<template>
  <a
    href="https://beian.miit.gov.cn"
    class="record_box"
    target="_blank"
    rel="nofollow"
    >粤ICP备2021021979号 -1</a
  >
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {
    toCSIP() {
      window.open('https://beian.miit.gov.cn');
    }
  }
};
</script>

<style lang="scss" scoped>
.record_box {
  position: absolute;
  left: 0;
  bottom: 2rem;
  width: 100%;
  font-size: 2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.4);
  cursor: pointer;
  z-index: 3;
}
</style>
