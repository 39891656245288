<template>
  <div class="solve_graph_vue">
    <div
      id="solveGraphChar"
      ref="solveGraphChar"
      class="solveGraphChar"
      v-if="showAnimate"
    >
      <svg
        width="1920px"
        height="1080px"
        viewBox="0 0 1920 1080"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        class="solveGraphCanvas"
      >
        <defs>
          <path
            d="M0,58.722 C0,91.152 26.289,117.443 58.721,117.443 L58.721,117.443 C91.152,117.443 117.442,91.152 117.442,58.722 L117.442,58.722 C117.442,26.29 91.152,0 58.721,0 L58.721,0 C26.289,0 0,26.29 0,58.722"
            id="path-1"
          ></path>
          <radialGradient
            cx="50%"
            cy="50%"
            fx="50%"
            fy="50%"
            r="49.6507724%"
            gradientTransform="translate(0.5, 0.5), scale(1, 1), rotate(90), translate(-0.5, -0.5)"
            id="radialGradient-3"
          >
            <stop stop-color="#CCD3E0" offset="0%"></stop>
            <stop stop-color="#FFFFFF" stop-opacity="0.6" offset="100%"></stop>
          </radialGradient>
        </defs>
        <g
          id="首页5备份"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g id="主编组-新媒体" transform="translate(818, 301.7836)">
            <line
              x1="401.5288"
              y1="213.828115"
              x2="208.9098"
              y2="124.008115"
              id="Stroke-86"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_2"
            ></line>
            <path
              d="M233.2087,135.563915 C277.9207,78.9359145 343.5167,39.5599145 418.3747,29.0599145"
              id="Stroke-88"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="radian_animation_line_2"
            ></path>
            <line
              x1="0.5"
              y1="253.716415"
              x2="402.5"
              y2="214.716415"
              id="Stroke-3"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="second_animation_line_2"
            ></line>
            <line
              x1="401.5288"
              y1="213.828115"
              x2="420.0328"
              y2="2.32611453"
              id="Stroke-80"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_2"
            ></line>
            <line
              x1="401.5288"
              y1="213.828115"
              x2="364.1568"
              y2="42.1661145"
              id="Stroke-82"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_2"
            ></line>
            <line
              x1="401.5288"
              y1="213.828115"
              x2="272.4968"
              y2="94.7711145"
              id="Stroke-84"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_2"
            ></line>
            <line
              x1="401.5288"
              y1="213.828115"
              x2="337.5"
              y2="97.7164145"
              id="Stroke-84"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_2"
            ></line>
            <text
              id="互动/搜索"
              font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
              font-size="12"
              font-weight="normal"
              line-spacing="12"
              fill="#595757"
              class="third_animation_circle_2"
            >
              <tspan x="224.5688" y="68.9094247">互动/搜索</tspan>
            </text>
            <text
              id="购买/口碑"
              font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
              font-size="12"
              font-weight="normal"
              line-spacing="12"
              fill="#595757"
              class="third_animation_circle_2"
            >
              <tspan x="224.5688" y="84.9094247">购买/口碑</tspan>
            </text>
            <path
              d="M272.4969,89.7716145 C275.2579,89.7716145 277.4969,92.0106145 277.4969,94.7716145 C277.4969,97.5326145 275.2579,99.7716145 272.4969,99.7716145 C269.7359,99.7716145 267.4969,97.5326145 267.4969,94.7716145 C267.4969,92.0106145 269.7359,89.7716145 272.4969,89.7716145"
              id="Fill-98"
              fill="#1D3462"
              class="third_animation_circle_2"
            ></path>
            <text
              id="互动/评论"
              font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
              font-size="12"
              font-weight="normal"
              line-spacing="12"
              fill="#595757"
              class="third_animation_circle_2"
            >
              <tspan x="328.67323" y="13">互动/评论</tspan>
            </text>
            <text
              id="新增/点赞/分享"
              font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
              font-size="12"
              font-weight="normal"
              line-spacing="12"
              fill="#595757"
              class="third_animation_circle_2"
            >
              <tspan x="314.486055" y="29">新增/点赞/分享</tspan>
            </text>
            <g
              id="编组-4"
              transform="translate(311, 72.2164)"
              class="third_animation_circle_2"
            >
              <path
                d="M38.9978265,3.48708003 C51.4316115,10.6654297 55.6912696,26.5630468 48.51292,38.995837 C41.3345703,51.4286271 25.4359584,55.68928 13.0021735,48.5109304 C0.568388535,41.3325808 -3.6912696,25.4349636 3.48708003,13.0021735 C10.6654297,0.568388535 26.5640416,-3.6912696 38.9978265,3.48708003"
                id="Fill-26"
                fill="#B5B5B6"
              ></path>
              <text
                id="内容-运营"
                font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
                font-size="14"
                font-weight="500"
                line-spacing="14"
                fill="#FFFFFF"
              >
                <tspan x="12" y="27">内容</tspan>
                <tspan x="12" y="41">运营</tspan>
              </text>
            </g>
            <g
              id="编组-4备份"
              transform="translate(264, 143.2164)"
              class="third_animation_circle_2"
            >
              <path
                d="M38.9978265,3.48708003 C51.4316115,10.6654297 55.6912696,26.5630468 48.51292,38.995837 C41.3345703,51.4286271 25.4359584,55.68928 13.0021735,48.5109304 C0.568388535,41.3325808 -3.6912696,25.4349636 3.48708003,13.0021735 C10.6654297,0.568388535 26.5640416,-3.6912696 38.9978265,3.48708003"
                id="Fill-26"
                fill="#B5B5B6"
              ></path>
              <text
                id="直播-运营"
                font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
                font-size="14"
                font-weight="400"
                line-spacing="14"
                fill="#FFFFFF"
              >
                <tspan x="12" y="27">直播</tspan>
                <tspan x="12" y="41">运营</tspan>
              </text>
            </g>
            <g
              id="编组-4"
              transform="translate(385, 74.2164)"
              class="third_animation_circle_2"
            >
              <path
                d="M38.9978265,3.48708003 C51.4316115,10.6654297 55.6912696,26.5630468 48.51292,38.995837 C41.3345703,51.4286271 25.4359584,55.68928 13.0021735,48.5109304 C0.568388535,41.3325808 -3.6912696,25.4349636 3.48708003,13.0021735 C10.6654297,0.568388535 26.5640416,-3.6912696 38.9978265,3.48708003"
                id="Fill-26"
                fill="#B5B5B6"
              ></path>
              <text
                id="社交-电商"
                font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
                font-size="14"
                font-weight="400"
                line-spacing="14"
                fill="#FFFFFF"
              >
                <tspan x="12" y="27">社交</tspan>
                <tspan x="12" y="41">电商</tspan>
              </text>
            </g>
            <g
              id="编组-5"
              transform="translate(362, 174.2164)"
              class="second_animation_circle_2"
            >
              <path
                d="M80,40 C80,62.091 62.091,80 40,80 C17.909,80 0,62.091 0,40 C0,17.909 17.909,0 40,0 C62.091,0 80,17.909 80,40"
                id="Fill-28"
                fill="#B5B5B6"
              ></path>
              <text
                id="新媒体"
                font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
                font-size="20"
                font-weight="400"
                line-spacing="20"
                fill="#FFFFFF"
              >
                <tspan x="10" y="51">新媒体</tspan>
              </text>
            </g>
            <path
              d="M364.1567,37.1660145 C366.9177,37.1660145 369.1567,39.4050145 369.1567,42.1660145 C369.1567,44.9270145 366.9177,47.1660145 364.1567,47.1660145 C361.3957,47.1660145 359.1567,44.9270145 359.1567,42.1660145 C359.1567,39.4050145 361.3957,37.1660145 364.1567,37.1660145"
              id="Fill-100"
              fill="#1D3462"
              class="third_animation_circle_2"
            ></path>
          </g>
          <g id="主编组-数字资产" transform="translate(615.077, 78.9704)">
            <line
              x1="172.3698"
              y1="96.3641898"
              x2="215.7468"
              y2="215.54119"
              id="Stroke-4"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_3"
            ></line>
            <line
              x1="98.8814"
              y1="62.8697898"
              x2="215.7464"
              y2="215.54079"
              id="Stroke-5"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_3"
            ></line>
            <line
              x1="0"
              y1="64.4735898"
              x2="215.747"
              y2="215.54059"
              id="Stroke-6"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_3"
            ></line>
            <line
              x1="215.7466"
              y1="215.54089"
              x2="431.8616"
              y2="64.2158898"
              id="Stroke-7"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_3"
            ></line>
            <line
              x1="215.7466"
              y1="215.54089"
              x2="333.0496"
              y2="63.0208898"
              id="Stroke-8"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_3"
            ></line>
            <line
              x1="215.7466"
              y1="215.54089"
              x2="260.1226"
              y2="93.6178898"
              id="Stroke-9"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_3"
            ></line>
            <line
              x1="215.7466"
              y1="213.68989"
              x2="215.7466"
              y2="43.2418898"
              id="Stroke-10"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_3"
            ></line>
            <line
              x2="214.8996"
              y2="232.98279"
              x1="190.423"
              y1="460.52959"
              id="Stroke-12"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="second_animation_line_3"
            ></line>
            <path
              d="M37.7218,90.8854898 C90.0898,60.5844898 150.8928,43.2424898 215.7468,43.2424898 C280.6008,43.2424898 341.4038,60.5844898 393.7718,90.8854898"
              id="Stroke-16"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="radian_animation_line_3"
            ></path>
            <text
              id="互动/搜索"
              font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
              font-size="12"
              font-weight="normal"
              line-spacing="12"
              fill="#595757"
              class="third_animation_circle_3"
            >
              <tspan x="190.2462" y="13">互动/搜索</tspan>
            </text>
            <text
              id="互动/评论"
              font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
              font-size="12"
              font-weight="normal"
              line-spacing="12"
              fill="#595757"
              class="third_animation_circle_3"
            >
              <tspan x="71.923" y="34.0295898">互动/评论</tspan>
            </text>
            <text
              id="购买/口碑"
              font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
              font-size="12"
              font-weight="normal"
              line-spacing="12"
              fill="#595757"
              class="third_animation_circle_3"
            >
              <tspan x="190.2462" y="29">购买/口碑</tspan>
            </text>
            <text
              id="新增/点赞/分享"
              font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
              font-size="12"
              font-weight="normal"
              line-spacing="12"
              fill="#595757"
              class="third_animation_circle_3"
            >
              <tspan x="56.923" y="50.0295898">新增/点赞/分享</tspan>
            </text>
            <text
              id="AO-A5"
              font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
              font-size="12"
              font-weight="normal"
              line-spacing="12"
              letter-spacing="-0.24"
              fill="#595757"
              class="third_animation_circle_3"
            >
              <tspan x="321.184394" y="19.9441276">A</tspan>
              <tspan x="328.708394" y="19.9441276">O-A5</tspan>
            </text>
            <text
              id="A1-A3"
              font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
              font-size="12"
              font-weight="normal"
              line-spacing="12"
              fill="#595757"
              class="third_animation_circle_3"
            >
              <tspan x="322.035165" y="35.9441276">A1-A3</tspan>
            </text>
            <text
              id="A3-A5"
              font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
              font-size="12"
              font-weight="normal"
              line-spacing="12"
              fill="#595757"
              class="third_animation_circle_3"
            >
              <tspan x="322.035165" y="51.9441276">A3-A5</tspan>
            </text>
            <path
              d="M114.920827,111.51667 C127.354611,118.695019 131.61427,134.592637 124.43592,147.025427 C117.25757,159.458217 101.358958,163.71887 88.9251735,156.54052 C76.4913885,149.362171 72.2317304,133.464553 79.41008,121.031763 C86.5884297,108.597978 102.487042,104.33832 114.920827,111.51667"
              id="Fill-20"
              fill="#B5B5B6"
              class="third_animation_circle_3"
            ></path>
            <path
              d="M190.920827,91.5166699 C203.354611,98.6950195 207.61427,114.592637 200.43592,127.025427 C193.25757,139.458217 177.358958,143.71887 164.925173,136.54052 C152.491389,129.362171 148.23173,113.464553 155.41008,101.031763 C162.58843,88.5979784 178.487042,84.3383202 190.920827,91.5166699"
              id="Fill-22"
              fill="#B5B5B6"
              class="third_animation_circle_3"
            ></path>
            <path
              d="M265.920827,91.5166699 C278.354611,98.6950195 282.61427,114.592637 275.43592,127.025427 C268.25757,139.458217 252.358958,143.71887 239.925173,136.54052 C227.491389,129.362171 223.23173,113.464553 230.41008,101.031763 C237.58843,88.5979784 253.487042,84.3383202 265.920827,91.5166699"
              id="Fill-24"
              fill="#B5B5B6"
              class="third_animation_circle_3"
            ></path>
            <path
              d="M341.920827,112.51667 C354.354611,119.695019 358.61427,135.592637 351.43592,148.025427 C344.25757,160.458217 328.358958,164.71887 315.925173,157.54052 C303.491389,150.362171 299.23173,134.464553 306.41008,122.031763 C313.58843,109.597978 329.487042,105.33832 341.920827,112.51667"
              id="Fill-26"
              fill="#B5B5B6"
              class="third_animation_circle_3"
            ></path>
            <g
              id="编组-5"
              transform="translate(175.923, 175.0296)"
              class="second_animation_circle_3"
            >
              <path
                d="M80,40 C80,62.091 62.091,80 40,80 C17.909,80 0,62.091 0,40 C0,17.909 17.909,0 40,0 C62.091,0 80,17.909 80,40"
                id="Fill-28"
                fill="#B5B5B6"
              ></path>
              <text
                id="数字-资产"
                font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
                font-size="20"
                font-weight="400"
                line-spacing="20"
                fill="#FFFFFF"
              >
                <tspan x="20" y="41">数字</tspan>
                <tspan x="20" y="61">资产</tspan>
              </text>
            </g>
            <text
              id="数字-资产"
              font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
              font-size="14"
              font-weight="400"
              line-spacing="14"
              fill="#FFFFFF"
              class="third_animation_circle_3"
            >
              <tspan x="87.923" y="135.02959">数字</tspan>
              <tspan x="87.923" y="149.02959">资产</tspan>
            </text>
            <text
              id="数字人"
              font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
              font-size="14"
              font-weight="400"
              line-spacing="14"
              fill="#FFFFFF"
              class="third_animation_circle"
            >
              <tspan x="156.923" y="122.02959">数字人</tspan>
            </text>
            <text
              id="XR-内容"
              font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
              font-size="14"
              font-weight="400"
              line-spacing="14"
              fill="#FFFFFF"
              class="third_animation_circle_3"
            >
              <tspan x="243.578" y="115.02959">XR</tspan>
              <tspan x="238.923" y="129.02959">内容</tspan>
            </text>
            <text
              id="XR-直播"
              font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
              font-size="14"
              font-weight="400"
              line-spacing="14"
              fill="#FFFFFF"
              class="third_animation_circle_3"
            >
              <tspan x="319.578" y="136.02959">XR</tspan>
              <tspan x="314.923" y="150.02959">直播</tspan>
            </text>
            <path
              d="M220.7466,43.2419898 C220.7466,46.0029898 218.5076,48.2419898 215.7466,48.2419898 C212.9856,48.2419898 210.7466,46.0029898 210.7466,43.2419898 C210.7466,40.4809898 212.9856,38.2419898 215.7466,38.2419898 C218.5076,38.2419898 220.7466,40.4809898 220.7466,43.2419898"
              id="Fill-30"
              fill="#1D3462"
              class="third_animation_circle_3"
            ></path>
            <path
              d="M103.8814,62.8697898 C103.8814,65.6307898 101.6424,67.8697898 98.8814,67.8697898 C96.1204,67.8697898 93.8814,65.6307898 93.8814,62.8697898 C93.8814,60.1087898 96.1204,57.8697898 98.8814,57.8697898 C101.6424,57.8697898 103.8814,60.1087898 103.8814,62.8697898"
              id="Fill-32"
              fill="#1D3462"
              class="third_animation_circle_3"
            ></path>
            <path
              d="M338.0493,63.0211898 C338.0493,65.7821898 335.8103,68.0211898 333.0493,68.0211898 C330.2883,68.0211898 328.0493,65.7821898 328.0493,63.0211898 C328.0493,60.2601898 330.2883,58.0211898 333.0493,58.0211898 C335.8103,58.0211898 338.0493,60.2601898 338.0493,63.0211898"
              id="Fill-78"
              fill="#1D3462"
              class="third_animation_circle_3"
            ></path>
          </g>
          <g id="主编组-品牌焕新" transform="translate(685.9284, 587.2821)">
            <line
              x1="121.5716"
              y1="0.2179"
              x2="121.7116"
              y2="230"
              id="Stroke-11"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="second_animation_line_6"
            ></line>
            <line
              x1="121.7114"
              y1="209.4892"
              x2="121.7114"
              y2="381.5292"
              id="Stroke-138"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_6"
            ></line>
            <line
              x1="121.7114"
              y1="209.4892"
              x2="188.8564"
              y2="371.7032"
              id="Stroke-140"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_6"
            ></line>
            <line
              x1="121.7114"
              y1="209.4892"
              x2="243.4234"
              y2="383.3112"
              id="Stroke-142"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_6"
            ></line>
            <line
              x1="0"
              y1="383.311"
              x2="121.711"
              y2="209.489"
              id="Stroke-144"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_6"
            ></line>
            <line
              x1="54.633"
              y1="371.7036"
              x2="121.711"
              y2="209.4896"
              id="Stroke-146"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_6"
            ></line>
            <path
              d="M228.0106,361.2153 C195.1356,374.3303 159.2656,381.5423 121.7116,381.5423 C84.1576,381.5423 48.2876,374.3303 15.4126,361.2153"
              id="Stroke-148"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="radian_animation_line_6"
            ></path>
            <g
              id="编组-4"
              transform="translate(161.0716, 276.7179)"
              class="third_animation_circle_6"
            >
              <path
                d="M38.9978265,3.48708003 C51.4316115,10.6654297 55.6912696,26.5630468 48.51292,38.995837 C41.3345703,51.4286271 25.4359584,55.68928 13.0021735,48.5109304 C0.568388535,41.3325808 -3.6912696,25.4349636 3.48708003,13.0021735 C10.6654297,0.568388535 26.5640416,-3.6912696 38.9978265,3.48708003"
                id="Fill-26"
                fill="#B5B5B6"
              ></path>
              <text
                id="新品-营销"
                font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
                font-size="14"
                font-weight="400"
                line-spacing="14"
                fill="#FFFFFF"
              >
                <tspan x="12" y="27">新品</tspan>
                <tspan x="12" y="41">营销</tspan>
              </text>
            </g>
            <g
              id="编组-4"
              transform="translate(30.0716, 276.7179)"
              class="third_animation_circle_6"
            >
              <path
                d="M38.9978265,3.48708003 C51.4316115,10.6654297 55.6912696,26.5630468 48.51292,38.995837 C41.3345703,51.4286271 25.4359584,55.68928 13.0021735,48.5109304 C0.568388535,41.3325808 -3.6912696,25.4349636 3.48708003,13.0021735 C10.6654297,0.568388535 26.5640416,-3.6912696 38.9978265,3.48708003"
                id="Fill-26"
                fill="#B5B5B6"
              ></path>
              <text
                id="品牌-策略"
                font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
                font-size="14"
                font-weight="400"
                line-spacing="14"
                fill="#FFFFFF"
              >
                <tspan x="12" y="27">品牌</tspan>
                <tspan x="12" y="41">策略</tspan>
              </text>
            </g>
            <g
              id="编组-4"
              transform="translate(96.0716, 286.7179)"
              class="third_animation_circle_6"
            >
              <path
                d="M38.9978265,3.48708003 C51.4316115,10.6654297 55.6912696,26.5630468 48.51292,38.995837 C41.3345703,51.4286271 25.4359584,55.68928 13.0021735,48.5109304 C0.568388535,41.3325808 -3.6912696,25.4349636 3.48708003,13.0021735 C10.6654297,0.568388535 26.5640416,-3.6912696 38.9978265,3.48708003"
                id="Fill-26"
                fill="#B5B5B6"
              ></path>
              <text
                id="品牌-设计"
                font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
                font-size="14"
                font-weight="400"
                line-spacing="14"
                fill="#FFFFFF"
              >
                <tspan x="12" y="27">品牌</tspan>
                <tspan x="12" y="41">设计</tspan>
              </text>
            </g>
            <g
              id="编组-5"
              transform="translate(82.0716, 169.7179)"
              class="second_animation_circle_6"
            >
              <path
                d="M80,40 C80,62.091 62.091,80 40,80 C17.909,80 0,62.091 0,40 C0,17.909 17.909,0 40,0 C62.091,0 80,17.909 80,40"
                id="Fill-28"
                fill="#B5B5B6"
              ></path>
              <text
                id="品牌-焕新"
                font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
                font-size="20"
                font-weight="400"
                line-spacing="20"
                fill="#FFFFFF"
              >
                <tspan x="20" y="42">品牌</tspan>
                <tspan x="20" y="62">焕新</tspan>
              </text>
            </g>
            <text
              id="推荐"
              font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
              font-size="12"
              font-weight="normal"
              fill="#595757"
              class="third_animation_circle_6"
            >
              <tspan x="185.7088" y="396.71461">推荐</tspan>
            </text>
            <text
              id="好感"
              font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
              font-size="12"
              font-weight="normal"
              fill="#595757"
              class="third_animation_circle_6"
            >
              <tspan x="111.6868" y="404.14721">好感</tspan>
            </text>
            <text
              id="认知"
              font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
              font-size="12"
              font-weight="normal"
              fill="#595757"
              class="third_animation_circle_6"
            >
              <tspan x="39.4363" y="396.71511">认知</tspan>
            </text>
            <path
              d="M194.6046,373.4777 C194.6046,376.2387 192.3656,378.4777 189.6046,378.4777 C186.8436,378.4777 184.6046,376.2387 184.6046,373.4777 C184.6046,370.7167 186.8436,368.4777 189.6046,368.4777 C192.3656,368.4777 194.6046,370.7167 194.6046,373.4777"
              id="Fill-159"
              fill="#1D3462"
              class="third_animation_circle_6"
            ></path>
            <path
              d="M126.7114,381.5426 C126.7114,384.3036 124.4724,386.5426 121.7114,386.5426 C118.9504,386.5426 116.7114,384.3036 116.7114,381.5426 C116.7114,378.7816 118.9504,376.5426 121.7114,376.5426 C124.4724,376.5426 126.7114,378.7816 126.7114,381.5426"
              id="Fill-161"
              fill="#1D3462"
              class="third_animation_circle_6"
            ></path>
            <path
              d="M58.9426,373.4713 C58.9426,376.2323 56.7036,378.4713 53.9426,378.4713 C51.1816,378.4713 48.9426,376.2323 48.9426,373.4713 C48.9426,370.7103 51.1816,368.4713 53.9426,368.4713 C56.7036,368.4713 58.9426,370.7103 58.9426,373.4713"
              id="Fill-163"
              fill="#1D3462"
              class="third_animation_circle_6"
            ></path>
          </g>
          <g id="主编组-大众传播" transform="translate(825.3232, 569.5)">
            <line
              x1="0.1768"
              y1="2.82704199e-13"
              x2="474.1109"
              y2="342.3699"
              id="Stroke-13"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="second_animation_line_1"
            ></line>
            <line
              x1="497.823"
              y1="345.8573"
              x2="748.925"
              y2="301.5813"
              id="Stroke-104"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_1"
            ></line>
            <line
              x1="497.823"
              y1="345.8573"
              x2="675.387"
              y2="272.2053"
              id="Stroke-106"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_1"
            ></line>
            <line
              x1="497.823"
              y1="345.8573"
              x2="597.381"
              y2="262.3183"
              id="Stroke-108"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_1"
            ></line>
            <line
              x1="497.823"
              y1="345.8573"
              x2="615.631"
              y2="217.1783"
              id="Stroke-110"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_1"
            ></line>
            <line
              x1="497.823"
              y1="345.8573"
              x2="550.389"
              y2="179.4403"
              id="Stroke-112"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_1"
            ></line>
            <line
              x1="497.823"
              y1="345.8573"
              x2="520.387"
              y2="217.8923"
              id="Stroke-114"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_1"
            ></line>
            <line
              x1="497.823"
              y1="345.8573"
              x2="472.392"
              y2="155.1133"
              id="Stroke-116"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_1"
            ></line>
            <line
              x1="497.823"
              y1="345.8573"
              x2="410.638"
              y2="106.3193"
              id="Stroke-118"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_1"
            ></line>
            <path
              d="M426.3572,149.5043 C542.3492,156.0463 643.4962,218.1213 703.6352,309.5663"
              id="Stroke-120"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="radian_animation_line_1"
            ></path>
            <path
              d="M472.3916,150.1136 C475.1526,150.1136 477.3916,152.3526 477.3916,155.1136 C477.3916,157.8746 475.1526,160.1136 472.3916,160.1136 C469.6306,160.1136 467.3916,157.8746 467.3916,155.1136 C467.3916,152.3526 469.6306,150.1136 472.3916,150.1136"
              id="Fill-102"
              fill="#1D3462"
              class="third_animation_circle_1"
            ></path>
            <text
              id="搜索新闻"
              font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
              font-size="12"
              font-weight="normal"
              line-spacing="12"
              fill="#595757"
              class="third_animation_circle_1"
            >
              <tspan x="449.7657" y="144.04511">搜索新闻</tspan>
            </text>
            <text
              id="转发评论"
              font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
              font-size="12"
              font-weight="normal"
              line-spacing="12"
              fill="#595757"
              class="third_animation_circle_1"
            >
              <tspan x="449.7657" y="128.04511">转发评论</tspan>
            </text>
            <text
              id="引用传播"
              font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
              font-size="12"
              font-weight="normal"
              line-spacing="12"
              fill="#595757"
              class="third_animation_circle_1"
            >
              <tspan x="449.7657" y="112.04511">引用传播</tspan>
            </text>
            <text
              id="点赞-IP"
              font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
              font-size="12"
              font-weight="normal"
              line-spacing="12"
              fill="#595757"
              class="third_animation_circle_1"
            >
              <tspan x="540.7657" y="134.04511">点赞 IP</tspan>
            </text>
            <text
              id="讨论IP"
              font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
              font-size="12"
              font-weight="normal"
              line-spacing="12"
              fill="#595757"
              class="third_animation_circle_1"
            >
              <tspan x="612.2657" y="174.04511">讨论IP</tspan>
            </text>
            <text
              id="搜索IP"
              font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
              font-size="12"
              font-weight="normal"
              line-spacing="12"
              fill="#595757"
              class="third_animation_circle_1"
            >
              <tspan x="542.2657" y="150.04511">搜索IP</tspan>
            </text>
            <text
              id="买IP衍生品"
              font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
              font-size="12"
              font-weight="normal"
              line-spacing="12"
              fill="#595757"
              class="third_animation_circle_1"
            >
              <tspan x="530.7657" y="166.04511">买IP衍生品</tspan>
            </text>
            <text
              id="KOL原粉"
              font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
              font-size="12"
              font-weight="normal"
              line-spacing="12"
              letter-spacing="-0.24"
              fill="#595757"
              class="third_animation_circle_1"
            >
              <tspan x="673.7657" y="233.04511">K</tspan>
              <tspan x="680.6537" y="233.04511">OL原粉</tspan>
            </text>
            <text
              id="KOL活动参与"
              font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
              font-size="12"
              font-weight="normal"
              line-spacing="12"
              letter-spacing="-0.24"
              fill="#595757"
              class="third_animation_circle_1"
            >
              <tspan x="594.7657" y="190.04511">K</tspan>
              <tspan x="601.6537" y="190.04511">OL活动参与</tspan>
            </text>
            <text
              id="转发/咨询"
              font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
              font-size="12"
              font-weight="normal"
              line-spacing="12"
              fill="#595757"
              class="third_animation_circle_1"
            >
              <tspan x="669.7657" y="249.04511">转发/咨询</tspan>
            </text>
            <text
              id="点击"
              font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
              font-size="12"
              font-weight="normal"
              line-spacing="12"
              fill="#595757"
              class="third_animation_circle_1"
            >
              <tspan x="684.7657" y="265.04511">点击</tspan>
            </text>
            <text
              id="互动转化"
              font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
              font-size="12"
              font-weight="normal"
              line-spacing="12"
              fill="#595757"
              class="third_animation_circle_1"
            >
              <tspan x="605.7657" y="206.04511">互动转化</tspan>
            </text>
            <g
              id="编组-4备份-2"
              transform="translate(426.6768, 195.5)"
              class="third_animation_circle_1"
            >
              <path
                d="M38.9978265,3.48708003 C51.4316115,10.6654297 55.6912696,26.5630468 48.51292,38.995837 C41.3345703,51.4286271 25.4359584,55.68928 13.0021735,48.5109304 C0.568388535,41.3325808 -3.6912696,25.4349636 3.48708003,13.0021735 C10.6654297,0.568388535 26.5640416,-3.6912696 38.9978265,3.48708003"
                id="Fill-26"
                fill="#B5B5B6"
              ></path>
              <text
                id="公关-传播"
                font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
                font-size="14"
                font-weight="400"
                line-spacing="14"
                fill="#FFFFFF"
              >
                <tspan x="12" y="27">公关</tspan>
                <tspan x="12" y="41">传播</tspan>
              </text>
            </g>
            <g
              id="编组-4"
              transform="translate(601.6768, 296.5)"
              class="third_animation_circle_1"
            >
              <path
                d="M38.9978265,3.48708003 C51.4316115,10.6654297 55.6912696,26.5630468 48.51292,38.995837 C41.3345703,51.4286271 25.4359584,55.68928 13.0021735,48.5109304 C0.568388535,41.3325808 -3.6912696,25.4349636 3.48708003,13.0021735 C10.6654297,0.568388535 26.5640416,-3.6912696 38.9978265,3.48708003"
                id="Fill-26"
                fill="#B5B5B6"
              ></path>
              <text
                id="媒介-采买"
                font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
                font-size="14"
                font-weight="400"
                line-spacing="14"
                fill="#FFFFFF"
              >
                <tspan x="12" y="27">媒介</tspan>
                <tspan x="12" y="41">采买</tspan>
              </text>
            </g>
            <g
              id="编组-4"
              transform="translate(553.6768, 250.5)"
              class="third_animation_circle_1"
            >
              <path
                d="M38.9978265,3.48708003 C51.4316115,10.6654297 55.6912696,26.5630468 48.51292,38.995837 C41.3345703,51.4286271 25.4359584,55.68928 13.0021735,48.5109304 C0.568388535,41.3325808 -3.6912696,25.4349636 3.48708003,13.0021735 C10.6654297,0.568388535 26.5640416,-3.6912696 38.9978265,3.48708003"
                id="Fill-26"
                fill="#B5B5B6"
              ></path>
              <text
                id="媒介-策略"
                font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
                font-size="14"
                font-weight="400"
                line-spacing="14"
                fill="#FFFFFF"
              >
                <tspan x="12" y="27">媒介</tspan>
                <tspan x="12" y="41">策略</tspan>
              </text>
            </g>
            <g
              id="编组-4备份"
              transform="translate(490.6768, 213.5)"
              class="third_animation_circle_1"
            >
              <path
                d="M38.9978265,3.48708003 C51.4316115,10.6654297 55.6912696,26.5630468 48.51292,38.995837 C41.3345703,51.4286271 25.4359584,55.68928 13.0021735,48.5109304 C0.568388535,41.3325808 -3.6912696,25.4349636 3.48708003,13.0021735 C10.6654297,0.568388535 26.5640416,-3.6912696 38.9978265,3.48708003"
                id="Fill-26"
                fill="#B5B5B6"
              ></path>
              <text
                id="IP大-事件"
                font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
                font-size="14"
                font-weight="400"
                line-spacing="14"
                fill="#FFFFFF"
              >
                <tspan x="12.476" y="27">IP大</tspan>
                <tspan x="12" y="41">事件</tspan>
              </text>
            </g>
            <g
              id="编组-5"
              transform="translate(457.6768, 305.5)"
              class="second_animation_circle_1"
            >
              <path
                d="M80,40 C80,62.091 62.091,80 40,80 C17.909,80 0,62.091 0,40 C0,17.909 17.909,0 40,0 C62.091,0 80,17.909 80,40"
                id="Fill-28"
                fill="#B5B5B6"
              ></path>
              <text
                id="大众-传播"
                font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
                font-size="20"
                font-weight="400"
                line-spacing="20"
                fill="#FFFFFF"
              >
                <tspan x="20" y="44">大众</tspan>
                <tspan x="20" y="64">传播</tspan>
              </text>
            </g>
            <path
              d="M550.3888,174.4407 C553.1498,174.4407 555.3888,176.6797 555.3888,179.4407 C555.3888,182.2017 553.1498,184.4407 550.3888,184.4407 C547.6278,184.4407 545.3888,182.2017 545.3888,179.4407 C545.3888,176.6797 547.6278,174.4407 550.3888,174.4407"
              id="Fill-132"
              fill="#1D3462"
              class="third_animation_circle_1"
            ></path>
            <path
              d="M615.631,212.1786 C618.392,212.1786 620.631,214.4176 620.631,217.1786 C620.631,219.9396 618.392,222.1786 615.631,222.1786 C612.87,222.1786 610.631,219.9396 610.631,217.1786 C610.631,214.4176 612.87,212.1786 615.631,212.1786"
              id="Fill-134"
              fill="#1D3462"
              class="third_animation_circle_1"
            ></path>
            <path
              d="M675.3868,267.2053 C678.1478,267.2053 680.3868,269.4443 680.3868,272.2053 C680.3868,274.9663 678.1478,277.2053 675.3868,277.2053 C672.6258,277.2053 670.3868,274.9663 670.3868,272.2053 C670.3868,269.4443 672.6258,267.2053 675.3868,267.2053"
              id="Fill-136"
              fill="#1D3462"
              class="third_animation_circle_1"
            ></path>
          </g>
          <g
            id="主编组-用户运营"
            transform="translate(420.8477, 761.0234) scale(-1, 1) translate(-420.8477, -761.0234)translate(39.4471, 567.0469)"
          >
            <line
              x1="0.7482"
              y1="0.4531"
              x2="487.987"
              y2="344.823"
              id="Stroke-13"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="second_animation_line_5"
            ></line>
            <line
              x1="511.6991"
              y1="348.3104"
              x2="762.8011"
              y2="304.0344"
              id="Stroke-104"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_5"
            ></line>
            <line
              x1="511.6991"
              y1="348.3104"
              x2="689.2631"
              y2="274.6584"
              id="Stroke-106"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_5"
            ></line>
            <line
              x1="511.6991"
              y1="348.3104"
              x2="611.2571"
              y2="264.7714"
              id="Stroke-108"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_5"
            ></line>
            <line
              x1="511.6991"
              y1="348.3104"
              x2="593.7482"
              y2="198.4531"
              id="Stroke-112"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_5"
            ></line>
            <line
              x1="511.6991"
              y1="348.3104"
              x2="534.2631"
              y2="220.3454"
              id="Stroke-114"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_5"
            ></line>
            <line
              x1="511.6991"
              y1="348.3104"
              x2="486.2681"
              y2="157.5664"
              id="Stroke-116"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_5"
            ></line>
            <line
              x1="511.6991"
              y1="348.3104"
              x2="424.5141"
              y2="108.7724"
              id="Stroke-118"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_5"
            ></line>
            <path
              d="M440.2333,151.9574 C556.2253,158.4994 657.3723,220.5744 717.5113,312.0194"
              id="Stroke-120"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="radian_animation_line_5"
            ></path>
            <path
              d="M486.2677,152.5667 C489.0287,152.5667 491.2677,154.8057 491.2677,157.5667 C491.2677,160.3277 489.0287,162.5667 486.2677,162.5667 C483.5067,162.5667 481.2677,160.3277 481.2677,157.5667 C481.2677,154.8057 483.5067,152.5667 486.2677,152.5667"
              id="Fill-102"
              fill="#1D3462"
              class="third_animation_circle_5"
            ></path>
            <text
              id="下载-添加-关注"
              transform="translate(486.6418, 123.4982) scale(-1, 1) translate(-486.6418, -123.4982)"
              font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
              font-size="12"
              font-weight="normal"
              line-spacing="16"
              fill="#595757"
              class="third_animation_circle_5"
            >
              <tspan x="474.6418" y="112.49821">下载</tspan>
              <tspan x="474.6418" y="128.49821">添加</tspan>
              <tspan x="474.6418" y="144.49821">关注</tspan>
            </text>
            <text
              id="报名-分享-邀请"
              transform="translate(595.6418, 155.4982) scale(-1, 1) translate(-595.6418, -155.4982)"
              font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
              font-size="12"
              font-weight="normal"
              line-spacing="16"
              fill="#595757"
              class="third_animation_circle_5"
            >
              <tspan x="583.6418" y="144.49821">报名</tspan>
              <tspan x="583.6418" y="160.49821">分享</tspan>
              <tspan x="583.6418" y="176.49821">邀请</tspan>
            </text>
            <text
              id="共创-投票-裂变"
              transform="translate(689.6418, 237.4982) scale(-1, 1) translate(-689.6418, -237.4982)"
              font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
              font-size="12"
              font-weight="normal"
              line-spacing="16"
              fill="#595757"
              class="third_animation_circle_5"
            >
              <tspan x="677.6418" y="226.49821">共创</tspan>
              <tspan x="677.6418" y="242.49821">投票</tspan>
              <tspan x="677.6418" y="258.49821">裂变</tspan>
            </text>
            <g
              id="编组-4备份-2"
              transform="translate(466.5529, 223.952) scale(-1, 1) translate(-466.5529, -223.952)translate(440.5529, 197.9531)"
              class="third_animation_circle_5"
            >
              <path
                d="M38.9978265,3.48708003 C51.4316115,10.6654297 55.6912696,26.5630468 48.51292,38.995837 C41.3345703,51.4286271 25.4359584,55.68928 13.0021735,48.5109304 C0.568388535,41.3325808 -3.6912696,25.4349636 3.48708003,13.0021735 C10.6654297,0.568388535 26.5640416,-3.6912696 38.9978265,3.48708003"
                id="Fill-26"
                fill="#B5B5B6"
              ></path>
              <text
                id="社区-运营"
                font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
                font-size="14"
                font-weight="400"
                line-spacing="14"
                fill="#FFFFFF"
              >
                <tspan x="12" y="27">社区</tspan>
                <tspan x="12" y="41">运营</tspan>
              </text>
            </g>
            <g
              id="编组-4"
              transform="translate(641.5529, 324.952) scale(-1, 1) translate(-641.5529, -324.952)translate(615.5529, 298.9531)"
              class="third_animation_circle_5"
            >
              <path
                d="M38.9978265,3.48708003 C51.4316115,10.6654297 55.6912696,26.5630468 48.51292,38.995837 C41.3345703,51.4286271 25.4359584,55.68928 13.0021735,48.5109304 C0.568388535,41.3325808 -3.6912696,25.4349636 3.48708003,13.0021735 C10.6654297,0.568388535 26.5640416,-3.6912696 38.9978265,3.48708003"
                id="Fill-26"
                fill="#B5B5B6"
              ></path>
              <text
                id="社群-运营"
                font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
                font-size="14"
                font-weight="400"
                line-spacing="14"
                fill="#FFFFFF"
              >
                <tspan x="12" y="27">社群</tspan>
                <tspan x="12" y="41">运营</tspan>
              </text>
            </g>
            <g
              id="编组-4"
              transform="translate(593.5529, 278.952) scale(-1, 1) translate(-593.5529, -278.952)translate(567.5529, 252.9531)"
              class="third_animation_circle_5"
            >
              <path
                d="M38.9978265,3.48708003 C51.4316115,10.6654297 55.6912696,26.5630468 48.51292,38.995837 C41.3345703,51.4286271 25.4359584,55.68928 13.0021735,48.5109304 C0.568388535,41.3325808 -3.6912696,25.4349636 3.48708003,13.0021735 C10.6654297,0.568388535 26.5640416,-3.6912696 38.9978265,3.48708003"
                id="Fill-26"
                fill="#B5B5B6"
              ></path>
              <text
                id="实时-客服"
                font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
                font-size="14"
                font-weight="400"
                line-spacing="14"
                fill="#FFFFFF"
              >
                <tspan x="12" y="27">实时</tspan>
                <tspan x="12" y="41">客服</tspan>
              </text>
            </g>
            <g
              id="编组-4备份"
              transform="translate(530.5529, 241.952) scale(-1, 1) translate(-530.5529, -241.952)translate(504.5529, 215.9531)"
              class="third_animation_circle_5"
            >
              <path
                d="M38.9978265,3.48708003 C51.4316115,10.6654297 55.6912696,26.5630468 48.51292,38.995837 C41.3345703,51.4286271 25.4359584,55.68928 13.0021735,48.5109304 C0.568388535,41.3325808 -3.6912696,25.4349636 3.48708003,13.0021735 C10.6654297,0.568388535 26.5640416,-3.6912696 38.9978265,3.48708003"
                id="Fill-26"
                fill="#B5B5B6"
              ></path>
              <text
                id="活动-运营"
                font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
                font-size="14"
                font-weight="400"
                line-spacing="14"
                fill="#FFFFFF"
              >
                <tspan x="12" y="27">活动</tspan>
                <tspan x="12" y="41">运营</tspan>
              </text>
            </g>
            <g
              id="编组-5"
              transform="translate(511.5529, 347.9531) scale(-1, 1) translate(-511.5529, -347.9531)translate(471.5529, 307.9531)"
              class="second_animation_circle_5"
            >
              <path
                d="M80,40 C80,62.091 62.091,80 40,80 C17.909,80 0,62.091 0,40 C0,17.909 17.909,0 40,0 C62.091,0 80,17.909 80,40"
                id="Fill-28"
                fill="#B5B5B6"
              ></path>
              <text
                id="用户-运营"
                font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
                font-size="20"
                font-weight="400"
                line-spacing="20"
                fill="#FFFFFF"
              >
                <tspan x="20" y="41">用户</tspan>
                <tspan x="20" y="61">运营</tspan>
              </text>
            </g>
            <path
              d="M595.2649,190.8938 C598.0259,190.8938 600.2649,193.1328 600.2649,195.8938 C600.2649,198.6548 598.0259,200.8938 595.2649,200.8938 C592.5039,200.8938 590.2649,198.6548 590.2649,195.8938 C590.2649,193.1328 592.5039,190.8938 595.2649,190.8938"
              id="Fill-132"
              fill="#1D3462"
              class="third_animation_circle_5"
            ></path>
            <path
              d="M689.2629,269.6584 C692.0239,269.6584 694.2629,271.8974 694.2629,274.6584 C694.2629,277.4194 692.0239,279.6584 689.2629,279.6584 C686.5019,279.6584 684.2629,277.4194 684.2629,274.6584 C684.2629,271.8974 686.5019,269.6584 689.2629,269.6584"
              id="Fill-136"
              fill="#1D3462"
              class="third_animation_circle_5"
            ></path>
          </g>
          <g id="主编组-落地体验" transform="translate(151.7363, 89)">
            <line
              x2="302.946751"
              y2="342.7766"
              x1="637.763651"
              y1="466.5"
              id="Stroke-14"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="second_animation_line_4"
            ></line>
            <line
              x1="236.405651"
              y1="109.2966"
              x2="274.972651"
              y2="328.0186"
              id="Stroke-34"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_4"
            ></line>
            <line
              x1="235.648551"
              y1="181.2608"
              x2="274.972551"
              y2="328.0188"
              id="Stroke-36"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_4"
            ></line>
            <line
              x1="159.288751"
              y1="190.1527"
              x2="274.972751"
              y2="328.0187"
              id="Stroke-38"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_4"
            ></line>
            <line
              x1="184.521751"
              y1="237.5685"
              x2="274.972751"
              y2="328.0195"
              id="Stroke-40"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_4"
            ></line>
            <line
              x1="151.423751"
              y1="294.9143"
              x2="274.972751"
              y2="328.0193"
              id="Stroke-42"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_4"
            ></line>
            <line
              x1="97.6244508"
              y1="296.7478"
              x2="274.972451"
              y2="328.0188"
              id="Stroke-44"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_4"
            ></line>
            <line
              x1="133.168451"
              y1="366.0152"
              x2="274.972451"
              y2="328.0192"
              id="Stroke-46"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_4"
            ></line>
            <line
              x1="66.1848508"
              y1="404.0115"
              x2="274.971851"
              y2="328.0195"
              id="Stroke-48"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_4"
            ></line>
            <line
              x1="5.2487508"
              y1="516.8815"
              x2="274.972751"
              y2="328.0195"
              id="Stroke-50"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_4"
            ></line>
            <line
              x1="274.972251"
              y1="328.019"
              x2="303.670251"
              y2="0"
              id="Stroke-52"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="third_animation_line_4"
            ></line>
            <path
              d="M59.0247508,479.2919 C62.3467508,303.6519 157.237751,150.4179 297.952751,65.3369"
              id="Stroke-54"
              stroke="#B5B5B6"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="radian_animation_line_4"
            ></path>
            <g
              id="编组-4"
              transform="translate(173.2637, 227)"
              class="third_animation_circle_4"
            >
              <path
                d="M38.9978265,3.48708003 C51.4316115,10.6654297 55.6912696,26.5630468 48.51292,38.995837 C41.3345703,51.4286271 25.4359584,55.68928 13.0021735,48.5109304 C0.568388535,41.3325808 -3.6912696,25.4349636 3.48708003,13.0021735 C10.6654297,0.568388535 26.5640416,-3.6912696 38.9978265,3.48708003"
                id="Fill-26"
                fill="#B5B5B6"
              ></path>
              <text
                id="上市-发布"
                font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
                font-size="14"
                font-weight="400"
                line-spacing="14"
                fill="#FFFFFF"
              >
                <tspan x="12" y="27">上市</tspan>
                <tspan x="12" y="41">发布</tspan>
              </text>
            </g>
            <g
              id="编组-4"
              transform="translate(214.2637, 175)"
              class="third_animation_circle_4"
            >
              <path
                d="M38.9978265,3.48708003 C51.4316115,10.6654297 55.6912696,26.5630468 48.51292,38.995837 C41.3345703,51.4286271 25.4359584,55.68928 13.0021735,48.5109304 C0.568388535,41.3325808 -3.6912696,25.4349636 3.48708003,13.0021735 C10.6654297,0.568388535 26.5640416,-3.6912696 38.9978265,3.48708003"
                id="Fill-26"
                fill="#B5B5B6"
              ></path>
              <text
                id="政企-活动"
                font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
                font-size="14"
                font-weight="400"
                line-spacing="14"
                fill="#FFFFFF"
              >
                <tspan x="12" y="27">政企</tspan>
                <tspan x="12" y="41">活动</tspan>
              </text>
            </g>
            <g
              id="编组-4"
              transform="translate(264.2637, 129)"
              class="third_animation_circle_4"
            >
              <path
                d="M38.9978265,3.48708003 C51.4316115,10.6654297 55.6912696,26.5630468 48.51292,38.995837 C41.3345703,51.4286271 25.4359584,55.68928 13.0021735,48.5109304 C0.568388535,41.3325808 -3.6912696,25.4349636 3.48708003,13.0021735 C10.6654297,0.568388535 26.5640416,-3.6912696 38.9978265,3.48708003"
                id="Fill-26"
                fill="#B5B5B6"
              ></path>
              <text
                id="展会-运营"
                font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
                font-size="14"
                font-weight="400"
                line-spacing="14"
                fill="#FFFFFF"
              >
                <tspan x="12" y="27">展会</tspan>
                <tspan x="12" y="41">运营</tspan>
              </text>
            </g>
            <g
              id="编组-4"
              transform="translate(146.2637, 274)"
              class="third_animation_circle_4"
            >
              <path
                d="M38.9978265,3.48708003 C51.4316115,10.6654297 55.6912696,26.5630468 48.51292,38.995837 C41.3345703,51.4286271 25.4359584,55.68928 13.0021735,48.5109304 C0.568388535,41.3325808 -3.6912696,25.4349636 3.48708003,13.0021735 C10.6654297,0.568388535 26.5640416,-3.6912696 38.9978265,3.48708003"
                id="Fill-26"
                fill="#B5B5B6"
              ></path>
              <text
                id="体验-活动"
                font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
                font-size="14"
                font-weight="400"
                line-spacing="14"
                fill="#FFFFFF"
              >
                <tspan x="12" y="27">体验</tspan>
                <tspan x="12" y="41">活动</tspan>
              </text>
            </g>
            <g
              id="编组-4"
              transform="translate(121.2637, 336)"
              class="third_animation_circle_4"
            >
              <path
                d="M38.9978265,3.48708003 C51.4316115,10.6654297 55.6912696,26.5630468 48.51292,38.995837 C41.3345703,51.4286271 25.4359584,55.68928 13.0021735,48.5109304 C0.568388535,41.3325808 -3.6912696,25.4349636 3.48708003,13.0021735 C10.6654297,0.568388535 26.5640416,-3.6912696 38.9978265,3.48708003"
                id="Fill-26"
                fill="#B5B5B6"
              ></path>
              <text
                id="数字-展厅"
                font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
                font-size="14"
                font-weight="400"
                line-spacing="14"
                fill="#FFFFFF"
              >
                <tspan x="12" y="27">数字</tspan>
                <tspan x="12" y="41">展厅</tspan>
              </text>
            </g>
            <g
              id="编组-4"
              transform="translate(106.2637, 402)"
              class="third_animation_circle_4"
            >
              <path
                d="M38.9978265,3.48708003 C51.4316115,10.6654297 55.6912696,26.5630468 48.51292,38.995837 C41.3345703,51.4286271 25.4359584,55.68928 13.0021735,48.5109304 C0.568388535,41.3325808 -3.6912696,25.4349636 3.48708003,13.0021735 C10.6654297,0.568388535 26.5640416,-3.6912696 38.9978265,3.48708003"
                id="Fill-26"
                fill="#B5B5B6"
              ></path>
              <text
                id="数字-沙盘"
                font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
                font-size="14"
                font-weight="400"
                line-spacing="14"
                fill="#FFFFFF"
              >
                <tspan x="12" y="27">数字</tspan>
                <tspan x="12" y="41">沙盘</tspan>
              </text>
            </g>
            <g
              id="编组-5"
              transform="translate(235.2637, 288)"
              class="second_animation_circle_4"
            >
              <path
                d="M80,40 C80,62.091 62.091,80 40,80 C17.909,80 0,62.091 0,40 C0,17.909 17.909,0 40,0 C62.091,0 80,17.909 80,40"
                id="Fill-28"
                fill="#B5B5B6"
              ></path>
              <text
                id="落地-体验"
                font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
                font-size="20"
                font-weight="400"
                line-spacing="20"
                fill="#FFFFFF"
              >
                <tspan x="20" y="41">落地</tspan>
                <tspan x="20" y="61">体验</tspan>
              </text>
            </g>
            <text
              id="传播-关注-讨论"
              font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
              font-size="12"
              font-weight="normal"
              fill="#595757"
              class="third_animation_circle_4"
            >
              <tspan x="121.464749" y="157.914647">传播</tspan>
              <tspan x="121.464749" y="174.914647">关注</tspan>
              <tspan x="121.464749" y="191.914647">讨论</tspan>
            </text>
            <text
              id="打卡-参与-分享"
              font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
              font-size="12"
              font-weight="normal"
              line-spacing="16"
              fill="#595757"
              class="third_animation_circle_4"
            >
              <tspan x="61.6693716" y="270.250166">打卡</tspan>
              <tspan x="61.6693716" y="286.250166">参与</tspan>
              <tspan x="61.6693716" y="302.250166">分享</tspan>
            </text>
            <text
              id="到地/到场-到店/签到"
              font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
              font-size="12"
              font-weight="normal"
              line-spacing="16"
              fill="#595757"
              class="third_animation_circle_4"
            >
              <tspan x="0" y="395.244463">到地/到场</tspan>
              <tspan x="0" y="411.244463">到店/签到</tspan>
            </text>
            <path
              d="M241.405651,109.2966 C241.405651,112.0576 239.166651,114.2966 236.405651,114.2966 C233.644651,114.2966 231.405651,112.0576 231.405651,109.2966 C231.405651,106.5356 233.644651,104.2966 236.405651,104.2966 C239.166651,104.2966 241.405651,106.5356 241.405651,109.2966"
              id="Fill-70"
              fill="#1D3462"
              class="third_animation_circle_4"
            ></path>
            <text
              id="关注/搜索-报名/邀约"
              font-family="SourceHanSansCN-Regular  ,'Helvetica Neue'"
              font-size="12"
              font-weight="normal"
              line-spacing="16"
              fill="#595757"
              class="third_animation_circle_4"
            >
              <tspan x="178.569851" y="85.0236102">关注/搜索</tspan>
              <tspan x="178.569851" y="101.02361">报名/邀约</tspan>
            </text>
            <path
              d="M164.371851,190.1527 C164.371851,192.9137 162.132851,195.1527 159.371851,195.1527 C156.610851,195.1527 154.371851,192.9137 154.371851,190.1527 C154.371851,187.3917 156.610851,185.1527 159.371851,185.1527 C162.132851,185.1527 164.371851,187.3917 164.371851,190.1527"
              id="Fill-72"
              fill="#1D3462"
              class="third_animation_circle_4"
            ></path>
            <path
              d="M102.624451,296.7478 C102.624451,299.5088 100.385451,301.7478 97.6244508,301.7478 C94.8634508,301.7478 92.6244508,299.5088 92.6244508,296.7478 C92.6244508,293.9868 94.8634508,291.7478 97.6244508,291.7478 C100.385451,291.7478 102.624451,293.9868 102.624451,296.7478"
              id="Fill-74"
              fill="#1D3462"
              class="third_animation_circle_4"
            ></path>
            <path
              d="M71.1848508,404.0115 C71.1848508,406.7725 68.9458508,409.0115 66.1848508,409.0115 C63.4238508,409.0115 61.1848508,406.7725 61.1848508,404.0115 C61.1848508,401.2505 63.4238508,399.0115 66.1848508,399.0115 C68.9458508,399.0115 71.1848508,401.2505 71.1848508,404.0115"
              id="Fill-76"
              fill="#1D3462"
              class="third_animation_circle_4"
            ></path>
          </g>
          <g id="编组" transform="translate(747.854, 500.3528)">
            <mask id="mask-2" fill="white">
              <use xlink:href="#path-1"></use>
            </mask>
            <g id="Clip-19"></g>
            <path
              d="M0,58.722 C0,91.152 26.289,117.443 58.721,117.443 L58.721,117.443 C91.152,117.443 117.442,91.152 117.442,58.722 L117.442,58.722 C117.442,26.29 91.152,0 58.721,0 L58.721,0 C26.289,0 0,26.29 0,58.722"
              id="Fill-18"
              fill="url(#radialGradient-3)"
              mask="url(#mask-2)"
            ></path>
          </g>

          <!-- </g> -->
          <text
            id="品牌-体验"
            font-family="SourceHanSansCN-Medium  ,'Helvetica Neue'"
            font-size="24"
            font-weight="500"
            line-spacing="24"
            fill="#595757"
          >
            <tspan x="783" y="560">品牌</tspan>
            <tspan x="783" y="584">体验</tspan>
          </text>
        </g>
      </svg>

      <!-- <g
          id="页面-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <path
            d="M758 582 Q0 0 0 0"
            class="animation_line"
            stroke="#B5B5B6"
          ></path>
        </g> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showAnimate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {},

  methods: {}
};
</script>

<style lang="scss" scoped>
.solve_graph_vue {
  height: 100vh;
  width: 100vw;
  background: #ffffff;
  .solveGraphChar {
    height: 100vh;
    width: 100vw;
    position: relative;
    @keyframes line-animate {
      0% {
        stroke-dashoffset: 1100;
      }
      100% {
        stroke-dashoffset: 0;
      }
    }
    @keyframes circle-animate {
      0% {
        // transform: scale(0);
        opacity: 0;
      }

      100% {
        // transform: scale(1);
        opacity: 1;
      }
    }
    .solveGraphCanvas {
      width: 100%;
      height: 100%;
    }
    @for $i from 1 through 6 {
      .second_animation_line_#{$i} {
        animation: line-animate 0.8s;
        animation-fill-mode: backwards;
        stroke-dasharray: 1000;
        // animation-delay: #{1.8 * ($i - 1)+0.8}s;
        animation-delay: 0.8s;
      }
      .second_animation_circle_#{$i} {
        animation: circle-animate 1s;
        animation-fill-mode: backwards;
        // animation-delay: #{1.8 * ($i - 1)+1}s;
        animation-delay: 1s;
      }
      .third_animation_line_#{$i} {
        animation: line-animate 0.6s;
        animation-fill-mode: backwards;
        stroke-dasharray: 1000;
        // animation-delay: #{1.8 * ($i - 1)+1.2}s;
        animation-delay: 1.2s;
      }
      .third_animation_circle_#{$i} {
        animation: circle-animate 0.6s;
        // animation-delay: #{1.8 * ($i - 1)+1.5}s;
        animation-delay: 1.5s;
        animation-fill-mode: backwards;
      }
      .radian_animation_line_#{$i} {
        animation: line-animate 0.8s;
        animation-fill-mode: backwards;
        stroke-dasharray: 1000;
        // animation-delay: #{1.8 * ($i - 1)+2}s;
        animation-delay: 2s;
      }
    }
  }
}
</style>
