<!--
 * @Author: xing
 * @Date: 2023-09-04 14:52:47
 * @LastEditors: xing
 * @LastEditTime: 2023-09-12 10:12:14
 * @Description: 
-->
<template>
  <div>
    <HeaderNav />
    <div>
      <keep-alive>
        <router-view :routes="routers" />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import HeaderNav from './components/header-nav.vue';
import router from '@/router';
export default {
  components: {
    HeaderNav
  },
  data() {
    return {
      routers: router.options.routes
    };
  }
};
</script>
