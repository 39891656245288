<template>
  <div class="m-solution">
    <div class="m-solution-area">
      <div class="m-solution-title">
        <div class="title1">
          {{ marketData.nameEn }} {{ marketData.nameEnTwo }}
        </div>
        <div class="title2">{{ marketData.name }}</div>
        <div class="title3">{{ marketData.description }}</div>
      </div>

      <div class="case-area" v-if="detailData && detailData.length > 0">
        <div
          class="case-area-item"
          style="margin-bottom: 4rem"
          v-for="(slide, index) in detailData.slice(0, 2)"
          :key="index"
          @click="toSecondRoute"
        >
          <div class="case-area-content">
            <div class="case-area-top">
              <img :src="JSON.parse(slide.images)[0]" alt="" class="img" />
            </div>
            <div class="case-area-bottom">
              <div class="left-font">{{ slide.title }}</div>
              <div class="right-font">{{ slide.description }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  props: {
    marketData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      swiperObj: null,
      detailData: null
    };
  },
  computed: {},
  watch: {
    marketData: {
      handler(val) {
        if (!val) return;
        this.getDetailData();
      },
      immediate: true
    }
  },
  mounted() {},
  methods: {
    getDetailData() {
      axios
        .get(
          this.$api.marketingCategoryDetailApi +
            '?categoryId=' +
            this.marketData.id
        )
        .then((res) => {
          if (res.data.code === 0) {
            this.detailData = res.data.data;
          }
        });
    },
    toSecondRoute() {
      this.$router.push('/marketing?id=' + this.marketData.id);
    }
  }
};
</script>

<style lang="scss" scoped>
.m-solution {
  width: 100vw;
  min-height: 100vh;
  // padding-top: 8.8rem;
  box-sizing: border-box;
  background-color: #3a3a3a;

  .m-solution-area {
    padding: 3rem 4.5rem;

    .m-solution-title {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .title1 {
        height: 8.2rem;
        font-size: 8.2rem;
        font-family: SourceHanSansCN-Heavy, 'Helvetica Neue';
        font-weight: 900;
        color: #727171;
        line-height: 8.2rem;
      }

      .title2 {
        height: 3.6rem;
        font-size: 3.6rem;
        font-family: SourceHanSansCN-Medium, 'Helvetica Neue';
        font-weight: 500;
        color: #ffffff;
        line-height: 3.6rem;
        margin: 1rem auto 2rem;
      }

      .title3 {
        min-height: 1.6rem;
        font-size: 1.6rem;
        font-family: SourceHanSansCN-Regular, 'Helvetica Neue';
        font-weight: 400;
        color: #ffffff;
        line-height: 3rem;
        // width: 80%;
        word-break: break-all;
      }
    }

    .case-area {
      width: calc(100vw - 9rem);
      height: 100%;
      padding: 3rem;
      margin: 4rem auto 0;
      border-radius: 2.4rem;
      background-color: #cccccc;
      box-sizing: border-box;
      .case-area-item {
        width: 100%;
        // margin-bottom: 6rem;
        &:last-child {
          margin-bottom: 0;
        }
        .case-area-content {
          .case-area-top {
            .img {
              width: 100%;
              height: 38rem;
              object-fit: cover;
              vertical-align: middle;
            }
          }
          .case-area-bottom {
            margin-top: 2rem;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            .left-font {
              font-size: 2rem;
              font-family: SourceHanSansCN-Regular, 'Helvetica Neue';
              font-weight: 400;
              color: #000000;
              line-height: 2.8rem;
            }
            .right-font {
              width: 20rem;
              font-size: 1rem;
              font-family: SourceHanSansCN-Medium, 'Helvetica Neue';
              font-weight: 500;
              color: #000000;
              line-height: 2rem;
              margin-left: 10rem;
              text-align: right;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
          }
        }
      }
    }
  }
}
</style>
