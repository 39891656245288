<template>
  <div class="home-box" v-loading="loading">
    <!--   -->
    <div class="swiper-page-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-if="bannerList.length > 0">
          <VideoBannerVue
            ref="moduleOneWrapper"
            :bannerList="bannerList"
          ></VideoBannerVue>
        </div>
        <!-- 时间轴 -->
        <div class="swiper-slide">
          <DevelopHistory ref="moduleTwoWrapper"></DevelopHistory>
        </div>
        <!-- 孚马迪解决方案 -->
        <div class="swiper-slide">
          <SolutionApproach ref="moduleThreeWrapper"></SolutionApproach>
        </div>
        <!-- 卡片翻转 -->
        <div class="swiper-slide">
          <div v-if="businessList && businessList.length > 0">
            <BusinessViewVue
              :businessList="businessList"
              :marketingList="marketingList"
            ></BusinessViewVue>
          </div>
        </div>
        <!-- 直播体验 -->
        <div class="swiper-slide" v-for="item in marketingList" :key="item.id">
          <LiveStreamingVue :marketData="item"></LiveStreamingVue>
        </div>

        <!-- logo徽标组 -->
        <div class="swiper-slide">
          <BrandLogo></BrandLogo>
        </div>
        <!-- 首页结尾 -->
        <div class="swiper-slide" v-if="companyDetail">
          <PageEnding
            ref="moduleEndingWrapper"
            :companyDetail="companyDetail[0]"
          ></PageEnding>
        </div>
      </div>

      <div class="swiper-page-pagination home_page_pagination"></div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import DevelopHistory from './components/DevelopHistory.vue';
import SolutionApproach from './components/SolutionApproach.vue';
import BusinessViewVue from './components/BusinessView.vue';
import BrandLogo from './components/BrandLogo.vue';
import PageEnding from './components/PageEnding.vue';
import VideoBannerVue from './components/VideoBanner.vue';
import axios from 'axios';
export default {
  name: 'HomeView',
  components: {
    DevelopHistory,
    SolutionApproach,
    BusinessViewVue,
    BrandLogo,
    PageEnding,
    VideoBannerVue,
    LiveStreamingVue: () => import('./components/LiveStreaming.vue')
  },
  data() {
    return {
      slideIndex: 0, // 当前轮播项的索引
      activeIndex: 0, // 默认选中第一个
      items: [1, 2, 3, 4, 5], // 根据需要修改成相应的数据
      releaseOnEdgesValue: false,
      screenWeight: 0, // 屏幕宽度
      screenHeight: 0, // 屏幕高度
      index: 1, // 用于判断翻页
      curIndex: 1, // 当前页的index
      startTime: 0, // 翻屏起始时间
      endTime: 0, // 上一次翻屏结束时间
      nowTop: 0, // 翻屏后top的位置
      pageNum: 0, // 一共有多少页
      main: Object,
      obj: Object,
      isThird: false,
      swiperTime: null,
      loading: false,
      bannerList: [],
      businessList: [],
      marketingList: [],
      companyDetail: null
    };
  },
  computed: {
    isTop() {
      return this.$store.state.isTop;
    }
  },
  mounted() {
    this.$store.state.isTop = true;
    this.getPageData();
  },

  beforeUnmount() {},
  methods: {
    selectItem(index) {
      this.activeIndex = index;
    },
    initPageSwiper() {
      const that = this;
      // 初始化swiper
      this.pageSwiper = new Swiper('.swiper-page-container', {
        speed: 1000,
        autoplay: false,
        direction: 'vertical',
        shortSwipes: false,
        mousewheel: {
          sensitivity: 0.1
        },
        pagination: {
          el: '.swiper-page-pagination',
          clickable: true
        },
        on: {
          slideChangeTransitionStart: () => {
            that.pageSlideChange();
          }
        }
      });
      this.pageSwiper.mousewheel.enable();
    },
    pageSlideChange() {
      const { activeIndex } = this.pageSwiper;

      if (activeIndex !== 0) {
        this.$store.state.isTop = false;
      }
      if (![0, 1, 2].includes(activeIndex) && this.$refs.moduleEndingWrapper) {
        this.$refs.moduleEndingWrapper.update();
      }

      if (activeIndex === 0) {
        this.isThird = false;
        this.$store.state.isTop = true;
        this.$refs.moduleOneWrapper.update();
        // this.swiperOne.slideTo(0);
      } else if (activeIndex === 1) {
        this.$refs.moduleTwoWrapper.update();
      } else if (activeIndex === 2) {
        this.$refs.moduleThreeWrapper.update();
      }
    },
    // 一下子全部获取页面的配置和数据
    getPageData() {
      this.loading = true;
      Promise.all([
        axios.get(this.$api.homepageListApi),
        axios.get(this.$api.marketingCategoryApi),
        axios.get(this.$api.businessListApi),
        axios.get(this.$api.companyDetailApi)
      ])
        .then((resArr) => {
          const bannerRes = resArr[0];
          if (bannerRes.data.code === 0) {
            this.bannerList = bannerRes.data.data;
          }
          // 翻转卡片
          const businessRes = resArr[2];
          if (businessRes.data.code === 0) {
            this.businessList = businessRes.data.data;
          }
          const marketingRes = resArr[1];
          if (marketingRes.data.code === 0) {
            this.marketingList = marketingRes.data.data;
          }
          const companyDetailRes = resArr[3];
          if (companyDetailRes.data.code === 0) {
            this.companyDetail = companyDetailRes.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.initPageSwiper();
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.home-box {
  --swiper-pagination-color: #9d9292;
  background-color: #3a3a3a;
  overflow: hidden;

  .swiper-page-container {
    background: rgb(239, 239, 239);
    height: 100vh;
    // height: calc(100vh - 8.8rem);
    .swiper-page-pagination {
      position: fixed;
      top: 50%;
      right: 5.9rem;
      display: flex;
      flex-direction: column;
      transform: translateY(-50%);
      z-index: 999;
      li {
        width: 1.1rem;
        height: 1.1rem;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        margin-bottom: 1.4rem;
        cursor: pointer;
        &:last-child {
          margin-bottom: 0;
        }
      }
      li.active {
        background-color: #ffffff;
        /* 将选中的颜色改为 #FFFFFFF */
      }
    }
  }
  .home_page_pagination {
    ::v-deep .swiper-pagination-bullet {
      background: #a5a6a6;
      &.swiper-pagination-bullet-active {
        background: #adabab;
      }
    }
  }
}
</style>

<style lang="scss"></style>
