<!--
 * @Author: xing
 * @Date: 2023-09-05 22:28:59
 * @LastEditors: xing
 * @LastEditTime: 2023-09-12 10:49:09
 * @Description: 
-->
<template>
  <div class="mobile_marketing_carousel">
    <div class="page_header">
      <div class="page_header_left">
        <!-- {{ marketDetail.nameEn }} {{ marketDetail.nameEnTwo }} -->
      </div>
      <div class="page_header_right">
        {{ marketDetail.nameEn }} {{ marketDetail.nameEnTwo }}
      </div>
    </div>
    <div class="page_content">
      <div
        class="article_item"
        v-for="(item, index) in detailData"
        :key="index"
      >
        <div class="article_item_top">
          <div class="article_item_title">
            <div class="main_title">{{ item.title }}</div>
            <div class="sub_title">
              {{ item.subTitle }}
            </div>
          </div>
          <div :class="'swiper_image' + index">
            <div class="swiper-wrapper">
              <div
                v-for="(img, i) in JSON.parse(item.images)"
                :key="'img_' + i"
                class="swiper-slide gallery-top-img"
              >
                <img :src="img" alt="" class="img" :preview="item.id + index" />
                <div :class="['swiper-button-prev', 'swiper_prev_' + index]">
                  <img
                    src="https://cdn.gzfmt.com/fmt/swiper/swiper_prev_btn.png"
                    alt=""
                    class="btn_img"
                  />
                </div>
                <div :class="['swiper-button-next', 'swiper_next_' + index]">
                  <img
                    src="https://cdn.gzfmt.com/fmt/swiper/swiper_next_btn.png"
                    alt=""
                    class="btn_img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="article_item_bottom">
          <div class="article_item_des">{{ item.content }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue-photo-preview/dist/skin.css';
import Swiper from 'swiper';
export default {
  props: {
    detailData: {
      type: Array,
      default: () => []
    },
    marketDetail: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {};
  },

  mounted() {
    this.initSwiper();
  },
  methods: {
    initSwiper() {
      this.detailData.forEach((item, i) => {
        new Swiper(this.$el.querySelector('.swiper_image' + i), {
          loop: true,
          navigation: {
            nextEl: '.swiper_next_' + i,
            prevEl: '.swiper_prev_' + i
          }
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.mobile_marketing_carousel {
  min-height: 100vh;
  overflow-y: scroll;
  background: #1a1a1a;
  width: 100%;
  overflow-x: hidden;
  padding-bottom: 20rem;
  box-sizing: border-box;
  .page_header {
    margin-top: 6rem;
    padding: 0 4.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.4rem;
    font-family: SourceHanSansCN-Regular, 'Helvetica Neue';
    font-weight: 400;
    color: #d3d3d3;
    line-height: 1.9rem;
  }
  .page_content {
    margin-top: 5rem;
    .article_item {
      margin-bottom: 5rem;

      .article_item_top {
        .article_item_title {
          text-align: center;
          .main_title {
            margin: 0 auto;
            font-size: 3.8rem;
            font-family: SourceHanSansCN-Medium, 'Helvetica Neue';
            font-weight: 500;
            color: #f7f8f8;
            line-height: 5.2rem;
            margin-bottom: 1.2rem;
            width: 70vw;
            text-align: center;
          }
          .sub_title {
            font-size: 2.4rem;
            font-family: SourceHanSansCN-Medium, 'Helvetica Neue';
            font-weight: 500;
            color: #ffffff;
            line-height: 3rem;
            margin-bottom: 2rem;
          }
        }

        .swiper-wrapper {
        }
        .swiper-slide {
          overflow: hidden;
          text-align: center;
          height: 33.3rem;
          padding: 0 4.4rem;
          box-sizing: border-box;
          .img {
            width: calc(100vw - 15rem);
            height: 100%;
            border-radius: 2rem;
            object-fit: cover;
          }
          .swiper-button-prev,
          .swiper-button-next {
            &.swiper-button-disabled {
              opacity: 1;
            }
            &::after {
              display: none;
            }
            .btn_img {
              width: 1.9rem;
              height: 3.6rem;
            }
          }
        }
      }
      .article_item_bottom {
        margin: 0 auto;
        margin-top: 2rem;
        width: calc(100vw - 15rem);
        .article_item_des {
          font-size: 1.8rem;
          font-family: SourceHanSansCN-Regular, 'Helvetica Neue';
          font-weight: 400;
          color: #ffffff;
          line-height: 3rem;
          text-align: left;
        }
      }
    }
  }
}
</style>
