<!--
 * @Author: xing
 * @Date: 2023-09-04 14:07:54
 * @LastEditors: xing
 * @LastEditTime: 2023-09-09 12:27:13
 * @Description: 
-->
<template>
  <div class="solution-approach">
    <div
      :class="['animate__animated', 'animate__backInRight', 'home-fifth']"
      v-if="show"
    >
      <div class="home-fifth-1">孚马迪全栈式</div>
      <div class="home-fifth-2">用户体验营销解决方案</div>
      <div class="home-fifth-3"></div>
      <div class="home-fifth-4">FMT FULL STACK USER EXPERIENCE</div>
      <div class="home-fifth-5">MARKETING SOLUTION</div>
    </div>
    <SolveGraph :showAnimate="show"></SolveGraph>
  </div>
</template>

<script>
import SolveGraph from '@/views/Home/components/SolveGraph.vue';

export default {
  data() {
    return {
      show: false
    };
  },
  components: {
    SolveGraph
  },

  methods: {
    update() {
      this.show = false;
      setTimeout(() => {
        this.show = true;
      }, 200);
    }
  }
};
</script>

<style lang="scss" scoped>
.solution-approach {
  width: 100vw;
  height: 100vh;
}
.home-fifth {
  position: absolute;
  top: 11.8rem;
  right: 14.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &-1 {
    margin-bottom: 1.2rem;
  }

  &-1,
  &-2 {
    height: 4.6rem;
    line-height: 4.6rem;
    font-size: 4.6rem;
    font-family: SourceHanSansCN-Medium, 'Helvetica Neue';
    font-weight: 500;
    color: #1d3462;
  }

  &-3 {
    width: 5.9rem;
    height: 0.3rem;
    margin: 3rem 0;
    background: #595757;
  }

  &-4,
  &-5 {
    height: 1.6rem;
    line-height: 1.6rem;
    font-size: 1.6rem;
    font-family: SourceHanSansCN-ExtraLight, 'Helvetica Neue';
    font-weight: 200;
    color: #595757;
  }
}
</style>
