<template>
  <nav
    v-if="windowType !== 'phone'"
    :style="{
      height: isTop || isShow ? '12.4rem' : 0
    }"
    @mousewheel.prevent
    @mouseleave="hiddenNav"
    @wheel="isShow = false"
  >
    <div class="logo">
      <img alt="logo" src="https://cdn.gzfmt.com/fmt/home/logo.png" />
    </div>
    <div class="link-container">
      <router-link to="/"> 首页</router-link>
      <router-link
        v-for="item in marketList"
        :key="item.id"
        :to="'/marketing?id=' + item.id"
        >{{ item.name }}</router-link
      >
      <router-link to="/about">关于我们</router-link>
      <router-link to="/join">加入我们</router-link>
    </div>
  </nav>
  <MoveNav v-else />
</template>

<script>
import MoveNav from '@/components/move-nav/move-nav.vue';
import axios from 'axios';
export default {
  components: {
    MoveNav
  },
  data() {
    return {
      isShow: false,
      marketList: []
    };
  },
  mounted() {
    this.getMarketingList();
    window.addEventListener('mousemove', this.ifShowTop);
    window.addEventListener('wheel', this.hiddenNav);
  },
  unmounted() {
    window.removeEventListener('mousemove', this.ifShowTop);
    window.removeEventListener('wheel', this.hiddenNav);
  },
  computed: {
    activeRoutePath() {
      return this.$route.fullPath;
    },
    isTop() {
      // return true
      return this.$store.state.isTop;
    },
    windowType() {
      return this.$store.state.windows.type;
    }
  },
  methods: {
    ifShowTop(e) {
      if (this.windowType !== 'pc') return;
      const clientY = e.clientY;
      if (clientY < 50) {
        this.isShow = true;
      }
    },
    hiddenNav() {
      if (this.isTop) return;
      this.isShow = false;
    },
    getMarketingList() {
      axios.get(this.$api.marketingCategoryApi).then((res) => {
        if (res.data.code === 0) {
          this.marketList = res.data.data;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
nav {
  position: fixed;
  top: 0;
  left: 0;
  // right: 0;
  overflow: hidden;
  width: 100vw;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  height: 12.4rem;
  background-color: #3a3a3a;
  border-bottom: 0.1rem solid #ffffff;
  box-sizing: border-box;
  transition: height 0.3s;
  z-index: 99;

  .logo img {
    width: 12.3rem;
    height: 5.5rem;
    margin-left: 18rem;
  }

  .link-container {
    display: flex;
    margin-left: 27.6rem;
    font-size: 1.4rem;
    color: #181818;
    line-height: 1;
    height: 100%;
  }

  a,
  .router_link_item {
    padding: 0 1.2rem;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 100%;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    // color: red;
    text-decoration: none;
    font-weight: bold;
    margin-right: 6rem;
    font-size: 1.4rem;
    font-family: SourceHanSansCN-Regular, 'Helvetica Neue';
    font-weight: 400;

    /* 选中的样式 */
    &.router-link-exact-active {
      position: relative;
      font-weight: bold;
      color: #ffffff;
    }

    &.router-link-exact-active::before {
      content: '';
      position: absolute;
      bottom: 0;
      /* 调整为合适的位置 */
      left: 0;
      width: 100%;
      height: 0.4rem;
      text-align: center;
      background-color: #ffffff;
      transition: width 0.3s ease;
    }

    &.router-link-exact-active:hover::before {
      // width: 120%;
    }
  }

  /* 移动端适配 */
  //@media (max-width: 1100px) {
  //  .logo img {
  //    margin-left: 6.3rem;
  //  }
  //
  //  .link-container {
  //    flex-wrap: wrap;
  //    justify-content: center;
  //    margin-left: 20rem;
  //    //padding-top: 1rem;
  //    font-size: 0.12rem;
  //  }
  //
  //  a {
  //    padding: 1rem;
  //  }
  //}
}
</style>
