/*
 * @Author: xing
 * @Date: 2023-09-04 14:52:47
 * @LastEditors: xing
 * @LastEditTime: 2023-09-05 22:57:12
 * @Description:
 */
import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

const stateObj = {
  isTop: true,
  isScroll: false
};
const actionsObj = {};
const modulesObj = {};

function importAll(file, type) {
  file.keys().forEach((item) => {
    const reg = /\.\/(.*)\.js/;
    const result = reg.exec(item);
    if (result[1]) {
      const name = result[1];
      const modules = file(item);
      if (modules && modules.default) {
        if (type === 'state') {
          stateObj[name] = modules.default;
        } else if (type === 'mutations') {
          actionsObj[name] = modules.default;
        } else if (type === 'modules') {
          modulesObj[name] = modules.default;
        }
      }
    }
  });
}

importAll(require.context('./state', true, /\.js$/), 'state');

const store = new Vuex.Store({
  state: stateObj,
  actions: actionsObj,
  mutations: {
    pullWindowsSize() {
      const width = document.documentElement.clientWidth;
      this.state.windows.height = document.documentElement.clientHeight;
      this.state.windows.width = width;
      let type = 'pc';
      if (width <= 768) {
        type = 'phone';
      } else if (width > 768 && width <= 992) {
        type = 'ipad';
      } // else if ...
      this.state.windows.type = type;
    }
  }
});

export default store;
