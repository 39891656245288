<template>
  <div ref="carouselContainer" class="develop_history_vue web_content_css">
    <div class="develop_content">
      <div class="home-fourth-title animate__animated animate__bounceIn">
        <div class="big_title">从2OO8年开始</div>
        <div class="sub_title">为品牌客户提供丰富/专业/成熟的营销服务</div>
      </div>

      <!-- 页面内容 应当开始播放动画 -->
      <div class="container" v-show="show">
        <!-- 中间穿过去的线条 -->
        <div class="container_line"></div>
        <div
          v-for="(item, index) in list"
          :key="index"
          :class="['circle_item_box', 'delay-' + (index + 1)]"
        >
          <div class="circle_item"></div>
          <span class="circle_span">{{ item.label }}</span>
          <!--  -->
          <div
            class="vertical_line"
            :class="{ left: index % 2 === 0, right: index % 2 !== 0 }"
          >
            <template>
              <div class="vertical_line_text">
                <div class="item">{{ item.content.join('') }}</div>
              </div>
              <div class="vertical_line_circle"></div>
              <div class="vertical_line_line"></div>
            </template>
          </div>
        </div>
      </div>
      <div class="container" style="opacity: 0" v-show="!show">
        <!-- 中间穿过去的线条 -->

        <div
          v-for="(item, index) in list"
          :key="index"
          :class="['circle_item_box']"
        >
          <div class="circle_item"></div>
          <span class="circle_span">{{ item.label }}</span>
          <!--  -->
          <div
            class="vertical_line"
            :class="{ left: index % 2 === 0, right: index % 2 !== 0 }"
          >
            <template>
              <div class="vertical_line_text">
                <div class="item">{{ item.content.join('') }}</div>
              </div>
              <div class="vertical_line_circle"></div>
              <div class="vertical_line_line"></div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pageShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      list: [
        { label: '2008', content: ['媒体基因，布局新营销', '实现从O-1'] },
        {
          label: '2015',
          content: ['洞见时代，业务升级', '组建数字营销团O-1', '开启营销新篇章']
        },
        {
          label: '2018',
          content: ['构建体验营销板块', '驱动线上&线下体化营销创新']
        },
        {
          label: '2020',
          content: ['布局新能源汽车项目，', '系统化升级品·效·销三维营销模式']
        },
        {
          label: '2021',
          content: ['实战中迭代，做真正', '洞悉用户体验的', '营销服务商']
        },
        { label: '2023', content: ['新能源汽车一体化', '创新营销解决方案'] }
      ],
      containerHeight: 0,
      clientTop: 0,
      show: false
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.containerHeight = this.$refs.carouselContainer.clientHeight;
      this.clientTop = this.$refs.carouselContainer.offsetTop;
    });
    window.addEventListener('scroll', this.changeAnimation);
  },
  unmounted() {
    window.removeEventListener('scroll', this.changeAnimation);
  },
  computed: {},
  watch: {
    pageShow: {
      handler(val) {
        if (!val) this.show = false;
      },
      immediate: true
    }
  },
  methods: {
    changeAnimation(e) {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const winHeight = document.documentElement.clientHeight;
      if (
        scrollTop > 0.6 * winHeight &&
        scrollTop <= this.clientTop + this.containerHeight &&
        !this.show
      ) {
        // this.update();
        this.show = true;
      } else if (scrollTop === 0 && this.show) {
        this.show = false;
      }
    },
    update() {
      // this.show = false;
      // setTimeout(() => {
      this.show = true;
      // }, 200);
    }
  }
};
</script>

<style lang="scss" scoped>
@for $i from 1 through 6 {
  .delay-#{$i} {
    animation-delay: #{0.4 * ($i - 1)}s;
  }
}
@keyframes showBoxAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes breathingAnimation {
  0% {
    background-color: white;
    box-shadow: 0 0 0 rgba(217, 225, 239, 0.8);
  }

  50% {
    background-color: #f3f5fd;
    box-shadow: 0 0 12px rgba(217, 225, 239, 0.5);
  }

  100% {
    background-color: white;
    box-shadow: 0 0 0 rgba(217, 225, 239, 0.8);
  }
}
.web_content_css {
  &.develop_history_vue {
    width: 100vw;
    min-height: 100vh;
    padding-top: 12.9rem;
    box-sizing: border-box;
    position: relative;
    background: #efefef;
    .develop_content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .home-fourth-title {
        text-align: center;
        font-size: 3.8rem;
        font-family: SourceHanSansCN-Medium, 'Helvetica Neue';
        font-weight: 500;
        color: #3e3a39;
        line-height: 4.2rem;
        padding: 0 7.2rem;
        .big_title {
          line-height: 5.3rem;
        }
        .sub_title {
          margin-top: 1.7rem;
        }
      }
      .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-bottom: 10rem;
        margin-top: 4.1rem;
        .container_line {
          position: absolute;
          top: 8.7rem;
          left: 50%;
          transform: translateX(-50%);
          height: 80%;
          width: 0.1rem;
          background-color: #e0e0e0;
          animation-name: showBoxAnimation;
          animation-duration: 6s;
        }
        .circle_item_box {
          width: 14rem;
          height: 14rem;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          opacity: 0;
          animation-name: showBoxAnimation;
          animation-duration: 4s;
          animation-fill-mode: forwards;
          margin-bottom: 5.67rem;
          cursor: pointer;

          .circle_item {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            border-radius: 50%;
            background: radial-gradient(
              circle at center,
              #d9e1ef 20%,
              rgba(204, 211, 224, 0) 70%
            );
            animation: breathingAnimation 3s infinite alternate;
            animation-timing-function: ease-in-out;
          }
          .circle_span {
            font-size: 3.6rem;
            font-family: SourceHanSansCN-Medium, 'Helvetica Neue';
            font-weight: 500;
            color: #3e3a39;
            line-height: 3.6rem;
            position: relative;
            text-align: center;
          }
          .vertical_line {
            position: absolute;
            top: 50%;
            text-align: center;
            display: flex;
            // flex-direction: column;
            align-items: center;

            &.left {
              left: 0;
              transform: translateY(-50%) translateX(-100%);
            }
            &.right {
              right: 0;
              transform: translateY(-50%) translateX(100%);
              flex-direction: row-reverse;
              .vertical_line_text {
                margin-top: 0;
                margin-left: 2rem;
                margin-right: 0;
              }
            }

            .vertical_line_line {
              width: 3rem;
              height: 0.1rem;
              background: #e0e0e0;
            }
            .vertical_line_circle {
              width: 0.8rem;
              height: 0.8rem;
              border-radius: 50%;
              background: #e0e0e0;
            }
            .vertical_line_text {
              margin-right: 2rem;
              width: 19rem;
              text-align: center;
              font-size: 1.8rem;
              font-family: SourceHanSansCN-Regular, 'Helvetica Neue';
              font-weight: 400;
              color: #595757;
              line-height: 2.1rem;
              .item {
                display: inline-block;
                word-wrap: break-word;
                text-align: center;
                font-size: 1.8rem;
                font-family: SourceHanSansCN-Regular, 'Helvetica Neue';
                font-weight: 400;
                color: #595757;
                line-height: 3rem;
              }
            }
          }
        }
      }
    }
  }
}

// 2008...
</style>
