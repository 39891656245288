<!--
 * @Author: xing
 * @Date: 2023-09-05 14:34:25
 * @LastEditors: xing
 * @LastEditTime: 2023-09-09 14:12:16
 * @Description: 
-->
<template>
  <div class="business_vue">
    <div class="m-card-flex">
      <div
        class="m-card"
        @click="toDetail(index)"
        v-for="(item, index) in businessList"
        :key="index"
      >
        <img alt="" class="m-card-bg" :src="item.imageUrl" />
        <div class="m-card-title">
          <div class="title1">{{ item.title }}</div>
          <div class="title2">{{ item.titleEn }} {{ item.titleEnTwo }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    businessList: {
      type: Array,
      default: () => []
    },
    marketingList: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    toDetail(index) {
      this.$router.push('/marketing?id=' + this.marketingList[index].id);
    }
  }
};
</script>

<style lang="scss" scoped>
.business_vue {
  min-height: 100vh;
  // padding-top: 8.8rem;
  box-sizing: border-box;
  background-color: #3a3a3a;
  display: flex;
  flex-direction: column;
  justify-content: center;

  /* 移动端 */
  .m-card-flex {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: 4rem 0;
    .m-card {
      position: relative;
      width: calc(100vw - 12rem);
      height: 35.6rem;
      display: flex;
      justify-content: center;
      border-radius: 2.4rem;
      overflow: hidden;
      margin-bottom: 4rem;
      .m-card-bg {
        width: 100%;
        height: 100%;
        object-fit: cover;
        //position: relative;
      }
    }

    .m-card-title {
      position: absolute;
      top: 4.6rem;
      //left: ;
      //top: -26rem;
      //left: 50%;
      //transform: translate(-50%, -50%);
      /* 其他样式 */

      .title1 {
        font-size: 5.2rem;
        font-family: SourceHanSansCN-Medium, 'Helvetica Neue';
        font-weight: 500;
        color: #000000;
        line-height: 5.2rem;
        line-height: 5.2rem;
        margin-bottom: 0.8rem;
      }

      .title2 {
        height: 2.6rem;
        font-size: 2.6rem;
        font-family: SourceHanSansCN-Regular, 'Helvetica Neue';
        font-weight: 400;
        color: #000000;
        line-height: 2.6rem;
      }
    }
  }
}
</style>
