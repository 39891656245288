<template>
  <!-- 轮播7 -->

  <div :class="{ 'm-page-ending': show }">
    <img
      alt=""
      class="m-ending-bg"
      src="https://cdn.gzfmt.com/fmt/home/home_ending_bg.png"
    />

    <div class="m-ending-content">
      <div class="animate__animated animate__bounceInUp">
        <img
          alt=""
          class="ending-big-logo"
          src="https://cdn.gzfmt.com/fmt/home/home_dizhidatubiao.png"
        />
        <div class="m-ending-title">CONTACT US</div>
      </div>

      <div class="m-ending-infos">
        <div class="m-telephone">
          <img
            alt=""
            class="m-telephone-icon"
            src="https://cdn.gzfmt.com/fmt/home/home_ending_telephone.png"
          />
          <div class="m-telephone-info">电话 {{ companyDetail.phone }}</div>
        </div>
        <div class="m-email">
          <img
            alt=""
            class="m-telephone-icon"
            src="https://cdn.gzfmt.com/fmt/home/home_ending_mailbox.png"
          />
          <div class="m-email-info">邮件 {{ companyDetail.email }}</div>
        </div>
        <div class="m-address">
          <img
            alt=""
            class="m-telephone-icon"
            src="https://cdn.gzfmt.com/fmt/home/home_ending_address.png"
          />
          <div class="m-address-info">地址 {{ companyDetail.address }}</div>
        </div>
        <div class="m-qrcode">
          <div class="m-qrcode-about">
            <img :src="companyDetail.qrcode" alt="" class="img" />
          </div>
          <div class="m-qrcode-infos">
            <img
              alt=""
              class="m-qrcode-icon"
              src="https://cdn.gzfmt.com/fmt/home/home_ending_wechat.png"
            />
            <div class="m-qrcode-info">{{ companyDetail.nickname }}</div>
          </div>
        </div>
      </div>
    </div>
    <AQRecordVue></AQRecordVue>
  </div>
</template>

<script>
import AQRecordVue from '@/components/AQRecord.vue';
export default {
  components: {
    AQRecordVue
  },
  props: {
    companyDetail: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      show: false
    };
  },
  mounted() {
    this.update();
  },
  methods: {
    update() {
      this.show = false;
      this.show = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.m-page-ending {
  position: relative;
  width: 100vw;
  // padding-top: 8.8rem;
  // height: calc(100vh - 8.8rem);
  min-height: 100vh;
  .m-ending-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .m-ending-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    text-align: center;
    padding-top: 13rem;
    padding-bottom: 16rem;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    .ending-big-logo {
      width: 3.6rem;
      height: 8rem;
    }

    .m-ending-title {
      margin-top: 3rem;
      font-size: 10rem;
      font-family: SourceHanSansCN-Bold, 'Helvetica Neue';
      font-weight: 700;
      color: #f2f2f2;
      line-height: 11.2rem;
    }

    .m-ending-infos {
      font-size: 2.4rem;
      font-family: SourceHanSansCN-Regular, 'Helvetica Neue';
      font-weight: 400;
      color: #f2f2f2;
      line-height: 3.4rem;

      .m-telephone,
      .m-email,
      .m-address {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 2.6rem;
      }

      .m-telephone-icon {
        width: 4rem;
        height: 4rem;
        margin-right: 0.8rem;
      }
    }

    .m-qrcode {
      margin-top: 4.3rem;
      .m-qrcode-about {
        width: 17.4rem;
        height: 17.4rem;
        background: #f2f2f2;
        border-radius: 2rem;
        margin: 0 auto;
        .img {
          width: 100%;
          height: 100%;
          vertical-align: middle;
        }
      }

      .m-qrcode-infos {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;

        .m-qrcode-icon {
          width: 3.6rem;
          height: 3.6rem;
          margin-right: 0.8rem;
        }

        .m-qrcode-info {
          font-size: 2.4rem;
          font-family: SourceHanSansCN-Regular, 'Helvetica Neue';
          font-weight: 400;
          color: #f2f2f2;
          line-height: 2.4rem;
        }
      }
    }
  }
}
</style>
