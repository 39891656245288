<!--
 * @Author: xing
 * @Date: 2023-09-04 14:52:47
 * @LastEditors: xing
 * @LastEditTime: 2023-09-11 17:33:47
 * @Description: 
-->
<template>
  <div class="about">
    <div class="about-bg">
      <img src="https://cdn.gzfmt.com/fmt/home/about_guangyuwm.png" alt="" />
    </div>

    <div class="about-infos">
      <div class="about-infos-title">公司简介</div>
      <div class="about-infos-content">
        <div>
          孚马迪科技是一家致力于以技术和理念创新驱动品牌增长的科技营销公司。公司的核心团队得益于国际4A、一线媒体集团等多年的专业经验和积累，能为客户在线上线下一体化创新营销方面提供专业的解决方案。
        </div>
        <div>
          公司核心服务内容包括新媒体营销（直播运营、新媒体平台运营、XR营销）、公关/体验营销（公关策略/媒介购买/执行管控、艺人资源、大型展览、数字展厅、新品发布、快闪活动等）、媒介代理（媒介策略、媒介购买、执行管控，并自有部分优势户外资源，独家代理较多热门IP资源）、新智造（定制产品研发、模块化展具及展厅开发等）。
        </div>
        <div>
          孚马迪科技洞察行业趋势与变革机会，从 2018
          年开始布局新能源汽车营销业务，从产品研发、用户决策、品牌营销、销售管理等方面深度研究新能源汽车与传统燃油车的差异化，通过不断探索与总结，从技术手段和数字化方式实现各个营销环节的前后承继和闭环，沉淀适用于新能源汽车品牌营销的一体化解决方案。
          凭借专业服务，公司与包括广汽丰田、广汽本田、广汽埃安、昊铂、东风日产、中国银行等一线知名品牌，保持了长期稳定的合作关系，并赢得了广泛的认可和好评。
        </div>
      </div>
    </div>
    <AQRecordVue></AQRecordVue>
  </div>
</template>
<script>
import AQRecordVue from '@/components/AQRecord.vue';
export default {
  components: {
    AQRecordVue
  }
};
</script>
<style lang="scss" scoped>
.about {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding-top: 14.8rem;
  box-sizing: border-box;
  overflow: hidden;
  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &-bg img {
    width: 100%;
    height: 100%;
    vertical-align: middle;
    object-fit: cover;
  }

  /* 公司简介 */
  &-infos {
    // width: 80%;
    // height: 60rem;
    color: #ffffff;
    position: relative;
    padding: 0 4.5rem;
    margin-bottom: 4rem;
    &-title {
      font-size: 3.8rem;
      font-family: SourceHanSansCN-Medium, 'Helvetica Neue';
      font-weight: 500;
      line-height: 3.8rem;
      text-align: center;
    }
    //  @media (min-height: 901px) {
    //   &-content {
    //     line-height: 6rem;
    //   }
    // }
    // @media (max-height: 900px) {
    //   &-content {
    //     line-height: 5rem;
    //   }
    // }

    &-content {
      margin-top: 4.7rem;
      //height: 800px;
      font-size: 2rem;
      font-family: SourceHanSansCN-Medium, 'Helvetica Neue';
      font-weight: 500;
      color: #ffffff;
      line-height: 5rem;
      text-indent: 5.2rem;
    }
  }
}
</style>
