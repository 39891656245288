/*
 * @Author: xing
 * @Date: 2023-09-04 14:52:47
 * @LastEditors: xing
 * @LastEditTime: 2023-09-12 10:56:17
 * @Description:
 */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import api from '@/api';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import preview from 'vue-photo-preview';

import { Loading } from 'element-ui';
Vue.use(Loading); // 使用服务方式的话，只安装Loading即可
Vue.use(Loading.directive); //  指令方式(v-loading)的话这两行都得有
Vue.use(preview, {
  counterEl: false //控制是否显示左上角图片数量按钮
});

import 'reset-css';
import 'swiper/css/swiper.css';
import 'animate.css';

import './assets/css/index.css';

Vue.config.productionTip = false;
Vue.prototype.$api = api;
Vue.use(VueAwesomeSwiper);

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
