/*
 * @Author: xing
 * @Date: 2023-09-05 14:30:22
 * @LastEditors: xing
 * @LastEditTime: 2023-09-07 11:53:34
 * @Description:
 */
import HomeView from '../views/MobileHome/HomeView.vue';
import MobileMarketingView from '@/views/MobileMarketing/MarketingView.vue';
import MobileAboutView from '@/views/MobileAbout/AboutView.vue';
import MobileJoinView from '@/views/MobileJoin/JoinView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },

  {
    path: '/marketing',
    name: 'marketing',
    component: MobileMarketingView
  },

  {
    path: '/about',
    name: 'about',
    // 关于我们
    component: MobileAboutView
  },
  {
    path: '/join',
    name: 'join',
    // 加入我们
    component: MobileJoinView
  }
];

// const router = new VueRouter({
//   routes: routes,
//   linkActiveClass: 'router_link_item'
// });

export default routes;
