<template>
  <div class="business_vue">
    <div class="card-flex">
      <div
        class="card_item"
        v-for="(item, index) in businessList"
        :key="index"
        @click="toDetail(index)"
      >
        <div class="card">
          <div class="front">
            <img alt="" class="img" :src="item.imageUrl" />
            <div class="card_suspend">
              <div class="title">{{ item.title }}</div>
              <div class="sub_title">
                {{ item.titleEn }} {{ item.titleEnTwo }}
              </div>
            </div>
          </div>
          <div class="back">
            <img alt="" class="img" :src="item.imageUrlTwo" />
            <div class="card_suspend">
              <div class="titleEn" style="margin-top: 12.6rem">
                {{ item.titleEn }}
              </div>
              <div class="titleEn">{{ item.titleEnTwo }}</div>
              <div class="titleEn_two">{{ item.title }}</div>
              <div class="division_line"></div>
              <div class="content">
                <div
                  class="content_item"
                  v-for="content in item.content.split('；')"
                  :key="content"
                >
                  {{ content }}
                </div>
              </div>
              <div class="know_more">了解更多</div>
            </div>
          </div>

          <div class="left"></div>
          <!-- <div class="right"></div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    businessList: {
      type: Array,
      default: () => []
    },
    marketingList: {
      type: Array,
      default: () => []
    }
  },
  components: {},
  computed: {},
  methods: {
    toDetail(index) {
      this.$router.push('/marketing?id=' + this.marketingList[index].id);
    }
  }
};
</script>

<style lang="scss" scoped>
.business_vue {
  height: 100vh;
  background-color: #3a3a3a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // 包裹翻转的最外层盒子
  .card-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12rem;
    width: 100%;
    padding-top: 12.7rem;
  }
  .card_item {
    perspective: 1000px;
    height: 72.5rem;
    width: 48rem;
    &:hover .card {
      transform: perspective(5000px) rotate3d(0, 1, 0, 180deg);
      .front {
        transform: rotateY(-180deg) translateZ(-2rem);
      }
      .back {
        transform: translateZ(0);
      }
      .left {
        transform: translateZ(2rem) rotateY(90deg);
      }
      .right {
        transform: translateZ(2rem) rotateY(90deg);
      }
    }
  }
  // 卡片外壳
  .card {
    height: 100%;
    width: 100%;
    /* 观察者与元素之间的距离 */
    /* 开启3D效果 */
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    transition: transform 1.2s;
    background: rgba(#2980b9, 0.7);
    border-radius: 2.1rem;
    position: relative;
    background: #ffffff;
    // transform: rotate3d(0, 1, 0, 130deg);
    // 图片自身
    .front,
    .back {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 2.1rem;
      &.front {
        background-color: #f1f1f1;
        transform: rotateY(-180deg) translateZ(0px);
      }
      &.back {
        background-color: #2980b9;
        color: #fff;
        /* 初始状态为反面朝上 */
        transform: translateZ(-2rem);
        box-shadow: 0 1rem 2rem rgba(192, 188, 188, 0.2);
      }
      .img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        // backface-visibility: hidden;
        transform: rotateY(-180deg);
        border-radius: 2.4rem;
      }
    }
    .left {
      position: absolute;
      top: 2.1rem;
      width: 2rem;
      height: calc(100% - 4.2rem);
      transform-origin: 0 0;
      transform: translateZ(0rem) rotateY(90deg);
      // transition: transform 1.2s;
      background: #2980b9;
      left: 0;
      z-index: 3;
    }
    .right {
      position: absolute;
      top: 2.1rem;
      width: 2rem;
      height: calc(100% - 4.2rem);
      transform-origin: 100% 0;
      transform: translateZ(-2rem) rotateY(90deg);
      background: #2980b9;
      right: 0;
      z-index: 3;
    }
    .card_suspend {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transform: rotateY(180deg);
      cursor: pointer;
      .title {
        font-size: 4.6rem;
        font-family: SourceHanSansCN-Medium, 'Helvetica Neue';
        font-weight: 500;
        color: #231815;
        line-height: 4.6rem;
        margin-top: 5.2rem;
        text-align: center;
      }
      .sub_title {
        margin-top: 1.2rem;
        font-size: 2.6rem;
        font-family: SourceHanSansCN-Bold, 'Helvetica Neue';
        font-weight: 700;
        color: #231815;
        line-height: 2.6rem;
        text-align: center;
      }
      .titleEn {
        font-size: 4.4rem;
        font-family: SourceHanSansCN-Bold, 'Helvetica Neue';
        font-weight: 700;
        color: #ffffff;
        line-height: 4.4rem;
        margin-top: 0.9rem;
        margin-left: 5.4rem;
      }
      .titleEn_two {
        margin-top: 1.1rem;
        font-size: 2.4rem;
        font-family: SourceHanSansCN-Medium, 'Helvetica Neue';
        font-weight: 500;
        color: #ffffff;
        line-height: 2.4rem;
        margin-left: 5.4rem;
      }
      .division_line {
        margin-top: 2rem;
        width: 6.4rem;
        height: 0.4rem;
        margin-left: 5.4rem;
        background: #ffffff;
      }
      .content {
        margin-top: 11rem;
        margin-left: 5.4rem;
        font-size: 1.4rem;
        font-family: SourceHanSansCN-Regular, 'Helvetica Neue';
        font-weight: 400;
        color: #ffffff;
        line-height: 1.4rem;
        .content_item {
          margin-bottom: 1.2rem;
        }
      }
      .know_more {
        margin-top: 14.3rem;
        margin-left: 5.4rem;
        width: 10rem;
        height: 3rem;
        border: 0.1rem solid #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.4rem;
        font-family: SourceHanSansCN-Medium, 'Helvetica Neue';
        font-weight: 500;
        color: #ffffff;
        line-height: 1.9rem;
      }
    }
  }
}
</style>
