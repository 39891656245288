<!--
 * @Author: xing
 * @Date: 2023-09-04 14:52:47
 * @LastEditors: xing
 * @LastEditTime: 2023-09-11 16:03:10
 * @Description: 
-->
<template>
  <div class="number_marketing_page" v-loading="loading">
    <MobileMarketingContext :dataObj="marketDetail" v-if="marketDetail" />
    <MarketingCarouselVue
      :detailData="detailData"
      :marketDetail="marketDetail"
      v-if="marketDetail && detailData.length > 0"
    ></MarketingCarouselVue>
    <AQRecordVue></AQRecordVue>
  </div>
</template>

<script>
// import Swiper from 'swiper';
import MobileMarketingContext from '@/components/marketing-context/MobileMarketingContext.vue';
import MarketingCarouselVue from '@/components/marketing-carousel/MobileMarketingCarousel.vue';
import AQRecordVue from '@/components/AQRecord.vue';
import axios from 'axios';

export default {
  components: {
    MobileMarketingContext,
    MarketingCarouselVue,
    AQRecordVue
  },
  data() {
    return {
      loading: false,
      marketDetail: null,
      detailData: []
    };
  },
  computed: {
    categoryId() {
      return this.$route.query.id;
    }
  },
  watch: {
    categoryId: {
      handler(val) {
        if (!val) return;
        this.getMarketDetail();
      },
      immediate: true
    }
  },
  mounted() {},
  methods: {
    getMarketDetail() {
      this.loading = true;
      Promise.all([
        axios.get(this.$api.marketingDetailApi + this.categoryId),
        axios.get(
          this.$api.marketingCategoryDetailApi +
            '?categoryId=' +
            this.categoryId
        )
      ])
        .then((resArr) => {
          const res1 = resArr[0];
          const res2 = resArr[1];
          if (res1.data.code === 0) {
            this.marketDetail = res1.data.data;
          }
          if (res2.data.code === 0) {
            this.detailData = res2.data.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.number_marketing_page {
  position: relative;
  background-color: #3a3a3a;
}
</style>
