<template>
  <!--轮播6-->
  <div class="brand-logo">
    <div class="home-six-title">
      <div class="home-six-title-1">
        继续携手更多品牌客户，共赢共利创造无限价值！
      </div>
      <div class="home-six-title-2">
        Continue to work together with more brand customers to create unlimited
        value for mutual benefit and win-win situation!
      </div>
    </div>

    <div class="brand-logo-content">
      <!-- 三栏左右滑动 -->
      <div class="home-six-logo-area">
        <div class="logo-area-top">
          <ul class="logo-area-top-ul">
            <li>
              <img
                alt="brand_logo_1"
                src="https://cdn.gzfmt.com/fmt/home/brand_logo_1.png"
              />
            </li>
            <li>
              <img
                alt="brand_logo_1"
                src="https://cdn.gzfmt.com/fmt/home/brand_logo_1.png"
              />
            </li>
          </ul>
        </div>
        <div class="logo-lint"></div>
        <div class="logo-area-middle">
          <ul class="logo-area-middle-ul">
            <li>
              <img
                alt="brand_logo_2"
                src="https://cdn.gzfmt.com/fmt/home/brand_logo_2.png"
              />
            </li>
            <li>
              <img
                alt="brand_logo_2"
                src="https://cdn.gzfmt.com/fmt/home/brand_logo_2.png"
              />
            </li>
          </ul>
        </div>
        <div class="logo-lint"></div>

        <div class="logo-area-bottom">
          <ul class="logo-area-bottom-ul">
            <li>
              <img
                alt="brand_logo_3"
                src="https://cdn.gzfmt.com/fmt/home/brand_logo_3.png"
              />
            </li>
            <li>
              <img
                alt="brand_logo_3"
                src="https://cdn.gzfmt.com/fmt/home/brand_logo_3.png"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.brand-logo {
  width: 90vw;
  height: 100vh;
  max-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  .brand-logo-content {
    height: 0;
    flex: 1;
    display: flex;
    align-items: center;
  }

  .home-six-title {
    //margin-left: 10.6rem;
    //margin-top: 10.6rem;
    margin-top: 14rem;
  }

  .home-six-title-1 {
    //width: 110rem;
    height: 7rem;
    font-size: 5rem;
    font-family: SourceHanSansCN-Regular, 'Helvetica Neue';
    font-weight: 400;
    color: #231815;
    line-height: 7rem;
  }

  .home-six-title-2 {
    // width: 81.2rem;
    height: 1.9rem;
    font-size: 1.4rem;
    font-family: SourceHanSansCN-Medium, 'Helvetica Neue';
    font-weight: 500;
    color: #231815;
    line-height: 1.9rem;
    margin: 0.9rem 0 0 0.9rem;
  }

  // 轮播6-logo品牌区域
  .home-six-logo-area {
    width: 100%;
    // margin-top: 7.8rem;
    //margin-left: 11.1rem;
    overflow: hidden;

    .logo-lint {
      width: 100%;
      border: 0.2rem solid #818181;
      margin: 5rem auto;
    }

    .logo-area-middle {
      margin: 3rem 0 2rem;
      &:hover {
        cursor: pointer;
        // animation-play-state: paused;
      }

      animation: logoMiddle 30s infinite linear;

      ul li img {
        @keyframes logoMiddle {
          0% {
            transform: translateX(-100%);
          }

          100% {
            transform: translateX(0%);
          }
        }
      }
    }
    .logo-area-top,
    .logo-area-bottom {
      animation: logoOther 30s infinite linear;
      &:hover {
        cursor: pointer;
        // animation-play-state: paused;
      }

      ul li img {
        @keyframes logoOther {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-100%);
          }
        }
      }
    }
  }

  .logo-area-top {
    margin-bottom: 3rem;
  }

  .logo-area-bottom {
    margin-top: 3rem;
  }

  .logo-area-top,
  .logo-area-middle,
  .logo-area-bottom {
    position: relative;
    width: 120%;
    height: 12rem;

    ul {
      display: flex;
      position: absolute;
      left: 0;
      width: 200%;
      height: 12rem;
    }

    li {
      display: flex;
      width: 100%;
      height: 100%;

      //margin: 0 4rem 0 0;

      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        margin-right: 10%;
      }
    }
  }

  @media screen and (min-width: 2000px) {
    .logo-area-top,
    .logo-area-middle,
    .logo-area-bottom {
      li {
        display: flex;
        width: 100%;
        height: 120%;
      }
    }
  }
}

.m-brand-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  .m-brand-logo-title {
    .title1 {
      width: 45.5rem;
      height: 11.2rem;
      font-size: 4rem;
      font-family: SourceHanSansCN-Regular, 'Helvetica Neue';
      font-weight: 400;
      color: #231815;
      line-height: 5.6rem;
      margin-top: 5.9rem;
      margin-bottom: 0.5rem;
      margin-left: 15rem;

      //-webkit-background-clip: text;
      //-webkit-text-fill-color: transparent;
    }

    .title2 {
      img {
        width: 60%;
        margin-left: 15rem;
      }
    }

    .m-logo-area {
      img {
        width: 80%;
        object-fit: cover;
        margin-left: 5rem;
        margin-top: 7rem;
      }
    }
  }
}
</style>
