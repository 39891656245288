<template>
  <!-- 轮播7 -->
  <div :class="{ 'page-ending': show }">
    <div class="swiper-slide7-bg">
      <img
        alt=""
        class="ending-big-logo"
        src="https://cdn.gzfmt.com/fmt/home/home_ending_bg.png"
      />
    </div>

    <div class="home-content7">
      <div class="home-content7-logo animate__animated animate__bounceInUp">
        <img
          alt=""
          class="ending-big-logo"
          src="https://cdn.gzfmt.com/fmt/home/home_dizhidatubiao.png"
        />
      </div>
      <div class="home-content7-name">CONTACT US</div>

      <div class="home-content7-infos">
        <div class="telephone">
          <img
            alt=""
            class="telephone-icon"
            src="https://cdn.gzfmt.com/fmt/home/home_ending_telephone.png"
          />
          <div class="telephone-info">电话 {{ companyDetail.phone }}</div>
        </div>
        <div class="email">
          <img
            alt=""
            class="telephone-icon"
            src="https://cdn.gzfmt.com/fmt/home/home_ending_mailbox.png"
          />
          <div class="email-info">邮件 {{ companyDetail.email }}</div>
        </div>
        <div class="address">
          <img
            alt=""
            class="telephone-icon"
            src="https://cdn.gzfmt.com/fmt/home/home_ending_address.png"
          />
          <div class="address-info">地址 {{ companyDetail.address }}</div>
        </div>
      </div>

      <div class="qrcode">
        <div class="qrcode-about">
          <img :src="companyDetail.qrcode" alt="" class="img" />
        </div>
        <div class="qrcode-infos">
          <img
            alt=""
            class="qrcode-icon"
            src="https://cdn.gzfmt.com/fmt/home/home_ending_wechat.png"
          />
          <div class="qrcode-info">{{ companyDetail.nickname }}</div>
        </div>
      </div>
    </div>
    <AQRecordVue></AQRecordVue>
  </div>
</template>

<script>
import AQRecordVue from '@/components/AQRecord.vue';
export default {
  components: {
    AQRecordVue
  },
  props: {
    companyDetail: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      show: false
    };
  },
  methods: {
    update() {
      this.show = false;
      this.show = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.page-ending {
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  .swiper-slide7-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .ending-big-logo {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .home-content7 {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 82vw;

    &-logo {
      .ending-big-logo {
        width: 7rem;
        height: auto;
        object-fit: contain;
      }
    }

    &-name {
      // width: 90rem;
      height: 14rem;
      font-size: 14rem;
      font-family: SourceHanSansCN-Bold, 'Helvetica Neue';
      font-weight: 700;
      color: #f2f2f2;
      line-height: 14rem;
      margin: 2rem 0;
    }
  }

  .home-content7-infos {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;

    .telephone,
    .email,
    .address {
      display: flex;
      align-items: center;
      margin-right: 0.8rem;

      // 图标
      .telephone-icon,
      .email-icon,
      .address-icon {
        width: 4rem;
        height: 4rem;
        margin-right: 0.8rem;
        object-fit: cover;
      }
    }

    .telephone-info,
    .email-info,
    .address-info {
      height: 3.4rem;
      font-size: 2.4rem;
      font-family: SourceHanSansCN-Regular, 'Helvetica Neue';
      font-weight: 400;
      color: #f2f2f2;
      line-height: 3.4rem;
      white-space: nowrap;
    }
  }

  .qrcode {
    .qrcode-about {
      width: 17.4rem;
      height: 17.4rem;
      background-color: #ffffff;
      border-radius: 2rem;
      margin: 4rem;
      .img {
        width: 100%;
        height: 100%;
        vertical-align: middle;
      }
    }

    .qrcode-infos {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #f2f2f2;

      .qrcode-icon {
        width: 3.6rem;
        height: 3.7rem;
        //border-radius: 50%;
        //background-color: #b3b3b3;
        margin-right: 0.8rem;
        white-space: nowrap;
      }

      .qrcode-info {
        height: 2.4rem;
        font-size: 2.4rem;
        font-family: SourceHanSansCN-Regular, 'Helvetica Neue';
        font-weight: 400;
        color: #f2f2f2;
        line-height: 2.4rem;
      }
    }
  }

  // swiper库的样式
  .home-first,
  .home-two,
  .home-three {
    width: 60.3rem;
    height: 62.3rem;
    position: absolute;
    /* 调整文字容器的位置 */
    top: 13.9rem;
    //left: 9.2%;
    left: 18rem;
    transform: translate(-50%, -50%);
    font-family: SourceHanSansCN-Regular, 'Helvetica Neue';
    font-weight: 400;
  }
}
</style>
