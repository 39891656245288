<template>
  <div class="move-nav" :style="{ height: isScroll ? 0 : '8.8rem' }">
    <div class="move-logo">
      <img alt="logo" src="https://cdn.gzfmt.com/fmt/home/logo.png" />
    </div>
    <div class="move-nav-right">
      <div class="nav-search">
        <img alt="" src="https://cdn.gzfmt.com/fmt/m_nav/m_nav_search.png" />
      </div>
      <div class="nav-more" @click="isShowMore">
        <img alt="" src="https://cdn.gzfmt.com/fmt/m_nav/m_nav_more.png" />
      </div>
    </div>

    <!-- 下拉菜单 -->
    <div v-if="showMenu" :class="['dropdown-menu', { 'fade-in': showMenu }]">
      <ul>
        <li
          v-for="(item, index) in moreMenu"
          :key="item"
          @click="handleMenuItemClick(item)"
        >
          {{ item }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      showMenu: false, // 控制下拉菜单显示与隐藏
      marketList: []
    };
  },

  computed: {
    isScroll() {
      return this.$store.state.isScroll;
    },
    moreMenu() {
      const categoryList = this.marketList.map((t) => t.name);
      const list = ['首页', ...categoryList, '关于我们', '加入我们'];
      return list;
    }
  },
  mounted() {
    this.getMarketingList();
  },
  methods: {
    isShowMore() {
      this.showMenu = !this.showMenu; // 切换下拉菜单的显示与隐藏
    },
    handleMenuItemClick(item) {
      const menuObj = {
        首页: '/',
        关于我们: '/about',
        加入我们: '/join'
      };
      this.marketList.forEach((t) => {
        menuObj[t.name] = `/marketing?id=${t.id}`;
      });
      const targetPath = `${menuObj[item]}`;
      if (this.$route.path !== targetPath) {
        this.$router.push(targetPath, () => {
          this.showMenu = false;
        });
      } else {
        this.showMenu = false;
      }
    },
    getMarketingList() {
      axios.get(this.$api.marketingCategoryApi).then((res) => {
        if (res.data.code === 0) {
          this.marketList = res.data.data;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.move-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #3a3a3a;
  width: 100vw;
  height: 8.8rem;
  position: fixed;
  top: 0;
  left: 0;
  transition: height 0.6s;
  z-index: 299;
  .move-logo {
    margin-left: 4.2rem;
    img {
      width: 8.2rem;
      height: 3.6rem;
    }
  }
  .move-nav-right {
    display: flex;
    margin-right: 4.2rem;

    .nav-search {
      margin-right: 2.4rem;
      img {
        width: 2.4rem;
        height: 3.2rem;
        vertical-align: middle;
      }
    }
    .nav-more {
      img {
        width: 3.8rem;
        height: 2.8rem;
      }
    }
  }
}

.dropdown-menu {
  position: absolute;
  top: 8.8rem;
  left: 0;
  width: 100%;
  height: calc(100vh - 8.8rem);
  background-color: #3c3c3c;
  color: #ffffff;
  z-index: 9999; // 设置元素在最上层
  opacity: 0;
  transition: opacity 0.3s;

  ul {
    padding: 1rem;
    list-style: none;
    margin: 5rem 4.2rem;

    li {
      margin-bottom: 0.5rem;
      border-bottom: 0.1rem solid #ffffff;

      height: 4.6rem;
      font-size: 3.3rem;
      font-family: SourceHanSansCN-Regular, 'Helvetica Neue';
      font-weight: 400;
      color: #ffffff;
      line-height: 4.6rem;
      padding: 2.4rem 0;

      &:active {
        background-color: #5b5b5b;
        transition: all 0.1s;
      }
    }
  }
}

.fade-in {
  opacity: 1;
}
</style>
