<!--
 * @Author: xing
 * @Date: 2023-09-04 21:16:49
 * @LastEditors: xing
 * @LastEditTime: 2023-09-09 14:12:22
 * @Description: 
-->
<template>
  <BaseLayout />
</template>

<script>
import { set } from 'vue';
import BaseLayout from './layout/index.vue';
export default {
  components: {
    BaseLayout
  },
  data() {
    return {
      scrollTimer: null
    };
  },
  mounted() {
    this.pullWindowsSize();
    window.addEventListener('resize', this.pullWindowsSize);
  },
  unmounted() {
    window.removeEventListener('resize', this.pullWindowsSize);
  },
  methods: {
    pullWindowsSize() {
      this.handleHtmlFontSize();
      this.$store.commit('pullWindowsSize');
    },
    handleHtmlFontSize() {
      // const winWidth = Math.min(
      //   Math.max(document.documentElement.clientWidth, 300),
      //   3000
      // ); // 限定宽度在300-3000之间
      const winWidth = document.documentElement.clientWidth;
      const winHeight = document.documentElement.clientHeight;
      let targetRate;
      if (winWidth > 1920) {
        const aspectVal = winWidth / winHeight;
        // 判断长宽比是否符合1920/1080
        if (aspectVal > 1920 / 1080) {
          targetRate = (winWidth / 1920) * 6.9;
        } else {
          targetRate = (winWidth / 1920) * 10;
        }
      } else if (winWidth > 768) {
        targetRate = (winWidth / 1920) * 10;
      } else {
        targetRate = (winWidth / 750) * 10;
      }
      document.documentElement.style.fontSize = targetRate + 'px';
    }
  }
};
</script>
