<template>
  <div class="solve_graph_vue">
    <div
      id="solveGraphChar"
      ref="solveGraphChar"
      class="solveGraphChar"
      v-if="showAnimate"
    >
      <svg
        width="666.460095px"
        height="1013.04px"
        viewBox="0 0 666.460095 1013.04"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        class="solveGraphCanvas"
      >
        <defs>
          <radialGradient
            cx="50%"
            cy="50%"
            fx="50%"
            fy="50%"
            r="49.6503497%"
            id="radialGradient-1"
          >
            <stop stop-color="#CCD3E0" offset="0%"></stop>
            <stop stop-color="#FFFFFF" stop-opacity="0.6" offset="100%"></stop>
          </radialGradient>
        </defs>
        <g
          id="页面-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g id="资源-1备份" transform="translate(0.3601, 0)">
            <g id="主编组-落地体验" transform="translate(50.3857, 453.25)">
              <g
                transform="translate(30.8743, 0)"
                id="路径"
                stroke="#B5B5B6"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="0.72"
              >
                <line
                  x1="16.22"
                  y1="92.24"
                  x2="123.02"
                  y2="210.88"
                  class="third_animation_line_5"
                ></line>
                <line
                  x1="43.16"
                  y1="136.4"
                  x2="123.02"
                  y2="210.88"
                  class="third_animation_line_5"
                ></line>
                <line
                  x1="0"
                  y1="170.9"
                  x2="123.02"
                  y2="210.88"
                  class="third_animation_line_5"
                ></line>
                <line
                  x1="33.44"
                  y1="190.19"
                  x2="123.02"
                  y2="210.88"
                  class="third_animation_line_5"
                ></line>
                <line
                  x1="35.1"
                  y1="237.75"
                  x2="123.02"
                  y2="210.88"
                  class="third_animation_line_5"
                ></line>
                <line
                  x1="3.01"
                  y1="259.36"
                  x2="123.02"
                  y2="210.88"
                  class="third_animation_line_5"
                ></line>
                <line
                  x1="51.05"
                  y1="288.05"
                  x2="123.02"
                  y2="210.88"
                  class="third_animation_line_5"
                ></line>
                <line
                  x1="24.69"
                  y1="336.72"
                  x2="123.02"
                  y2="210.88"
                  class="third_animation_line_5"
                ></line>
                <line
                  x1="30.53"
                  y1="428.73"
                  x2="123.02"
                  y2="210.88"
                  class="third_animation_line_5"
                ></line>
                <line
                  x1="123.02"
                  y1="210.88"
                  x2="15.6"
                  y2="-1.13686838e-13"
                  class="third_animation_line_5"
                ></line>
              </g>
              <path
                d="M79.864312,385.33 C15.004312,277 14.494312,147.45 67.874312,42"
                id="路径"
                stroke="#B5B5B6"
                stroke-width="0.72"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="radian_animation_line_5"
              ></path>
              <circle
                id="椭圆形"
                fill="#B5B5B6"
                fill-rule="nonzero"
                cx="79.444312"
                cy="193.7"
                r="18.78"
                class="third_animation_circle_5"
              ></circle>
              <circle
                id="椭圆形"
                fill="#B5B5B6"
                fill-rule="nonzero"
                cx="84.644312"
                cy="146.31"
                r="18.78"
                class="third_animation_circle_5"
              ></circle>
              <circle
                id="椭圆形"
                fill="#B5B5B6"
                fill-rule="nonzero"
                cx="97.294312"
                cy="99.73"
                r="18.78"
                class="third_animation_circle_5"
              ></circle>
              <circle
                id="椭圆形"
                fill="#B5B5B6"
                fill-rule="nonzero"
                cx="80.804312"
                cy="233.22"
                r="18.78"
                class="third_animation_circle_5"
              ></circle>
              <circle
                id="椭圆形"
                fill="#B5B5B6"
                fill-rule="nonzero"
                cx="89.234312"
                cy="280.22"
                r="18.78"
                class="third_animation_circle_5"
              ></circle>
              <circle
                id="椭圆形"
                fill="#B5B5B6"
                fill-rule="nonzero"
                cx="105.154312"
                cy="325.76"
                r="18.78"
                class="third_animation_circle_5"
              ></circle>
              <g
                id="_径向重复_"
                transform="translate(114.2506, 171.2971)"
                fill="#B5B5B6"
                fill-rule="nonzero"
              >
                <circle
                  id="椭圆形"
                  transform="translate(39.615, 39.615) rotate(-31.99) translate(-39.615, -39.615)"
                  cx="39.6149647"
                  cy="39.6149647"
                  r="28.75"
                  class="second_animation_circle_5"
                ></circle>
              </g>
              <g
                transform="translate(0, 81.0829)"
                fill="#595757"
                fill-rule="nonzero"
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="8.26"
                font-weight="normal"
                class="third_animation_circle_5"
              >
                <g
                  transform="translate(20.8432, 11.2725) rotate(-1) translate(-20.8432, -11.2725)translate(2.8432, 0.3125)"
                >
                  <text id="关注/搜索">
                    <tspan x="0" y="9">关注</tspan>
                    <tspan x="16.52" y="9" font-family="Helvetica">/</tspan>
                    <tspan x="18.8148926" y="9">搜索</tspan>
                  </text>
                  <text id="报名/邀约">
                    <tspan x="0" y="18.92">报名</tspan>
                    <tspan x="16.52" y="18.92" font-family="Helvetica">/</tspan>
                    <tspan x="18.8148926" y="18.92">邀约</tspan>
                  </text>
                </g>
                <g
                  transform="translate(9.0992, 90.723) rotate(-2.18) translate(-9.0992, -90.723)translate(0.5992, 74.808)"
                >
                  <text id="传播">
                    <tspan x="0" y="9">传播</tspan>
                  </text>
                  <text id="关注">
                    <tspan x="0" y="18.92">关注</tspan>
                  </text>
                  <text id="讨论">
                    <tspan x="0" y="28.83">讨论</tspan>
                  </text>
                </g>
                <g
                  transform="translate(14.3657, 180.1185) rotate(-0.96) translate(-14.3657, -180.1185)translate(5.8657, 164.2035)"
                >
                  <text id="打卡">
                    <tspan x="0" y="9">打卡</tspan>
                  </text>
                  <text id="参与">
                    <tspan x="0" y="18.92">参与</tspan>
                  </text>
                  <text id="分享">
                    <tspan x="0" y="28.83">分享</tspan>
                  </text>
                </g>
                <g
                  transform="translate(31.0652, 270.8686) rotate(-2.47) translate(-31.0652, -270.8686)translate(13.0652, 259.9086)"
                >
                  <text id="到地/到场">
                    <tspan x="0" y="9">到地</tspan>
                    <tspan x="16.52" y="9" font-family="Helvetica">/</tspan>
                    <tspan x="18.8148926" y="9">到场</tspan>
                  </text>
                  <text id="到店/签到">
                    <tspan x="0" y="18.92">到店</tspan>
                    <tspan x="16.52" y="18.92" font-family="Helvetica">/</tspan>
                    <tspan x="18.8148926" y="18.92">签到</tspan>
                  </text>
                </g>
              </g>
              <g
                transform="translate(106.2089, 325.8723) rotate(-1.13) translate(-106.2089, -325.8723)translate(96.2089, 313.5123)"
                fill="#FFFFFF"
                fill-rule="nonzero"
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="9.77"
                font-weight="normal"
                class="third_animation_circle_5"
              >
                <text id="数字">
                  <tspan x="0" y="10">数字</tspan>
                </text>
                <text id="沙盘">
                  <tspan x="0" y="21.72">沙盘</tspan>
                </text>
              </g>
              <g
                transform="translate(90.3221, 280.1068) rotate(-0.19) translate(-90.3221, -280.1068)translate(80.3221, 267.7468)"
                fill="#FFFFFF"
                fill-rule="nonzero"
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="9.77"
                font-weight="normal"
                class="third_animation_circle_5"
              >
                <text id="数字">
                  <tspan x="0" y="10">数字</tspan>
                </text>
                <text id="展厅">
                  <tspan x="0" y="21.72">展厅</tspan>
                </text>
              </g>
              <g
                transform="translate(80.8853, 233.0929) rotate(-0.27) translate(-80.8853, -233.0929)translate(70.8853, 220.7329)"
                fill="#FFFFFF"
                fill-rule="nonzero"
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="9.77"
                font-weight="normal"
                class="third_animation_circle_5"
              >
                <text id="体验">
                  <tspan x="0" y="10">体验</tspan>
                </text>
                <text id="活动">
                  <tspan x="0" y="21.72">活动</tspan>
                </text>
              </g>
              <g
                transform="translate(79.6969, 194.4233) rotate(0.65) translate(-79.6969, -194.4233)translate(69.6969, 182.0633)"
                fill="#FFFFFF"
                fill-rule="nonzero"
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="9.77"
                font-weight="normal"
                class="third_animation_circle_5"
              >
                <text id="上市">
                  <tspan x="0" y="10">上市</tspan>
                </text>
                <text id="发布">
                  <tspan x="0" y="21.72">发布</tspan>
                </text>
              </g>
              <g
                transform="translate(84.7855, 146.2736) rotate(-0.78) translate(-84.7855, -146.2736)translate(74.7855, 133.9136)"
                fill="#FFFFFF"
                fill-rule="nonzero"
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="9.77"
                font-weight="normal"
                class="third_animation_circle_5"
              >
                <text id="政企">
                  <tspan x="0" y="10">政企</tspan>
                </text>
                <text id="活动">
                  <tspan x="0" y="21.72">活动</tspan>
                </text>
              </g>
              <g
                transform="translate(97.4233, 99.6216) rotate(-0.22) translate(-97.4233, -99.6216)translate(87.4233, 87.2616)"
                fill="#FFFFFF"
                fill-rule="nonzero"
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="9.77"
                font-weight="normal"
                class="third_animation_circle_5"
              >
                <text id="展会">
                  <tspan x="0" y="10">展会</tspan>
                </text>
                <text id="运营">
                  <tspan x="0" y="21.72">运营</tspan>
                </text>
              </g>
              <g
                transform="translate(154.5307, 211.1714) rotate(-0.33) translate(-154.5307, -211.1714)translate(140.0307, 192.5464)"
                fill="#FFFFFF"
                fill-rule="nonzero"
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="14.38"
                font-weight="normal"
                class="second_animation_circle_5"
              >
                <text id="落地">
                  <tspan x="0" y="15">落地</tspan>
                </text>
                <text id="体验">
                  <tspan x="0" y="32.25">体验</tspan>
                </text>
              </g>
              <circle
                id="椭圆形"
                fill="#1D3462"
                fill-rule="nonzero"
                cx="47.094312"
                cy="92.24"
                r="3.59"
                class="third_animation_circle_5"
              ></circle>
              <circle
                id="椭圆形"
                fill="#1D3462"
                fill-rule="nonzero"
                cx="30.924312"
                cy="170.86"
                r="3.59"
                class="third_animation_circle_5"
              ></circle>
              <circle
                id="椭圆形"
                fill="#1D3462"
                fill-rule="nonzero"
                cx="33.884312"
                cy="259.36"
                r="3.59"
                class="third_animation_circle_5"
              ></circle>
              <circle
                id="椭圆形"
                fill="#1D3462"
                fill-rule="nonzero"
                cx="55.564312"
                cy="336.72"
                r="3.59"
                class="third_animation_circle_5"
              ></circle>
            </g>
            <g id="主编组-数字资产" transform="translate(253.19, 0)">
              <g>
                <g
                  transform="translate(0, 28.95)"
                  id="路径"
                  stroke="#B5B5B6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="0.72"
                >
                  <line
                    x1="123.89"
                    y1="38.19"
                    x2="155.07"
                    y2="123.85"
                    class="third_animation_line_3"
                  ></line>
                  <line
                    x1="71.07"
                    y1="14.11"
                    x2="155.07"
                    y2="123.85"
                    class="third_animation_line_3"
                  ></line>
                  <line
                    x1="0"
                    y1="15.26"
                    x2="155.07"
                    y2="123.85"
                    class="third_animation_line_3"
                  ></line>
                  <line
                    x1="155.07"
                    y1="123.85"
                    x2="310.41"
                    y2="15.08"
                    class="third_animation_line_3"
                  ></line>
                  <line
                    x1="155.07"
                    y1="123.85"
                    x2="239.38"
                    y2="14.22"
                    class="third_animation_line_3"
                  ></line>
                  <line
                    x1="155.07"
                    y1="123.85"
                    x2="186.97"
                    y2="36.21"
                    class="third_animation_line_3"
                  ></line>
                  <line
                    x1="155.07"
                    y1="122.52"
                    x2="155.07"
                    y2="0"
                    class="third_animation_line_3"
                  ></line>
                </g>
                <path
                  d="M27.11,63.2 C64.75,41.42 108.45,28.96 155.07,28.96 C201.69,28.96 245.39,41.43 283.03,63.2"
                  id="路径"
                  stroke="#B5B5B6"
                  stroke-width="0.72"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="radian_animation_line_3"
                ></path>
                <g
                  transform="translate(136.74, 0)"
                  fill="#595757"
                  fill-rule="nonzero"
                  font-family="PingFangSC-Regular, PingFang SC"
                  font-size="8.26"
                  font-weight="normal"
                  class="third_animation_circle_3"
                >
                  <text id="互动/搜索">
                    <tspan x="0" y="9">互动</tspan>
                    <tspan x="16.52" y="9" font-family="Helvetica">/</tspan>
                    <tspan x="18.8148926" y="9">搜索</tspan>
                  </text>
                  <text id="购买/口碑">
                    <tspan x="0" y="18.92">购买</tspan>
                    <tspan x="16.52" y="18.92" font-family="Helvetica">/</tspan>
                    <tspan x="18.8148926" y="18.92">口碑</tspan>
                  </text>
                </g>
                <g
                  transform="translate(42.09, 11.07)"
                  fill="#595757"
                  fill-rule="nonzero"
                  font-family="PingFangSC-Regular, PingFang SC"
                  font-size="8.26"
                  font-weight="normal"
                  class="third_animation_circle_3"
                >
                  <text id="互动/评论">
                    <tspan x="10.2" y="9">互动</tspan>
                    <tspan x="26.72" y="9" font-family="Helvetica">/</tspan>
                    <tspan x="29.0148926" y="9">评论</tspan>
                  </text>
                  <text id="新增/点赞/分享">
                    <tspan x="0" y="18.92">新增</tspan>
                    <tspan x="16.52" y="18.92" font-family="Helvetica">/</tspan>
                    <tspan x="18.8148926" y="18.92">点赞</tspan>
                    <tspan x="35.3348926" y="18.92" font-family="Helvetica">
                      /
                    </tspan>
                    <tspan x="37.6297852" y="18.92">分享</tspan>
                  </text>
                </g>
                <g
                  transform="translate(230.85, 4.99)"
                  fill="#595757"
                  fill-rule="nonzero"
                  font-family="Helvetica"
                  font-size="8.26"
                  font-weight="normal"
                  class="third_animation_circle_3"
                >
                  <text id="A">
                    <tspan x="0" y="8">A</tspan>
                  </text>
                  <text id="O-A5">
                    <tspan x="5.35" y="8">O-A5</tspan>
                  </text>
                  <text id="A1-A3">
                    <tspan x="0.61" y="17.92">A1-A3</tspan>
                  </text>
                  <text id="A3-A5">
                    <tspan x="0.61" y="27.83">A3-A5</tspan>
                  </text>
                </g>
                <g
                  id="_径向重复_-2"
                  transform="translate(54.64, 75.99)"
                  fill="#B5B5B6"
                  fill-rule="nonzero"
                >
                  <circle
                    id="椭圆形"
                    cx="18.78"
                    cy="18.78"
                    r="18.78"
                    class="third_animation_circle_3"
                  ></circle>
                </g>
                <g
                  id="_径向重复_-3"
                  transform="translate(109.34, 61.32)"
                  fill="#B5B5B6"
                  fill-rule="nonzero"
                >
                  <circle
                    id="椭圆形"
                    cx="18.78"
                    cy="18.78"
                    r="18.78"
                    class="third_animation_circle_3"
                  ></circle>
                </g>
                <g
                  id="_径向重复_-4"
                  transform="translate(163.24, 61.32)"
                  fill="#B5B5B6"
                  fill-rule="nonzero"
                >
                  <circle
                    id="椭圆形"
                    cx="18.78"
                    cy="18.78"
                    r="18.78"
                    class="third_animation_circle_3"
                  ></circle>
                </g>
                <g
                  id="_径向重复_-5"
                  transform="translate(217.94, 76.41)"
                  fill="#B5B5B6"
                  fill-rule="nonzero"
                >
                  <circle
                    id="椭圆形"
                    cx="18.78"
                    cy="18.78"
                    r="18.78"
                    class="third_animation_circle_3"
                  ></circle>
                </g>
                <g
                  id="_径向重复_-6"
                  transform="translate(126.32, 124.05)"
                  fill="#B5B5B6"
                  fill-rule="nonzero"
                >
                  <circle
                    id="椭圆形"
                    cx="28.75"
                    cy="28.75"
                    r="28.75"
                    class="second_animation_circle_3"
                  ></circle>
                </g>
                <g
                  transform="translate(140.93, 135.04)"
                  fill="#FFFFFF"
                  fill-rule="nonzero"
                  font-family="PingFangSC-Regular, PingFang SC"
                  font-size="14.38"
                  font-weight="normal"
                  class="second_animation_circle_3"
                >
                  <text id="数字">
                    <tspan x="0" y="15">数字</tspan>
                  </text>
                  <text id="资产">
                    <tspan x="0" y="32.25">资产</tspan>
                  </text>
                </g>
                <g
                  transform="translate(63.81, 81.65)"
                  fill="#FFFFFF"
                  fill-rule="nonzero"
                  font-family="PingFangSC-Regular, PingFang SC"
                  font-size="9.77"
                  font-weight="normal"
                  class="second_animation_circle_3"
                >
                  <text id="数字">
                    <tspan x="0" y="10">数字</tspan>
                  </text>
                  <text id="资产">
                    <tspan x="0" y="21.72">资产</tspan>
                  </text>
                </g>
                <g
                  transform="translate(114.83, 73.05)"
                  fill="#FFFFFF"
                  fill-rule="nonzero"
                  font-family="PingFangSC-Regular, PingFang SC"
                  font-size="9.77"
                  font-weight="normal"
                  id="数字人"
                  class="third_animation_circle_3"
                >
                  <text>
                    <tspan x="0" y="10">数字人</tspan>
                  </text>
                </g>
                <g
                  transform="translate(172.41, 65.98)"
                  fill="#FFFFFF"
                  fill-rule="nonzero"
                  font-size="9.77"
                  font-weight="normal"
                  class="third_animation_circle_3"
                >
                  <text id="XR" font-family="Helvetica">
                    <tspan x="3.25" y="10">XR</tspan>
                  </text>
                  <text id="内容" font-family="PingFangSC-Regular, PingFang SC">
                    <tspan x="0" y="21.72">内容</tspan>
                  </text>
                </g>
                <g
                  transform="translate(227.65, 80.2)"
                  fill="#FFFFFF"
                  fill-rule="nonzero"
                  font-size="9.77"
                  font-weight="normal"
                  class="third_animation_circle_3"
                >
                  <text id="XR" font-family="Helvetica">
                    <tspan x="3.25" y="10">XR</tspan>
                  </text>
                  <text id="直播" font-family="PingFangSC-Regular, PingFang SC">
                    <tspan x="0" y="21.72">直播</tspan>
                  </text>
                </g>
                <circle
                  id="椭圆形"
                  fill="#1D3462"
                  fill-rule="nonzero"
                  cx="155.07"
                  cy="28.95"
                  r="3.59"
                  class="third_animation_circle_3"
                ></circle>
                <circle
                  id="椭圆形"
                  fill="#1D3462"
                  fill-rule="nonzero"
                  cx="71.07"
                  cy="43.06"
                  r="3.59"
                  class="third_animation_circle_3"
                ></circle>
              </g>
              <circle
                id="椭圆形"
                fill="#1D3462"
                fill-rule="nonzero"
                cx="239.38"
                cy="43.17"
                r="3.59"
                class="third_animation_circle_3"
              ></circle>
            </g>
            <line
              x1="347.5"
              y1="473.326344"
              x2="522.61"
              y2="444.76"
              id="路径"
              stroke="#B5B5B6"
              stroke-width="0.72"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="second_animation_line_2"
            ></line>
            <g id="主编组-新媒体" transform="translate(479.57, 279.7981)">
              <line
                x1="66.07"
                y1="154.371893"
                x2="172.79"
                y2="45.2818929"
                id="路径"
                stroke="#B5B5B6"
                stroke-width="0.72"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="third_animation_line_3"
              ></line>
              <line
                x1="66.07"
                y1="154.371893"
                x2="123.57"
                y2="41.9418929"
                id="路径"
                stroke="#B5B5B6"
                stroke-width="0.72"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="third_animation_line_3"
              ></line>
              <line
                x1="66.07"
                y1="154.371893"
                x2="48.66"
                y2="29.3818929"
                id="路径"
                stroke="#B5B5B6"
                stroke-width="0.72"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="third_animation_line_3"
              ></line>
              <line
                x1="66.07"
                y1="154.371893"
                x2="-5.68434189e-14"
                y2="16.6418929"
                id="路径"
                stroke="#B5B5B6"
                stroke-width="0.72"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="third_animation_line_3"
              ></line>
              <path
                d="M8.23,34.1418929 C58.89,23.0618929 113.29,31.0918929 159.68,59.3818929"
                id="路径"
                stroke="#B5B5B6"
                stroke-width="0.72"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="radian_animation_line_3"
              ></path>
              <g
                transform="translate(46.8307, 11.0259) rotate(-0.21) translate(-46.8307, -11.0259)translate(28.8307, 0.0659)"
                fill="#595757"
                fill-rule="nonzero"
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="8.26"
                font-weight="normal"
                class="third_animation_circle_3"
              >
                <text id="互动/搜索">
                  <tspan x="0" y="9">互动</tspan>
                  <tspan x="16.52" y="9" font-family="Helvetica">/</tspan>
                  <tspan x="18.8148926" y="9">搜索</tspan>
                </text>
                <text id="购买/口碑">
                  <tspan x="0" y="18.92">购买</tspan>
                  <tspan x="16.52" y="18.92" font-family="Helvetica">/</tspan>
                  <tspan x="18.8148926" y="18.92">口碑</tspan>
                </text>
              </g>
              <g
                transform="translate(131.7788, 19.7995) rotate(1.12) translate(-131.7788, -19.7995)translate(104.2788, 8.8395)"
                fill="#595757"
                fill-rule="nonzero"
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="8.26"
                font-weight="normal"
                class="third_animation_circle_3"
              >
                <text id="互动/评论">
                  <tspan x="10.2" y="9">互动</tspan>
                  <tspan x="26.72" y="9" font-family="Helvetica">/</tspan>
                  <tspan x="29.0148926" y="9">评论</tspan>
                </text>
                <text id="新增/点赞/分享">
                  <tspan x="0" y="18.92">新增</tspan>
                  <tspan x="16.52" y="18.92" font-family="Helvetica">/</tspan>
                  <tspan x="18.8148926" y="18.92">点赞</tspan>
                  <tspan x="35.3348926" y="18.92" font-family="Helvetica">
                    /
                  </tspan>
                  <tspan x="37.6297852" y="18.92">分享</tspan>
                </text>
              </g>
              <g
                id="_径向重复_-7"
                transform="translate(51.9842, 54.9893)"
                fill="#B5B5B6"
                fill-rule="nonzero"
              >
                <circle
                  id="椭圆形"
                  transform="translate(26.4308, 26.4308) rotate(-50.63) translate(-26.4308, -26.4308)"
                  cx="26.4308149"
                  cy="26.4308149"
                  r="18.78"
                  class="third_animation_circle_3"
                ></circle>
              </g>
              <g
                id="_径向重复_-8"
                transform="translate(4.2756, 53.9072)"
                fill="#B5B5B6"
                fill-rule="nonzero"
              >
                <circle
                  id="椭圆形"
                  transform="translate(26.4308, 26.4308) rotate(-50.63) translate(-26.4308, -26.4308)"
                  cx="26.4308149"
                  cy="26.4308149"
                  r="18.78"
                  class="third_animation_circle_3"
                ></circle>
              </g>
              <g
                id="_径向重复_-9"
                transform="translate(96.9602, 69.3181)"
                fill="#B5B5B6"
                fill-rule="nonzero"
              >
                <circle
                  id="椭圆形"
                  transform="translate(26.4308, 26.4308) rotate(-50.63) translate(-26.4308, -26.4308)"
                  cx="26.4308149"
                  cy="26.4308149"
                  r="18.78"
                  class="third_animation_circle_3"
                ></circle>
              </g>
              <g
                id="_径向重复_-10"
                transform="translate(37.32, 125.6219)"
                fill="#B5B5B6"
                fill-rule="nonzero"
              >
                <circle
                  id="椭圆形"
                  cx="28.75"
                  cy="28.75"
                  r="28.75"
                  class="second_animation_circle_3"
                ></circle>
              </g>
              <g
                transform="translate(67.3537, 154.4835) rotate(-1.17) translate(-67.3537, -154.4835)translate(45.3537, 144.4835)"
                fill="#FFFFFF"
                fill-rule="nonzero"
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="14.38"
                font-weight="normal"
                id="新媒体"
                class="second_animation_circle_3"
              >
                <text>
                  <tspan x="0" y="15">新媒体</tspan>
                </text>
              </g>
              <g
                transform="translate(30.5973, 80.4546) rotate(-1.47) translate(-30.5973, -80.4546)translate(20.5973, 68.0946)"
                fill="#FFFFFF"
                fill-rule="nonzero"
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="9.77"
                font-weight="normal"
                class="third_animation_circle_3"
              >
                <text id="直播">
                  <tspan x="0" y="10">直播</tspan>
                </text>
                <text id="运营">
                  <tspan x="0" y="21.72">运营</tspan>
                </text>
              </g>
              <g
                transform="translate(79.0188, 81.099) rotate(0.27) translate(-79.0188, -81.099)translate(69.0188, 68.739)"
                fill="#FFFFFF"
                fill-rule="nonzero"
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="9.77"
                font-weight="normal"
                class="third_animation_circle_3"
              >
                <text id="内容">
                  <tspan x="0" y="10">内容</tspan>
                </text>
                <text id="运营">
                  <tspan x="0" y="21.72">运营</tspan>
                </text>
              </g>
              <g
                transform="translate(123.9415, 95.0186) rotate(1.13) translate(-123.9415, -95.0186)translate(113.9415, 82.6586)"
                fill="#FFFFFF"
                fill-rule="nonzero"
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="9.77"
                font-weight="normal"
                class="third_animation_circle_3"
              >
                <text id="社交">
                  <tspan x="0" y="10">社交</tspan>
                </text>
                <text id="电商">
                  <tspan x="0" y="21.72">电商</tspan>
                </text>
              </g>
              <circle
                id="椭圆形"
                fill="#1D3462"
                fill-rule="nonzero"
                cx="48.66"
                cy="29.3818929"
                r="3.59"
                class="third_animation_circle_3"
              ></circle>
              <circle
                id="椭圆形"
                fill="#1D3462"
                fill-rule="nonzero"
                cx="123.57"
                cy="41.9418929"
                r="3.59"
                class="third_animation_circle_3"
              ></circle>
            </g>
            <g id="主编组-大众传播" transform="translate(454.99, 572.403)">
              <circle
                id="椭圆形"
                fill="#1D3462"
                fill-rule="nonzero"
                cx="50.8"
                cy="48.8369584"
                r="3.59"
                class="third_animation_circle_1"
              ></circle>
              <g>
                <g
                  transform="translate(18.42, 2.447)"
                  id="路径"
                  stroke="#B5B5B6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="0.72"
                  class="third_animation_line_1"
                >
                  <line x1="10.33" y1="182.93" x2="192.33" y2="204.53"></line>
                  <line x1="10.33" y1="182.93" x2="147.81" y2="169.06"></line>
                  <line x1="10.33" y1="182.93" x2="96.17" y2="146.08"></line>
                  <line x1="10.33" y1="182.93" x2="118.09" y2="118.8"></line>
                  <line x1="10.33" y1="182.93" x2="81.02" y2="79.3"></line>
                  <line x1="10.33" y1="182.93" x2="52.4" y2="99.54"></line>
                  <line x1="10.33" y1="182.93" x2="32.38" y2="46.39"></line>
                  <line
                    x1="10.33"
                    y1="182.93"
                    x2="5.68434189e-14"
                    y2="-1.13686838e-13"
                  ></line>
                </g>
                <path
                  d="M20.29,35.4269584 C98.76,63.9769584 155.5,127.676958 177.92,203.076958"
                  id="路径"
                  stroke="#B5B5B6"
                  stroke-width="0.72"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="radian_animation_line_1"
                ></path>
                <g
                  transform="translate(42.3394, -0)"
                  fill="#595757"
                  fill-rule="nonzero"
                  font-size="8.26"
                  font-weight="normal"
                  class="third_animation_circle_1"
                >
                  <g
                    transform="translate(17.5691, 21.3358) rotate(1.58) translate(-17.5691, -21.3358)translate(0.5691, 0.4608)"
                    font-family="PingFangSC-Regular, PingFang SC"
                  >
                    <text id="参与事件">
                      <tspan x="0" y="9">参与事件</tspan>
                    </text>
                    <text id="搜索新闻">
                      <tspan x="0" y="18.92">搜索新闻</tspan>
                    </text>
                    <text id="转发评论">
                      <tspan x="0" y="28.83">转发评论</tspan>
                    </text>
                    <text id="引用传播">
                      <tspan x="0" y="38.75">引用传播</tspan>
                    </text>
                  </g>
                  <g
                    transform="translate(83.7763, 59.6713) rotate(0.77) translate(-83.7763, -59.6713)translate(63.2763, 38.7963)"
                    font-family="PingFangSC-Regular, PingFang SC"
                  >
                    <text id="点赞-IP">
                      <tspan x="7.07" y="9">点赞</tspan>
                      <tspan x="23.59" y="9" font-family="Helvetica">IP</tspan>
                    </text>
                    <text id="讨论IP">
                      <tspan x="8.13" y="18.92">讨论</tspan>
                      <tspan x="24.65" y="18.92" font-family="Helvetica">
                        IP
                      </tspan>
                    </text>
                    <text id="搜索IP">
                      <tspan x="8.13" y="28.83">搜索</tspan>
                      <tspan x="24.65" y="28.83" font-family="Helvetica">
                        IP
                      </tspan>
                    </text>
                    <text id="买IP衍生品">
                      <tspan x="0" y="38.75">买</tspan>
                      <tspan x="8.26" y="38.75" font-family="Helvetica">
                        IP
                      </tspan>
                      <tspan x="16.064248" y="38.75">衍生品</tspan>
                    </text>
                  </g>
                  <g
                    transform="translate(124.5185, 110.7422) rotate(0.41) translate(-124.5185, -110.7422)translate(99.5635, 94.8272)"
                  >
                    <text id="K" font-family="Helvetica">
                      <tspan x="8.13" y="8">K</tspan>
                    </text>
                    <text id="OL原粉" font-family="Helvetica">
                      <tspan x="13.04" y="8">OL</tspan>
                      <tspan
                        x="24.0587109"
                        y="8"
                        font-family="PingFangSC-Regular, PingFang SC"
                      >
                        原粉
                      </tspan>
                    </text>
                    <text id="K" font-family="Helvetica">
                      <tspan x="-1.77635684e-15" y="17.92">K</tspan>
                    </text>
                    <text id="OL活动参与" font-family="Helvetica">
                      <tspan x="4.91" y="17.92">OL</tspan>
                      <tspan
                        x="15.9287109"
                        y="17.92"
                        font-family="PingFangSC-Regular, PingFang SC"
                      >
                        活动参与
                      </tspan>
                    </text>
                    <text
                      id="转发/咨询"
                      font-family="PingFangSC-Regular, PingFang SC"
                    >
                      <tspan x="5.55" y="28.83">转发</tspan>
                      <tspan x="22.07" y="28.83" font-family="Helvetica">
                        /
                      </tspan>
                      <tspan x="24.3648926" y="28.83">咨询</tspan>
                    </text>
                  </g>
                  <g
                    transform="translate(150.9287, 172.6399) rotate(2.23) translate(-150.9287, -172.6399)translate(133.9287, 161.6799)"
                    font-family="PingFangSC-Regular, PingFang SC"
                  >
                    <text id="点击">
                      <tspan x="8.13" y="9">点击</tspan>
                    </text>
                    <text id="互动转化">
                      <tspan x="0" y="18.92">互动转化</tspan>
                    </text>
                  </g>
                </g>
                <g
                  id="_径向重复_-11"
                  transform="translate(0.0687, 67.1762)"
                  fill="#B5B5B6"
                  fill-rule="nonzero"
                >
                  <circle
                    id="椭圆形"
                    transform="translate(23.3999, 23.3999) rotate(-73.23) translate(-23.3999, -23.3999)"
                    cx="23.3999027"
                    cy="23.3999027"
                    r="18.78"
                    class="third_animation_circle_1"
                  ></circle>
                </g>
                <g
                  id="_径向重复_-12"
                  transform="translate(99.6748, 173.2594)"
                  fill="#B5B5B6"
                  fill-rule="nonzero"
                >
                  <circle
                    id="椭圆形"
                    transform="translate(23.3999, 23.3999) rotate(-73.23) translate(-23.3999, -23.3999)"
                    cx="23.3999027"
                    cy="23.3999027"
                    r="18.78"
                    class="third_animation_circle_1"
                  ></circle>
                </g>
                <g
                  id="_径向重复_-13"
                  transform="translate(76.3174, 131.57)"
                  fill="#B5B5B6"
                  fill-rule="nonzero"
                >
                  <circle
                    id="椭圆形"
                    transform="translate(23.3999, 23.3999) rotate(-73.23) translate(-23.3999, -23.3999)"
                    cx="23.3999027"
                    cy="23.3999027"
                    r="18.78"
                    class="third_animation_circle_1"
                  ></circle>
                </g>
                <g
                  id="_径向重复_-14"
                  transform="translate(40.1582, 92.9781)"
                  fill="#B5B5B6"
                  fill-rule="nonzero"
                >
                  <circle
                    id="椭圆形"
                    transform="translate(23.3999, 23.3999) rotate(-73.23) translate(-23.3999, -23.3999)"
                    cx="23.3999027"
                    cy="23.3999027"
                    r="18.78"
                    class="third_animation_circle_1"
                  ></circle>
                </g>
                <g
                  id="_径向重复_-15"
                  transform="translate(0, 156.627)"
                  fill="#B5B5B6"
                  fill-rule="nonzero"
                >
                  <circle
                    id="椭圆形"
                    cx="28.75"
                    cy="28.75"
                    r="28.75"
                    class="second_animation_circle_1"
                  ></circle>
                </g>
                <g
                  transform="translate(23.8371, 89.7592) rotate(1.45) translate(-23.8371, -89.7592)translate(13.8371, 77.3992)"
                  fill="#FFFFFF"
                  fill-rule="nonzero"
                  font-family="PingFangSC-Regular, PingFang SC"
                  font-size="9.77"
                  font-weight="normal"
                  class="third_animation_circle_1"
                >
                  <text id="公关">
                    <tspan x="0" y="10">公关</tspan>
                  </text>
                  <text id="传播">
                    <tspan x="0" y="21.72">传播</tspan>
                  </text>
                </g>
                <g
                  transform="translate(64.4345, 115.551) rotate(0.49) translate(-64.4345, -115.551)translate(54.2695, 103.191)"
                  fill="#FFFFFF"
                  fill-rule="nonzero"
                  font-size="9.77"
                  font-weight="normal"
                  class="third_animation_circle_1"
                >
                  <text id="IP大" font-family="Helvetica">
                    <tspan x="0.33" y="10">IP</tspan>
                    <tspan
                      x="9.56093262"
                      y="10"
                      font-family="PingFangSC-Regular, PingFang SC"
                    >
                      大
                    </tspan>
                  </text>
                  <text id="事件" font-family="PingFangSC-Regular, PingFang SC">
                    <tspan x="5.55111512e-17" y="21.72">事件</tspan>
                  </text>
                </g>
                <g
                  transform="translate(99.643, 155.6267) rotate(1.32) translate(-99.643, -155.6267)translate(89.643, 143.2667)"
                  fill="#FFFFFF"
                  fill-rule="nonzero"
                  font-family="PingFangSC-Regular, PingFang SC"
                  font-size="9.77"
                  font-weight="normal"
                  class="third_animation_circle_1"
                >
                  <text id="媒介">
                    <tspan x="0" y="10">媒介</tspan>
                  </text>
                  <text id="策略">
                    <tspan x="0" y="21.72">策略</tspan>
                  </text>
                </g>
                <g
                  transform="translate(124.3738, 197.1819) rotate(16.77) translate(-124.3738, -197.1819)translate(114.3738, 184.8219)"
                  fill="#FFFFFF"
                  fill-rule="nonzero"
                  font-family="PingFangSC-Regular, PingFang SC"
                  font-size="9.77"
                  font-weight="normal"
                  class="third_animation_circle_1"
                >
                  <text id="媒介">
                    <tspan x="0" y="10">媒介</tspan>
                  </text>
                  <text id="采买">
                    <tspan x="0" y="21.72">采买</tspan>
                  </text>
                </g>
                <g
                  transform="translate(28.8581, 186.9416) rotate(0.87) translate(-28.8581, -186.9416)translate(14.3581, 168.3166)"
                  fill="#FFFFFF"
                  fill-rule="nonzero"
                  font-family="PingFangSC-Regular, PingFang SC"
                  font-size="14.38"
                  font-weight="normal"
                  class="third_animation_circle_1"
                >
                  <text id="大众">
                    <tspan x="0" y="15">大众</tspan>
                  </text>
                  <text id="传播">
                    <tspan x="0" y="32.25">传播</tspan>
                  </text>
                </g>
                <circle
                  id="椭圆形"
                  fill="#1D3462"
                  fill-rule="nonzero"
                  cx="99.44"
                  cy="81.7469584"
                  r="3.59"
                  class="third_animation_circle_1"
                ></circle>
                <circle
                  id="椭圆形"
                  fill="#1D3462"
                  fill-rule="nonzero"
                  cx="136.51"
                  cy="121.246958"
                  r="3.59"
                  class="third_animation_circle_1"
                ></circle>
                <circle
                  id="椭圆形"
                  fill="#1D3462"
                  fill-rule="nonzero"
                  cx="166.23"
                  cy="171.506958"
                  r="3.59"
                  class="third_animation_circle_1"
                ></circle>
              </g>
            </g>
            <g id="品牌焕新" transform="translate(260.88, 840.3)">
              <g
                transform="translate(0, 28.75)"
                id="路径"
                stroke="#B5B5B6"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="0.72"
                class="third_animation_line_6"
              >
                <line
                  x1="87.48"
                  y1="1.13686838e-13"
                  x2="87.48"
                  y2="123.66"
                ></line>
                <line
                  x1="87.48"
                  y1="1.13686838e-13"
                  x2="135.75"
                  y2="116.6"
                ></line>
                <line
                  x1="87.48"
                  y1="1.13686838e-13"
                  x2="174.97"
                  y2="124.94"
                ></line>
                <line x1="0" y1="124.94" x2="87.48" y2="1.13686838e-13"></line>
                <line
                  x1="39.27"
                  y1="116.6"
                  x2="87.48"
                  y2="1.13686838e-13"
                ></line>
              </g>
              <path
                d="M163.89,137.81 C140.26,147.24 114.48,152.42 87.49,152.42 C60.5,152.42 34.72,147.24 11.09,137.81"
                id="路径"
                stroke="#B5B5B6"
                stroke-width="0.72"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="radian_animation_line_6"
              ></path>
              <circle
                id="椭圆形"
                fill="#B5B5B6"
                fill-rule="nonzero"
                cx="134.45"
                cy="95.76"
                r="18.78"
                class="third_animation_circle_6"
              ></circle>
              <circle
                id="椭圆形"
                fill="#B5B5B6"
                fill-rule="nonzero"
                cx="40.56"
                cy="95.76"
                r="18.78"
                class="third_animation_circle_6"
              ></circle>
              <circle
                id="椭圆形"
                fill="#B5B5B6"
                fill-rule="nonzero"
                cx="87.48"
                cy="102.9"
                r="18.78"
                class="third_animation_circle_6"
              ></circle>
              <g
                id="_径向重复_-16"
                transform="translate(58.73, -0)"
                fill="#B5B5B6"
                fill-rule="nonzero"
                class="second_animation_circle_6"
              >
                <circle id="椭圆形" cx="28.75" cy="28.75" r="28.75"></circle>
              </g>
              <g
                transform="translate(133.48, 155.39)"
                fill="#595757"
                fill-rule="nonzero"
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="8.26"
                font-weight="normal"
                id="推荐"
                class="third_animation_circle_6"
              >
                <text>
                  <tspan x="0" y="9">推荐</tspan>
                </text>
              </g>
              <g
                transform="translate(80.28, 160.74)"
                fill="#595757"
                fill-rule="nonzero"
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="8.26"
                font-weight="normal"
                id="好感"
                class="third_animation_circle_6"
              >
                <text>
                  <tspan x="0" y="9">好感</tspan>
                </text>
              </g>
              <g
                transform="translate(28.35, 155.39)"
                fill="#595757"
                fill-rule="nonzero"
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="8.26"
                font-weight="normal"
                id="认知"
                class="third_animation_circle_6"
              >
                <text>
                  <tspan x="0" y="9">认知</tspan>
                </text>
              </g>
              <g
                transform="translate(125.53, 84.09)"
                fill="#FFFFFF"
                fill-rule="nonzero"
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="9.77"
                font-weight="normal"
                class="third_animation_circle_6"
              >
                <text id="新品">
                  <tspan x="0" y="10">新品</tspan>
                </text>
                <text id="营销">
                  <tspan x="0" y="21.72">营销</tspan>
                </text>
              </g>
              <g
                transform="translate(77.87, 90.96)"
                fill="#FFFFFF"
                fill-rule="nonzero"
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="9.77"
                font-weight="normal"
                class="third_animation_circle_6"
              >
                <text id="品牌">
                  <tspan x="0" y="10">品牌</tspan>
                </text>
                <text id="设计">
                  <tspan x="0" y="21.72">设计</tspan>
                </text>
              </g>
              <g
                transform="translate(30.95, 84.09)"
                fill="#FFFFFF"
                fill-rule="nonzero"
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="9.77"
                font-weight="normal"
                class="third_animation_circle_6"
              >
                <text id="品牌">
                  <tspan x="0" y="10">品牌</tspan>
                </text>
                <text id="策略">
                  <tspan x="0" y="21.72">策略</tspan>
                </text>
              </g>
              <g
                transform="translate(73.34, 11.86)"
                fill="#FFFFFF"
                fill-rule="nonzero"
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="14.38"
                font-weight="normal"
                class="second_animation_circle_6"
              >
                <text id="品牌">
                  <tspan x="0" y="15">品牌</tspan>
                </text>
                <text id="焕新">
                  <tspan x="0" y="32.25">焕新</tspan>
                </text>
              </g>
              <circle
                id="椭圆形"
                fill="#1D3462"
                fill-rule="nonzero"
                cx="136.28"
                cy="146.62"
                r="3.59"
                class="third_animation_circle_6"
              ></circle>
              <circle
                id="椭圆形"
                fill="#1D3462"
                fill-rule="nonzero"
                cx="87.48"
                cy="152.42"
                r="3.59"
                class="third_animation_circle_6"
              ></circle>
              <circle
                id="椭圆形"
                fill="#1D3462"
                fill-rule="nonzero"
                cx="38.77"
                cy="146.62"
                r="3.59"
                class="third_animation_circle_6"
              ></circle>
            </g>
            <g id="主编组-用户运营" transform="translate(0, 150.85)">
              <g
                id="路径"
                stroke="#B5B5B6"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="0.72"
                class="third_animation_circle_4"
              >
                <line x1="243.12" y1="0" x2="180.46" y2="172.16"></line>
                <line x1="192.34" y1="36.38" x2="180.46" y2="172.16"></line>
                <line x1="169.45" y1="109.7" x2="180.46" y2="172.16"></line>
                <line x1="130.17" y1="129.96" x2="180.46" y2="172.16"></line>
                <line x1="56.99" y1="114.58" x2="180.46" y2="172.16"></line>
                <line
                  x1="5.55111512e-16"
                  y1="140.34"
                  x2="180.46"
                  y2="172.16"
                ></line>
              </g>
              <line
                x1="118.53"
                y1="64.88"
                x2="170.8"
                y2="155.42"
                id="路径"
                stroke="#B5B5B6"
                stroke-width="0.72"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="third_animation_line_4"
              ></line>
              <path
                d="M32.54,146.07 C75.81,80.71 148.74,35.74 231.82,31.05"
                id="路径"
                stroke="#B5B5B6"
                stroke-width="0.72"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="radian_animation_line_4"
              ></path>
              <circle
                id="椭圆形"
                fill="#B5B5B6"
                fill-rule="nonzero"
                cx="121.32"
                cy="122.48"
                r="18.78"
                class="third_animation_circle_4"
              ></circle>
              <circle
                id="椭圆形"
                fill="#B5B5B6"
                fill-rule="nonzero"
                cx="166.99"
                cy="96.11"
                r="18.78"
                class="third_animation_circle_4"
              ></circle>
              <circle
                id="椭圆形"
                fill="#B5B5B6"
                fill-rule="nonzero"
                cx="212.9"
                cy="82.88"
                r="18.78"
                class="third_animation_circle_4"
              ></circle>
              <circle
                id="椭圆形"
                fill="#B5B5B6"
                fill-rule="nonzero"
                cx="86.93"
                cy="155.74"
                r="18.78"
                class="third_animation_circle_4"
              ></circle>
              <g
                id="_径向重复_-17"
                transform="translate(151.71, 143.41)"
                fill="#B5B5B6"
                fill-rule="nonzero"
              >
                <circle
                  id="椭圆形"
                  cx="28.75"
                  cy="28.75"
                  r="28.75"
                  class="second_animation_circle_4"
                ></circle>
              </g>
              <g
                transform="translate(173.75, 1.27)"
                fill="#595757"
                fill-rule="nonzero"
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="8.26"
                font-weight="normal"
                class="third_animation_circle_4"
              >
                <text id="下载">
                  <tspan x="0" y="9">下载</tspan>
                </text>
                <text id="添加">
                  <tspan x="0" y="18.92">添加</tspan>
                </text>
                <text id="关注">
                  <tspan x="0" y="28.83">关注</tspan>
                </text>
              </g>
              <g
                transform="translate(100.04, 28.38)"
                fill="#595757"
                fill-rule="nonzero"
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="8.26"
                font-weight="normal"
                class="third_animation_circle_4"
              >
                <text id="报名">
                  <tspan x="0" y="9">报名</tspan>
                </text>
                <text id="分享">
                  <tspan x="0" y="18.92">分享</tspan>
                </text>
                <text id="邀请">
                  <tspan x="0" y="28.83">邀请</tspan>
                </text>
              </g>
              <g
                transform="translate(33.16, 83.38)"
                fill="#595757"
                fill-rule="nonzero"
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="8.26"
                font-weight="normal"
                class="third_animation_circle_4"
              >
                <text id="共创">
                  <tspan x="0" y="9">共创</tspan>
                </text>
                <text id="投票">
                  <tspan x="0" y="18.92">投票</tspan>
                </text>
                <text id="裂变">
                  <tspan x="0" y="28.83">裂变</tspan>
                </text>
              </g>
              <g
                transform="translate(75.91, 142.52)"
                fill="#FFFFFF"
                fill-rule="nonzero"
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="9.77"
                font-weight="normal"
                class="third_animation_circle_4"
              >
                <text id="社群">
                  <tspan x="0" y="10">社群</tspan>
                </text>
                <text id="运营">
                  <tspan x="0" y="21.72">运营</tspan>
                </text>
              </g>
              <g
                transform="translate(109.46, 109.62)"
                fill="#FFFFFF"
                fill-rule="nonzero"
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="9.77"
                font-weight="normal"
                class="third_animation_circle_4"
              >
                <text id="实时">
                  <tspan x="0" y="10">实时</tspan>
                </text>
                <text id="客服">
                  <tspan x="0" y="21.72">客服</tspan>
                </text>
              </g>
              <g
                transform="translate(157.15, 84.29)"
                fill="#FFFFFF"
                fill-rule="nonzero"
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="9.77"
                font-weight="normal"
                class="third_animation_circle_4"
              >
                <text id="活动">
                  <tspan x="0" y="10">活动</tspan>
                </text>
                <text id="运营">
                  <tspan x="0" y="21.72">运营</tspan>
                </text>
              </g>
              <g
                transform="translate(201.76, 69.66)"
                fill="#FFFFFF"
                fill-rule="nonzero"
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="9.77"
                font-weight="normal"
                class="third_animation_circle_4"
              >
                <text id="社区">
                  <tspan x="0" y="10">社区</tspan>
                </text>
                <text id="运营">
                  <tspan x="0" y="21.72">运营</tspan>
                </text>
              </g>
              <g
                transform="translate(166.63, 155.94)"
                fill="#FFFFFF"
                fill-rule="nonzero"
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="14.38"
                font-weight="normal"
                class="third_animation_circle_4"
              >
                <text id="用户">
                  <tspan x="0" y="15">用户</tspan>
                </text>
                <text id="运营">
                  <tspan x="0" y="32.25">运营</tspan>
                </text>
              </g>
              <circle
                id="椭圆形"
                fill="#1D3462"
                fill-rule="nonzero"
                cx="56.99"
                cy="114.58"
                r="3.59"
                class="third_animation_circle_4"
              ></circle>
              <circle
                id="椭圆形"
                fill="#1D3462"
                fill-rule="nonzero"
                cx="118.53"
                cy="64.88"
                r="3.59"
                class="third_animation_circle_4"
              ></circle>
              <circle
                id="椭圆形"
                fill="#1D3462"
                fill-rule="nonzero"
                cx="192.34"
                cy="36.38"
                r="3.59"
                class="third_animation_circle_4"
              ></circle>
            </g>
            <line
              x1="346.5"
              y1="473.326344"
              x2="408.54"
              y2="175.21"
              id="路径"
              stroke="#B5B5B6"
              stroke-width="0.72"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="second_animation_line_3"
            ></line>
            <line
              x1="346.5"
              y1="473.326344"
              x2="463.88"
              y2="746.5"
              id="路径"
              stroke="#B5B5B6"
              stroke-width="0.72"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="second_animation_line_1"
            ></line>
            <line
              x1="347.5"
              y1="473.326344"
              x2="346.42"
              y2="844.76"
              id="路径"
              stroke="#B5B5B6"
              stroke-width="0.72"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="second_animation_line_6"
            ></line>
            <line
              x1="346.5"
              y1="473.326344"
              x2="218.8"
              y2="644.29"
              id="路径"
              stroke="#B5B5B6"
              stroke-width="0.72"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="second_animation_line_5"
            ></line>
            <line
              x1="197.34"
              y1="342.45"
              x2="346.5"
              y2="473.326344"
              id="路径"
              stroke="#B5B5B6"
              stroke-width="0.72"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="second_animation_line_4"
            ></line>
            <circle
              id="椭圆形"
              fill="url(#radialGradient-1)"
              cx="347.42"
              cy="473.67"
              r="59"
            ></circle>
            <g
              id="编组"
              transform="translate(322.52, 447.76)"
              fill="#595757"
              fill-rule="nonzero"
              font-family="PingFangSC-Regular, PingFang SC"
              font-size="24"
              font-weight="normal"
              line-spacing="24"
            >
              <text id="品牌">
                <tspan x="0" y="25">品牌</tspan>
              </text>
              <text id="体验">
                <tspan x="0" y="53">体验</tspan>
              </text>
            </g>
          </g>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showAnimate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {},

  methods: {}
};
</script>

<style lang="scss" scoped>
.solve_graph_vue {
  height: 100vh;
  box-sizing: border-box;
  width: 100%;
  background: #efefef;
  .solveGraphChar {
    height: 100%;
    width: auto;
    vertical-align: middle;
    position: relative;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    pointer-events: none;

    @keyframes line-animate {
      0% {
        stroke-dashoffset: 1100;
      }
      100% {
        stroke-dashoffset: 0;
      }
    }
    @keyframes circle-animate {
      0% {
        // transform: scale(0);
        opacity: 0;
      }

      100% {
        // transform: scale(1);
        opacity: 1;
      }
    }
    .solveGraphCanvas {
      width: 100%;
      height: 100%;
    }
    @for $i from 1 through 6 {
      .second_animation_line_#{$i} {
        animation: line-animate 0.8s;
        animation-fill-mode: backwards;
        stroke-dasharray: 1000;
        // animation-delay: #{1.8 * ($i - 1)+0.8}s;
        animation-delay: 0.8s;
      }
      .second_animation_circle_#{$i} {
        animation: circle-animate 1s;
        animation-fill-mode: backwards;
        // animation-delay: #{1.8 * ($i - 1)+1}s;
        animation-delay: 1s;
      }
      .third_animation_line_#{$i} {
        animation: line-animate 0.6s;
        animation-fill-mode: backwards;
        stroke-dasharray: 1000;
        // animation-delay: #{1.8 * ($i - 1)+1.2}s;
        animation-delay: 1.2s;
      }
      .third_animation_circle_#{$i} {
        animation: circle-animate 0.6s;
        // animation-delay: #{1.8 * ($i - 1)+1.5}s;
        animation-delay: 1.5s;
        animation-fill-mode: backwards;
      }
      .radian_animation_line_#{$i} {
        animation: line-animate 0.8s;
        animation-fill-mode: backwards;
        stroke-dasharray: 1000;
        // animation-delay: #{1.8 * ($i - 1)+2}s;
        animation-delay: 2s;
      }
    }
  }
}
</style>
