/*
 * @Author: xing
 * @Date: 2023-09-06 15:27:17
 * @LastEditors: xing
 * @LastEditTime: 2023-09-11 17:23:56
 * @Description:
 */
// const baseUrl =
// process.env.VUE_APP_URL === 'dev' ? '' : process.env.VUE_APP_URL;
const baseUrl = 'https://api.gzfmt.com';
export default {
  // 首页第一栏目的轮播图列表接口
  homepageListApi: baseUrl + '/fmt-api/fmt/homepage/list',
  // 营销管理
  // 营销类目的列表
  marketingCategoryApi: baseUrl + '/fmt-api/fmt/marketingcategory/list',
  // 营销类目的详情
  marketingDetailApi: baseUrl + '/fmt-api/fmt/marketingcategory/',
  // 营销类目的详情 / 卡片列表
  marketingCategoryDetailApi: baseUrl + '/fmt-api/fmt/marketingcard/list',
  // 工作列表
  jobListApi: baseUrl + '/fmt-api/fmt/joblist/list',
  // 翻转卡片的列表-核心业务
  businessListApi: baseUrl + '/fmt-api/fmt/corebusiness/list',
  // 公司基本信息
  companyDetailApi: baseUrl + '/fmt-api/fmt/companyinfo/list'
};
