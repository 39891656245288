<!--
 * @Author: xing
 * @Date: 2023-09-05 11:29:57
 * @LastEditors: xing
 * @LastEditTime: 2023-09-09 12:26:46
 * @Description: 
-->
<template>
  <div class="number_marketing_vue">
    <div class="vue_bg">
      <img :src="dataObj.image" alt="" class="img" />
    </div>
    <div class="title_box animate__animated animate__zoomInDown animate__fast">
      <div class="big_title">{{ dataObj.name }}</div>
      <div class="sub_title">{{ dataObj.nameEn }} {{ dataObj.nameEnTwo }}</div>
    </div>
    <div class="tag_box animate__animated animate__fadeIn animate__delay-1s">
      {{ dataObj.tags }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataObj: {
      type: Object,
      default: () => null
    }
  },
  mounted() {},
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.number_marketing_vue {
  width: 100vw;
  height: 100vh;
  padding-top: 8.8rem;
  padding-bottom: 10rem;

  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .vue_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      vertical-align: middle;
    }
  }
  .title_box {
    position: relative;
    text-align: center;
    .big_title {
      font-size: 9.2rem;
      font-family: SourceHanSansCN-Medium, 'Helvetica Neue';
      font-weight: 500;
      color: #ffffff;
      line-height: 9.2rem;
    }
    .sub_title {
      margin-top: 2rem;
      font-size: 4.6rem;
      font-family: SourceHanSansCN-Bold, 'Helvetica Neue';
      font-weight: 700;
      color: #ffffff;
      line-height: 4.6rem;
    }
  }
  .tag_box {
    margin-top: 52.3rem;
    width: calc(100% - 10rem);
    position: relative;
    font-size: 3rem;
    font-family: SourceHanSansCN-Medium, 'Helvetica Neue';
    font-weight: 500;
    color: #ffffff;
    line-height: 4.5rem;
    text-align: center;
  }
}
</style>
