<!--
 * @Author: xing
 * @Date: 2023-09-06 14:26:51
 * @LastEditors: xing
 * @LastEditTime: 2023-09-12 15:38:20
 * @Description: 
-->
<template>
  <div class="send_dialog">
    <div class="dialog_content animate__animated animate__bounceIn">
      <div class="main_content">
        <div class="send_icon">
          <img
            src="https://cdn.gzfmt.com/fmt/join/send_email_icon.png"
            alt=""
            class="img"
          />
        </div>
        <div class="send_text">
          <div class="send_text_left">请把简历发送到</div>
          <div class="send_text_right">xz@gzfmt.com</div>
        </div>
        <div class="send_desc">
          收到您的简历，合适我们将第一时间联系您。感谢您的信任，期待有机会将于您共事
        </div>
        <div class="copy_btn_box">
          <div class="copy_btn" @click="copyStr">复制邮箱</div>
        </div>
      </div>
      <div class="close_content" @click="closeDialog">
        <img
          src="https://cdn.gzfmt.com/fmt/join/close_dialog.png"
          alt=""
          class="img"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant-green';
export default {
  methods: {
    copyStr() {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText('xz@gzfmt.com')
          .then(() => {
            Toast('复制成功');
          })
          .catch(() => {
            Toast('复制失败');
          });
      } else {
        Toast('复制失败,请升级您的浏览器');
      }
    },
    closeDialog() {
      this.$emit('close-dialog');
    }
  }
};
</script>

<style lang="scss" scoped>
.send_dialog {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .dialog_content {
    .main_content {
      width: 50rem;
      box-sizing: border-box;
      padding: 3rem;
      padding-bottom: 5rem;
      border-radius: 2.4rem;
      background: #ffffff;
      .send_icon {
        text-align: center;
        margin-bottom: 2rem;
        .img {
          width: 7rem;
          height: 7rem;
          vertical-align: middle;
        }
      }
      .send_text {
        display: flex;
        align-items: center;
        justify-content: center;
        .send_text_left {
          font-size: 2.4rem;
          font-family: SourceHanSansCN-Regular, 'Helvetica Neue';
          font-weight: 400;
          color: #3e3a39;
          line-height: 3.3rem;
          margin-right: 1.2rem;
        }
        .send_text_right {
          font-size: 2.4rem;
          font-family: SourceHanSansCN-Bold, 'Helvetica Neue';
          font-weight: 700;
          color: #3e3a39;
          line-height: 3.3rem;
        }
      }
      .send_desc {
        margin-top: 1.6rem;
        text-align: center;
        font-size: 2rem;
        font-family: SourceHanSansCN-Regular, 'Helvetica Neue';
        font-weight: 400;
        color: #3e3a39;
        line-height: 2.8rem;
      }
      .copy_btn_box {
        margin-top: 3rem;
        text-align: center;
        .copy_btn {
          border-radius: 1.2rem;
          border: 0.1rem solid #1a1a1a;
          font-size: 2rem;
          font-family: SourceHanSansCN-Regular, 'Helvetica Neue';
          font-weight: 400;
          color: #3e3a39;
          line-height: 2.8rem;
          padding: 1rem 1.7rem;
          display: inline-block;
        }
      }
    }
    .close_content {
      margin-top: 1.5rem;
      text-align: center;
      .img {
        width: 3rem;
        height: 3rem;
        vertical-align: middle;
      }
    }
  }
}
</style>
