<!--
 * @Author: xing
 * @Date: 2023-09-08 21:37:49
 * @LastEditors: xing
 * @LastEditTime: 2023-09-13 15:10:15
 * @Description: 
-->
<template>
  <!--轮播6-->
  <div class="brand-logo">
    <div class="home-six-title">
      <div class="home-six-title-1">
        <div>继续携手更多品牌客户，</div>
        <div>共赢共利创造无限价值！</div>
      </div>
      <div class="home-six-title-2">
        <!-- <img
          src="https://cdn.gzfmt.com/fmt/home/m_brand_logo_sub_title.png"
          alt=""
          class="img"
        /> -->
        Continue to work together with more brand customers to create unlimited
        value for mutual benefit and win-win situation!
      </div>
    </div>

    <div class="brand-logo-content">
      <!-- 三栏左右滑动 -->
      <img
        src="https://cdn.gzfmt.com/fmt/home/m_brand_logo.gif"
        alt=""
        class="img"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  unmounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
.brand-logo {
  width: 100vw;
  height: 100vh;
  // padding-top: 8.9rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #efefef;

  .home-six-title {
    margin-top: 6rem;
    .home-six-title-1 {
      margin: 0 auto;
      width: 48rem;
      font-size: 4rem;
      font-family: SourceHanSansCN-Regular, 'Helvetica Neue';
      font-weight: 600;
      color: #231815;
      line-height: 5.6rem;
      text-align: center;
    }
    .home-six-title-2 {
      margin: 0 auto;
      margin-top: 0.5rem;
      text-align: center;
      font-size: 1.6rem;
      line-height: 1.8rem;
      width: 45rem;
      color: #231815;
      font-family: SourceHanSansCN-Regular, 'Helvetica Neue';
    }
  }
  .brand-logo-content {
    margin-top: 5rem;

    .img {
      width: 63.6rem;
      height: auto;
      vertical-align: middle;
    }
  }
}
</style>
