<!--
 * @Author: xing
 * @Date: 2023-09-04 14:07:54
 * @LastEditors: xing
 * @LastEditTime: 2023-09-09 14:12:08
 * @Description: 
-->
<template>
  <div
    class="solution-approach"
    ref="solutionApproachRef"
    :style="{ paddingBottom: show ? 0 : '100vh' }"
  >
    <div
      :class="['animate__animated', 'animate__backInRight', 'home-fifth']"
      v-if="show"
    >
      <div class="home-fifth-1">孚马迪全栈式</div>
      <div class="home-fifth-2">用户体验营销解决方案</div>
      <div class="home-fifth-3"></div>
      <div class="home-fifth-4">FMT FULL STACK USER EXPERIENCE</div>
      <div class="home-fifth-5">MARKETING SOLUTION</div>
    </div>
    <SolveGraph :showAnimate="show"></SolveGraph>
    <!-- <SolveGraph v-show="!show" style="opacity: 0"></SolveGraph> -->
  </div>
</template>

<script>
import SolveGraph from './SolveGraph.vue';

export default {
  props: {
    pageShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      containerHeight: 0,
      clientTop: 0,
      show: false
    };
  },
  components: {
    SolveGraph
  },
  watch: {
    pageShow: {
      handler(val) {
        if (!val) this.show = false;
      },
      immediate: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.containerHeight = this.$refs.solutionApproachRef.clientHeight;
      this.clientTop = this.$refs.solutionApproachRef.offsetTop;
    });
    window.addEventListener('scroll', this.changeAnimation);
  },
  unmounted() {
    window.removeEventListener('scroll', this.changeAnimation);
  },
  methods: {
    changeAnimation(e) {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const winHeight = document.documentElement.clientHeight;
      if (
        scrollTop > 1.2 * winHeight &&
        scrollTop <= this.clientTop + this.containerHeight &&
        !this.show
      ) {
        // this.update();
        this.show = true;
      } else if (scrollTop < 1.2 * winHeight && this.show) {
        this.show = false;
      }
    },
    update() {
      this.show = false;
      setTimeout(() => {
        this.show = true;
      }, 200);
    }
  }
};
</script>

<style lang="scss" scoped>
.solution-approach {
  width: 100vw;
  min-height: 100vh;
  // padding-top: 10rem;
  box-sizing: border-box;
  background: #efefef;
}
.home-fifth {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  padding-right: 4.2rem;

  &-1,
  &-2 {
    font-size: 3.5rem;
    font-family: SourceHanSansCN-Medium, 'Helvetica Neue';
    font-weight: 500;
    color: #1d3462;
    line-height: 4.8rem;
  }

  &-3 {
    width: 4.4rem;
    height: 0.2rem;
    margin: 1.5rem 0;
    background: #595757;
  }

  &-4,
  &-5 {
    font-size: 1.3rem;
    font-family: SourceHanSansCN-Light, 'Helvetica Neue';
    font-weight: 300;
    color: #595757;
    line-height: 2rem;
  }
}
</style>
