<!--
 * @Author: xing
 * @Date: 2023-09-06 17:35:00
 * @LastEditors: xing
 * @LastEditTime: 2023-09-20 14:54:15
 * @Description: 
-->
<template>
  <div class="home-box" v-loading="loading">
    <!--  首页 -->
    <VideoBannerVue
      ref="moduleOneWrapper"
      :bannerList="bannerList"
      v-if="bannerList.length > 0"
    ></VideoBannerVue>
    <!-- 时间轴 -->
    <DevelopHistory ref="moduleTwoWrapper" :pageShow="isTwo"></DevelopHistory>
    <!-- 孚马迪解决方案 -->
    <SolutionApproach
      ref="moduleThreeWrapper"
      :pageShow="isThird"
    ></SolutionApproach>
    <!-- 卡片翻转 -->
    <div v-if="businessList && businessList.length > 0">
      <BusinessViewVue
        :businessList="businessList"
        :marketingList="marketingList"
      ></BusinessViewVue>
    </div>
    <div v-for="item in marketingList" :key="item.id">
      <!-- 营销类目卡片 -->
      <LiveStreamingVue :marketData="item"></LiveStreamingVue>
    </div>
    <!-- logo徽标组 -->
    <BrandLogo></BrandLogo>
    <!-- 首页结尾 -->
    <PageEnding
      ref="moduleEndingWrapper"
      v-if="companyDetail"
      :companyDetail="companyDetail[0]"
    ></PageEnding>
  </div>
</template>

<script>
import DevelopHistory from './components/DevelopHistory.vue';
import SolutionApproach from './components/SolutionApproach.vue';
import BusinessViewVue from './components/BusinessView.vue';
import BrandLogo from './components/BrandLogo.vue';
import PageEnding from './components/PageEnding.vue';
import VideoBannerVue from './components/VideoBanner.vue';
import LiveStreamingVue from './components/LiveStreaming.vue';
import axios from 'axios';

export default {
  name: 'HomeView',
  components: {
    DevelopHistory,
    SolutionApproach,
    BusinessViewVue,
    BrandLogo,
    PageEnding,
    VideoBannerVue,
    LiveStreamingVue
  },
  data() {
    return {
      loading: false,
      bannerList: [],
      businessList: [],
      marketingList: [],
      companyDetail: null,
      scrollTwoTimer: null,
      scrollThreeTimer: null,
      isTwo: false,
      isThird: false
    };
  },
  mounted() {
    this.getPageData();
  },
  beforeUnmount() {},
  methods: {
    // 一下子全部获取页面的配置和数据
    getPageData() {
      this.loading = true;
      Promise.all([
        axios.get(this.$api.homepageListApi),
        axios.get(this.$api.marketingCategoryApi),
        axios.get(this.$api.businessListApi),
        axios.get(this.$api.companyDetailApi)
      ])
        .then((resArr) => {
          const bannerRes = resArr[0];
          if (bannerRes.data.code === 0) {
            this.bannerList = bannerRes.data.data;
          }
          // 翻转卡片
          const businessRes = resArr[2];
          if (businessRes.data.code === 0) {
            this.businessList = businessRes.data.data;
          }
          const marketingRes = resArr[1];
          if (marketingRes.data.code === 0) {
            this.marketingList = marketingRes.data.data;
          }
          const companyDetailRes = resArr[3];
          if (companyDetailRes.data.code === 0) {
            this.companyDetail = companyDetailRes.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.home-box {
  width: 100vw;
  overflow-x: hidden;
  --swiper-pagination-color: #9d9292;
  .swiper-page-container {
    background: rgb(239, 239, 239);
    height: 100vh;
  }
}
</style>
