/*
 * @Author: xing
 * @Date: 2023-09-04 14:52:47
 * @LastEditors: xing
 * @LastEditTime: 2023-09-11 18:27:58
 * @Description:
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/Home/HomeView.vue';
import mobileRoutes from './mobileIndex';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/marketing',
    name: 'marketing',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '@/views/Marketing/MarketingView.vue'
      )
  },

  {
    path: '/about',
    name: 'about',
    // 关于我们
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/About/AboutView.vue')
  },
  {
    path: '/join',
    name: 'join',
    // 加入我们
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/Join/JoinView.vue')
  },
  { path: '*', redirect: '/' }
];
// 用于判断设备是移动端还是 pc 端
function isMobile() {
  const width = document.documentElement.clientWidth;
  if (width <= 768) {
    return true;
  }
  return false;
}
const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes: isMobile() ? mobileRoutes : routes,
  linkActiveClass: 'router_link_item'
});

export default router;
