<template>
  <div class="join_page">
    <!-- 头部区域 -->
    <div class="join_header">
      <img
        alt=""
        src="https://cdn.gzfmt.com/fmt/join/m_join_top.png"
        class="img"
      />
    </div>
    <!-- 主体区域 -->
    <div class="join_main" v-if="!activeItem">
      <div
        v-for="(item, index) in joinData"
        :key="index"
        class="join_item"
        @click="setActiveItem(item)"
      >
        <div class="join_item_top">
          <div class="top_left">
            <div class="job_name">{{ item.name }}</div>
            <div class="job_position">{{ item.functionalGeneralization }}</div>
          </div>
          <div class="top_right">
            <div class="job_address">{{ item.address }}</div>
            <div class="job_experience">{{ item.years }}</div>
          </div>
        </div>
        <div class="join_item_bottom">
          <div class="bottom_left">
            <div class="job_resp">
              {{ item.functionalDetail.replace(' /br ', '') }}
            </div>
          </div>
          <div class="bottom_right">
            <div class="look_more">查看更多</div>
            <img
              src="https://cdn.gzfmt.com/fmt/join/look_more.png"
              alt=""
              class="img"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="join_detail" v-else>
      <div class="detail_header">
        <div class="detail_header_left" @click="activeItem = null">
          <img
            src="https://cdn.gzfmt.com/fmt/join/return_list.png"
            alt=""
            class="img"
          />
          <span>返回</span>
        </div>
      </div>
      <div class="detail_top">
        <div class="top_left">{{ activeItem.name }}</div>
        <div class="top_right" @click="showDialog = true">简历投递</div>
      </div>
      <div class="detail_mid">
        <div class="detail_mid_left">
          {{ activeItem.functionalGeneralization }}
        </div>
        <div class="detail_mid_right">
          {{ activeItem.address }}｜{{ activeItem.years }}
        </div>
      </div>
      <div class="detail_content">
        <div class="resp_box">
          <div class="resp_item">
            <div
              v-for="(text, index) in activeItem.functionalDetail.split(
                ' /br '
              )"
              :key="index"
            >
              {{ text }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部区域 -->
    <div class="join_bottom">
      <img
        alt=""
        src="https://cdn.gzfmt.com/fmt/join/m_join_bottom.png"
        class="img"
      />
      <AQRecordVue></AQRecordVue>
    </div>
    <SendDialogVue
      v-if="showDialog"
      @close-dialog="showDialog = false"
    ></SendDialogVue>
  </div>
</template>

<script>
import SendDialogVue from './components/SendDialog.vue';
import AQRecordVue from '@/components/AQRecord.vue';

import axios from 'axios';
export default {
  name: 'JoinView',
  components: {
    SendDialogVue,
    AQRecordVue
  },
  data() {
    return {
      showDialog: false,
      joinData: [],
      activeItem: null
    };
  },
  mounted() {
    this.activeItem = null;
    this.getJobList();
  },
  methods: {
    setActiveItem(item) {
      this.activeItem = item;
      // this.$router.push('/jobDetail', { id: '' });
    },
    getJobList() {
      axios.get(this.$api.jobListApi).then((res) => {
        if (res.data.code === 0) {
          this.joinData = res.data.data;
          if (this.joinData.length === 0) return;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.join_page {
  min-height: 100vh;
  padding-top: 8.8rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  ::v-deep .record_box {
    position: static;
    background: #1a1a1a;
    padding: 1rem 0;
    width: 100vw;
    display: block;
    // transform: scale(0.8);
  }
  .join_header,
  .join_bottom {
    .img {
      width: 100%;
      height: auto;
      vertical-align: middle;
    }
  }
  .join_main {
    flex: 1;
    height: 0;
    overflow-y: auto;
    padding: 4rem 5rem;
    .join_item {
      margin-bottom: 2.4rem;
      background: #e2e2e2;
      color: #1a1a1a;
      border-radius: 2.4rem;
      padding: 3rem 2rem;
      height: 24.1rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &:hover {
        color: #ffffff;
        background: #1d3462;
      }
      &:last-child {
        margin-bottom: 0;
      }
      .join_item_top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .top_left {
          .job_name {
            font-size: 2.8rem;
            font-family: SourceHanSansCN-Medium, 'Helvetica Neue';
            font-weight: 500;
            line-height: 2.8rem;
          }
          .job_position {
            margin-top: 1.2rem;
            font-size: 1.2rem;
            min-height: 12px;
            line-height: 1;
            // transform: scale(0.8);
            width: 33rem;
            transform-origin: 0 0;
            font-family: SourceHanSansCN-Medium, 'Helvetica Neue';
            font-weight: 500;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }
        .top_right {
          display: flex;
          align-items: center;
          .job_address {
            font-size: 1.6rem;
            font-family: SourceHanSansCN-Medium, 'Helvetica Neue';
            font-weight: 500;
            line-height: 1.6rem;
          }
          .job_experience {
            margin-left: 1.6rem;
            font-size: 1.6rem;
            font-family: SourceHanSansCN-Medium, 'Helvetica Neue';
            font-weight: 500;
            line-height: 1.6rem;
          }
        }
      }
      .join_item_bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .bottom_left {
          width: 35rem;
          flex-shrink: 0;
          .job_resp {
            font-size: 1.6rem;
            font-family: SourceHanSansCN-Regular, 'Helvetica Neue';
            font-weight: 400;
            line-height: 1;
            min-height: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }
        .bottom_right {
          display: flex;
          align-items: center;
          flex-shrink: 0;
          .look_more {
            margin-right: 1.5rem;
            font-size: 1.8rem;
            font-family: SourceHanSansCN-Regular, 'Helvetica Neue';
            font-weight: 400;
            line-height: 1.8rem;
          }
          .img {
            width: 2.8rem;
            height: 2.8rem;
          }
        }
      }
    }
  }
  .join_detail {
    flex: 1;
    height: 0;
    overflow-y: auto;
    padding: 4rem;
    .detail_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .detail_header_left {
        display: flex;
        align-items: center;
        .img {
          width: 1.8rem;
          height: 1.8rem;
          vertical-align: middle;
          margin-right: 0.3rem;
        }
        span {
          padding: 0;
          margin: 0;
          font-size: 2.4rem;
          font-family: SourceHanSansCN-Regular, 'Helvetica Neue';
          font-weight: 400;
          color: #7f7f7f;
          line-height: 2.4rem;
        }
      }
    }
    .detail_top {
      margin-top: 3rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .top_left {
        font-size: 3.8rem;
        font-family: SourceHanSansCN-Medium, 'Helvetica Neue';
        font-weight: 500;
        color: #333333;
        line-height: 3.8rem;
      }
      .top_right {
        padding: 0.6rem 2.5rem;
        border-radius: 1.2rem;
        border: 0.1rem solid #1a1a1a;
        font-size: 2.4rem;
        font-family: SourceHanSansCN-Regular, 'Helvetica Neue';
        font-weight: 400;
        color: #3e3a39;
        line-height: 3.3rem;
      }
    }
    .detail_mid {
      margin-top: 3rem;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .detail_mid_left {
        font-size: 2.4rem;
        font-family: SourceHanSansCN-Regular, 'Helvetica Neue';
        font-weight: 400;
        color: #000000;
        line-height: 2.4rem;
        width: 45rem;
      }
      .detail_mid_right {
        font-size: 2.4rem;
        font-family: SourceHanSansCN-Regular, 'Helvetica Neue';
        font-weight: 400;
        color: #000000;
        line-height: 2.4rem;
      }
    }
    .detail_content {
      margin-top: 3rem;
      .resp_box,
      .request_box {
        &.request_box {
          margin-top: 3rem;
        }
        .resp_title,
        .request_title {
          font-size: 2rem;
          font-family: SourceHanSansCN-Regular, 'Helvetica Neue';
          font-weight: 400;
          color: #3e3a39;
          line-height: 2rem;
          margin-bottom: 1.6rem;
        }
        .resp_item {
          font-size: 2rem;
          font-family: SourceHanSansCN-Regular, 'Helvetica Neue';
          font-weight: 400;
          color: #3e3a39;
          line-height: 4rem;
        }
      }
    }
  }
}
</style>
