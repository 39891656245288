<template>
  <div
    :style="{
      marginTop: '12.4rem'
    }"
    class="swiper-container home_module_1"
    @mouseenter="mouseEnterModuleOne"
    @mouseleave="mouseLeaveModuleOne"
  >
    <div class="video_box" style="position: absolute; top: 0; left: 0">
      <div v-for="(item, i) in spreadImgObj" :key="'bg-image-' + i">
        <img :src="item" alt="" class="img" v-show="item === activeImageUrl" />
      </div>
    </div>
    <div ref="moduleOneWrapper" class="swiper-wrapper">
      <!-- 轮播1 -->
      <div
        v-for="(item, i) in bannerList"
        :key="'moduleOne' + i"
        class="swiper-slide"
      >
        <div class="video_box">
          <div
            v-for="(mediaSrc, index) in imgCacheObj[i]"
            :key="'image-' + i + index"
          >
            <img
              :src="mediaSrc"
              alt=""
              class="img"
              v-show="mediaSrc === activeImageUrlArr[i]"
            />
          </div>
        </div>
        <div class="font_mask"></div>
        <div
          ref="moduleOneSlideFont"
          :style="{
            paddingLeft: '18rem'
          }"
          class="swiper-slide-font animate__animated animate__fast home-first animate__fadeInLeft"
        >
          <div class="font_top_box">
            <span class="title">{{ item.title }}</span>
            <br /><br />
            <span class="key-word">{{ item.titleEn }}</span>
            <div class="goal-box">
              <div
                v-for="goalItem in JSON.parse(item.subTitle.trim())"
                :key="goalItem"
                class="goal"
              >
                {{ goalItem }}
              </div>
            </div>
          </div>
          <div
            class="font_bottom_box"
            :style="{ width: i === 0 ? '62rem' : '34rem' }"
          >
            <div
              style="
                width: 6.6rem;
                height: 0.2rem;
                background: #ffffff;
                margin: 0 0 3.7rem 0;
              "
            ></div>
            <span class="direction">{{ item.content }} </span>
            <div class="swiper-pagination home_module_1_pagination"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
export default {
  props: {
    bannerList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      swiperOne: null,
      activeImageUrl: '',
      activeImageUrlArr: [],
      timer: null,
      activeIndex: 0,
      imgCacheObj: [],
      spreadImgObj: []
    };
  },
  activated() {
    this.initSwiper();
  },
  deactivated() {
    clearTimeout(this.timer);
    this.swiperOne.destroy();
  },

  mounted() {
    this.initSwiper();
    // 图片预加载
    this.preLoadImage();
  },
  methods: {
    update() {
      this.swiperOne.slideTo(0);
    },
    mouseEnterModuleOne(e) {
      if (this.$store.state.windows.type !== 'pc') return;
      this.swiperOne.autoplay.stop();
    },
    mouseLeaveModuleOne(e) {
      if (this.$store.state.windows.type !== 'pc') return;
      this.swiperOne.autoplay.start();
    },
    // swiper库事件
    initSwiper() {
      const that = this;
      // 初始化swiper
      this.swiperOne = new Swiper('.swiper-container', {
        loop: true,
        autoplay: {
          delay: 4000
        },
        mousewheel: false,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        on: {
          slideChangeTransitionStart: () => {
            that.moduleOneFontAnimation();
            that.moduleOneSlideChange();
          }
        }
      });
      this.swiperOne.emit('slideChangeTransitionStart');
    },
    // 轮播图结束
    moduleOneSlideChange() {
      if (!this.swiperOne) return;
      const { realIndex } = this.swiperOne;
      this.activeIndex = realIndex;
      this.autoPlaySequenceBand(0);
    },
    // 首页模块一轮播图切换的图文动画效果
    moduleOneFontAnimation() {
      if (!this.swiperOne) return;
      const { activeIndex } = this.swiperOne;
      const fontBoxRefArr = document.querySelectorAll('.swiper-slide-font');
      setTimeout(() => {
        fontBoxRefArr.forEach((box, i) => {
          if (i === activeIndex) {
            box.style.display = 'flex';
            box.classList.add('animate__fadeInLeft');
          } else {
            box.style.display = 'none';
            box.classList.remove('animate__fadeInLeft');
          }
        });
      }, 200);
    },
    // 图片预加载
    preLoadImage() {
      this.spreadImgObj = [];
      this.bannerList.forEach((banner, index) => {
        this.activeImageUrlArr.push(banner.imageUrl + '00.jpg');
        for (let i = 0; i < 100; i++) {
          if (!this.imgCacheObj[index]) this.imgCacheObj[index] = [];
          // this.imgCacheObj[index][i] = new Image();
          this.imgCacheObj[index][i] =
            banner.imageUrl + (i >= 10 ? i : '0' + i) + '.jpg';
          this.spreadImgObj.push(this.imgCacheObj[index][i]);
        }
      });
    },
    // 开始播放序列帧动画
    autoPlaySequenceBand(activeIndex) {
      let index = activeIndex;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (index >= 99) index = 0;
        this.activeImageUrl = this.imgCacheObj[this.activeIndex][index];
        this.activeImageUrlArr.forEach((t, tIndex) => {
          this.activeImageUrlArr[tIndex] = this.imgCacheObj[tIndex][index];
        });
        this.autoPlaySequenceBand(index + 1);
      }, 100);
    }
  }
};
</script>

<style lang="scss" scoped>
.home_module_1 {
  width: 100vw;
  // height: calc(100vh - 12.4rem) !important;
  @media screen and (max-width: 991px) {
    height: calc(100vh - 8.8rem);
    //margin-top: 8.8rem;

    .swiper-slide-font {
      margin: 17.9rem auto auto 4.2rem;
    }
  }

  @media screen and (min-width: 991px) {
    height: calc(100vh - 12.4rem) !important;
  }

  .swiper-slide {
    position: relative;
    height: 100%;

    .font_mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 130rem;
      height: 100%;
      background-image: linear-gradient(
        to right,
        rgba(47, 96, 181, 1) 0%,
        rgba(87, 130, 193, 0) 100%
      );
    }

    .swiper-slide-font {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      text-align: left;
      // height: 46.1rem;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .font_top_box {
        // TODO 待拆组件样式 -------- 下
        .title {
          font-size: 6.8rem;
          color: #ffffff;
          line-height: 6.8rem;
          font-family: SourceHanSansCN-Heavy, 'Helvetica Neue';
          font-weight: 900;
        }

        // VISON
        .key-word {
          margin-top: 2.1rem;
          font-size: 2.8rem;
          color: #ffffff;
          line-height: 2.8rem;
          font-family: SourceHanSansCN-Regular, 'Helvetica Neue';
          font-weight: 400;
        }

        .goal-box {
          margin-top: 4.6rem;

          .goal {
            height: 3.4rem;
            font-size: 3.4rem;
            color: #ffffff;
            line-height: 3.4rem;
            font-family: SourceHanSansCN-Regular, 'Helvetica Neue';
            font-weight: 400;
            margin-bottom: 1.6rem;
          }
        }
      }

      .font_bottom_box {
        padding-bottom: 17rem;
        .direction {
          height: 5.6rem;
          font-size: 1.8rem;
          color: #ffffff;
          line-height: 2.8rem;
          font-family: SourceHanSansCN-Light, 'Helvetica Neue';
          font-weight: 300;
        }
      }

      // TODO 待拆组件样式 -------- 上
    }
  }

  .swiper-slide video,
  .swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .video_box {
    width: 100%;
    height: 100%;
    .img {
      width: 100vw;
      height: 100vh;
      object-fit: cover;
      z-index: 0;
    }
  }
  .home_module_1_pagination {
    margin-top: 16rem;
    ::v-deep .swiper-pagination-bullet {
      width: 4.4rem;
      height: 0.4rem;
      margin-right: 0.6rem;
      border-radius: 0;
      background: #b5b5b6;

      &.swiper-pagination-bullet-active {
        background: #ffffff;
      }
    }
  }
}
</style>
